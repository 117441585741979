import { Route, Routes, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../PageData'
import { InviteCustomer } from '../inviteCustomer'
import RegisterAffiliate from './RegisterAffiliate'

const InviteCustomerPage = () => {
  const intl = useIntl()
  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route
        path=':affiliateId'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {intl.formatMessage({ id: 'Affiliate.Register' })}
            </PageTitle>
            <RegisterAffiliate />
          </>
        }
      />
      <Route
        path='/register'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {intl.formatMessage({ id: 'Affiliate.Register' })}
            </PageTitle>
            <RegisterAffiliate />
          </>
        }
      />
      <Route
        path=':referralCode/register'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {intl.formatMessage({ id: 'Affiliate.Register' })}
            </PageTitle>
            <RegisterAffiliate />
          </>
        }
      />
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {intl.formatMessage({ id: 'IntroduceCollaborator' })}
            </PageTitle>
            <InviteCustomer />
          </>
        }
      />
    </Routes>
  )
}

export default InviteCustomerPage
