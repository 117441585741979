const Promotions = () => {
  return (
    <section id='home-promotion'>
      <div className='mb-5 mb-xl-10'>
        <div className='d-flex align-items-center justify-content-between'>
          <h1 className='fw-bolder mb-4 text-uppercase'>Chương trình khuyến mãi</h1>
          <div className='fw-bold text-primary cursor-pointer'>
            Xem tất cả
            <i className='fa fa-angle-right ms-2 text-primary' aria-hidden='true'></i>
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-3 col-lg-4 col-md-6'>
            <div className='product-cart-wrap style-2'>
              <div className='product-img-action-wrap'>
                <div className='product-img'>
                  <a href='/shop-product-right'>
                    <img src='https://nest-frontend-v6.netlify.app/assets/imgs/banner/banner-5.png' alt='' />
                  </a>
                </div>
              </div>
              <div className='product-content-wrap'>
                <div className='deals-countdown-wrap'>
                  <div className='deals-countdown' data-countdown='2025/03/25 00:00:00'>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>363</span>
                      <span className='countdown-period'> days </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>05</span>
                      <span className='countdown-period'> hours </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>00</span>
                      <span className='countdown-period'> mins </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>56</span>
                      <span className='countdown-period'> sec </span>
                    </span>
                  </div>
                </div>
                <div className='deals-content'>
                  <h2>
                    <a href='/shop-product-right'>Seeds of Change Organic Quinoa, Brown, &amp; Red Rice</a>
                  </h2>
                  <div>
                    <span className='font-small text-muted'>
                      By <a href='/vendor-details-1'>NestFood</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-4 col-md-6'>
            <div className='product-cart-wrap style-2'>
              <div className='product-img-action-wrap'>
                <div className='product-img'>
                  <a href='/shop-product-right'>
                    <img src='https://nest-frontend-v6.netlify.app/assets/imgs/banner/banner-6.png' alt='' />
                  </a>
                </div>
              </div>
              <div className='product-content-wrap'>
                <div className='deals-countdown-wrap'>
                  <div className='deals-countdown' data-countdown='2026/04/25 00:00:00'>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>759</span>
                      <span className='countdown-period'> days </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>05</span>
                      <span className='countdown-period'> hours </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>00</span>
                      <span className='countdown-period'> mins </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>56</span>
                      <span className='countdown-period'> sec </span>
                    </span>
                  </div>
                </div>
                <div className='deals-content'>
                  <h2>
                    <a href='/shop-product-right'>Perdue Simply Smart Organics Gluten Free</a>
                  </h2>
                  <div>
                    <span className='font-small text-muted'>
                      By <a href='/vendor-details-1'>Old El Paso</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-4 col-md-6 d-none d-lg-block'>
            <div className='product-cart-wrap style-2'>
              <div className='product-img-action-wrap'>
                <div className='product-img'>
                  <a href='/shop-product-right'>
                    <img src='https://nest-frontend-v6.netlify.app/assets/imgs/banner/banner-7.png' alt='' />
                  </a>
                </div>
              </div>
              <div className='product-content-wrap'>
                <div className='deals-countdown-wrap'>
                  <div className='deals-countdown' data-countdown='2027/03/25 00:00:00'>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>1093</span>
                      <span className='countdown-period'> days </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>05</span>
                      <span className='countdown-period'> hours </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>00</span>
                      <span className='countdown-period'> mins </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>56</span>
                      <span className='countdown-period'> sec </span>
                    </span>
                  </div>
                </div>
                <div className='deals-content'>
                  <h2>
                    <a href='/shop-product-right'>Signature Wood-Fired Mushroom and Caramelized</a>
                  </h2>
                  <div>
                    <span className='font-small text-muted'>
                      By <a href='/vendor-details-1'>Progresso</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-4 col-md-6 d-none d-xl-block'>
            <div className='product-cart-wrap style-2'>
              <div className='product-img-action-wrap'>
                <div className='product-img'>
                  <a href='/shop-product-right'>
                    <img src='https://nest-frontend-v6.netlify.app/assets/imgs/banner/banner-8.png' alt='' />
                  </a>
                </div>
              </div>
              <div className='product-content-wrap'>
                <div className='deals-countdown-wrap'>
                  <div className='deals-countdown' data-countdown='2025/02/25 00:00:00'>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>335</span>
                      <span className='countdown-period'> days </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>05</span>
                      <span className='countdown-period'> hours </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>00</span>
                      <span className='countdown-period'> mins </span>
                    </span>
                    <span className='countdown-section'>
                      <span className='countdown-amount hover-up'>56</span>
                      <span className='countdown-period'> sec </span>
                    </span>
                  </div>
                </div>
                <div className='deals-content'>
                  <h2>
                    <a href='/shop-product-right'>Simply Lemonade with Raspberry Juice</a>
                  </h2>
                  <div>
                    <span className='font-small text-muted'>
                      By <a href='/vendor-details-1'>Yoplait</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Promotions
