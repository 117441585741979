import _ from 'lodash'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import { getOrderRepurchaseById } from '../../../redux/actions/eco/orderActions'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import { OrderStatusEnum, getKeyByValue } from '../../../utilities/enums'
import NumberBox from '../componentsShared/NumberBox'
import ReasonCancelModal from './ReasonCancelModal'

const PurchasedProducts = ({ orders }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isOpenCancel, setIsOpenCancel] = useState(false)
  const [selectedOrder, setPayload] = useState(null)

  const handleCancel = async (order) => {
    setPayload(order)
    setIsOpenCancel(true)
  }

  const handleRepurchase = async (order) => {
    const { error, result: selectedCarts } = await getOrderRepurchaseById(order.id)
    if (selectedCarts) {
      navigate('/checkout', { state: { selectedCarts } })
    } else {
      console.log(error)
    }
  }

  const statusMessage = {
    [OrderStatusEnum.WaitForConfirm]: () => <></>,
    [OrderStatusEnum.PreparingGoods]: (item) => (
      <span className='purchase-action-desciption'>
        {intl.formatMessage({ id: 'Product.PreparedShipping' })}
        <div className='purchase-order-drawer ps-1'>
          <u className='purchase-date-delivery'>{item.receiveDate}</u>
        </div>
      </span>
    ),
    [OrderStatusEnum.WaitForDelivery]: () => <span className='purchase-action-desciption'>{intl.formatMessage({ id: 'Product.ClickReceive' })}</span>,

    [OrderStatusEnum.Complete]: () => <span className='purchase-action-desciption'>{intl.formatMessage({ id: 'Product.WaitingSellerReview' })}</span>,
    [OrderStatusEnum.Cancel]: () => <span className='purchase-action-desciption'>{intl.formatMessage({ id: 'Product.CanceledByYou' })}</span>,
    [OrderStatusEnum.ReturnRefund]: () => <></>,
  }

  const statusAction = {
    [OrderStatusEnum.WaitForConfirm]: (item) => (
      <div className='purchase-action-primary'>
        <button className='stardust-button purchase-action-text' onClick={() => handleCancel(item)}>
          <FormattedMessage id='CancelOrder' />
        </button>
      </div>
    ),
    [OrderStatusEnum.PreparingGoods]: (item) => (
      <div className='purchase-action-primary'>
        <button className='stardust-button purchase-action-text' onClick={() => handleCancel(item)}>
          <FormattedMessage id='CancelOrder' />
        </button>
      </div>
    ),
    [OrderStatusEnum.WaitForDelivery]: (item) => (
      <>
        <div className='purchase-action-success'>
          <button className='stardust-button stardust-button-primary purchase-action-text' onClick={() => handleCancel(item)}>
            {intl.formatMessage({ id: 'Product.ReceivedGoods' })}
          </button>
        </div>
        <div className='purchase-action-primary'>
          <button className='stardust-button purchase-action-text'>{intl.formatMessage({ id: 'Product.ReturnRefundRequest' })}</button>
        </div>
      </>
    ),
    [OrderStatusEnum.Complete]: () => (
      <>
        <div className='purchase-action-success'>
          <button className='stardust-button stardust-button-primary purchase-action-text'>{intl.formatMessage({ id: 'Product.Repurchase' })}</button>
        </div>
        <div className='purchase-action-primary'>
          <button className='stardust-button purchase-action-text'>{intl.formatMessage({ id: 'Product.ReviewShop' })}</button>
        </div>
      </>
    ),
    [OrderStatusEnum.Cancel]: (item) => (
      <div className='purchase-action-success'>
        <button className='stardust-button stardust-button-primary purchase-action-text' onClick={() => handleRepurchase(item)}>
          {intl.formatMessage({ id: 'Product.Repurchase' })}
        </button>
      </div>
    ),
    [OrderStatusEnum.ReturnRefund]: () => <></>,
  }

  return (
    <>
      {!_.isEmpty(orders) ? (
        orders.map((order) => (
          <div className='purchase-suppliers' key={order.id}>
            <div className='purchase-supplier-wrap'>
              <section>
                <div className='purchase-supplier-flex'>
                  <div className='purchase-supplier-left'>
                    {/* <div className='purchase-supplier-favorite'>
                      <img src={toAbsoluteUrl('../media/svg/icon/favorite.svg')} alt='' />
                    </div> */}
                    <div className='purchase-supplier-name'>{order.supplierName}</div>
                    <a className='purchase-supplier-visit' href={`/supplier/${order.supplierId}`}>
                      <div className='stardust-button'>
                        <img src={toAbsoluteUrl('../media/svg/icon/shop.svg')} alt='' className='checkout-svg-icon checkout-svg-icon fs-3' />
                        <span>
                          <FormattedMessage id='VisitShop' />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className='purchase-supplier-status'>
                    <div className='purchase-supplier-status-name'>
                      {intl.formatMessage({ id: `Purchase.${getKeyByValue(OrderStatusEnum, order.orderStatus)}` })}
                    </div>
                  </div>
                </div>
              </section>
              <div className='purchase-supplier-bottom' />
              {order.orderDetails.map((product) => (
                <section key={product.id}>
                  <div className='purchase-product-section'>
                    <div className='purchase-product-wrap'>
                      <div className='purchase-product-flex'>
                        <div className='purchase-product-left p-0'>
                          <img src={DEFAULT_VALUE.BASE_URL + DEFAULT_VALUE.SLASH + product.productImage} className='purchase-product-image' alt='' />
                          <div className='purchase-product-info'>
                            <div className='purchase-product-name'>
                              <span className='purchase-product-middle'>{product.productName}</span>
                            </div>
                            <div className='d-flex justify-content-between w-100'>
                              <div className='purchase-product-category'>
                                {product.productPropertyName && (
                                  <>
                                    <FormattedMessage id='ProductCategorizing' />: {product.productPropertyName}
                                  </>
                                )}
                              </div>
                              <div className='purchase-product-qty'>x{product.quantity}</div>
                            </div>
                            <div className='d-flex justify-content-between w-100'>
                              <span className='purchase-product-return purchase-product-green'>{intl.formatMessage({ id: 'Product.FreeReturn' })}</span>
                              <div className='purchase-product-price'>
                                {product.discountAmount > 0 && (
                                  <span className='purchase-product-price-discount'>
                                    <NumberBox value={product.basePrice} type='text' className='' suffix={DEFAULT_VALUE.VND} />
                                  </span>
                                )}
                                <span className='purchase-product-price-total purchase-success-color'>
                                  <NumberBox value={product.basePrice - product.discountAmount} type='text' className='' suffix={DEFAULT_VALUE.VND} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='purchase-product-end' />
                    </div>
                  </div>
                </section>
              ))}
            </div>

            <div className='purchase-supplier-bottom-wrap'>
              <div className='purchase-amount-section'>
                <div className='purchase-amount-flex'>
                  <span className='purchase-cursor'>
                    <div className='purchase-amount-icon'>
                      <img src={toAbsoluteUrl('../media/svg/icon/money-bag.svg')} alt='' className='icon-voucher-line' />
                    </div>
                  </span>
                  <label className='purchase-amount-text'>
                    <FormattedMessage id='TotalAmount' />:
                  </label>

                  <div className='purchase-amount-text-total'>
                    <NumberBox value={order.totalAmount} type='text' className='' suffix={DEFAULT_VALUE.VND} />
                  </div>
                </div>
              </div>
              <div className='purchase-action-section'>
                <div className='purchase-action-status'>{statusMessage[order.orderStatus](order)}</div>
                <div className='purchase-action-button-wrap'>{statusAction[order.orderStatus](order)}</div>
              </div>
            </div>

            {isOpenCancel && <ReasonCancelModal closeModal={setIsOpenCancel} order={selectedOrder} />}
          </div>
        ))
      ) : (
        <section>
          <div className='return-no-content-wrap'>
            <div className='return-no-content-flex'>
              <div className='return-no-content-image'></div>
              <h2 className='return-no-content-text'>
                <FormattedMessage id='NoOrder' />
              </h2>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default PurchasedProducts
