import { OrderStatusEnum } from '../../../utilities/enums'
import PurchasePage from './PurchasePage'
import Purchases from './Purchases'
import PurchasedProducts from './PurchasedProducts'

export const purchaseRoutes = [
  {
    title: 'Purchase.All',
    type: OrderStatusEnum.All,
  },
  {
    title: 'Purchase.WaitForConfirm',
    type: OrderStatusEnum.WaitForConfirm,
  },
  {
    title: 'Purchase.PreparingGoods',
    type: OrderStatusEnum.PreparingGoods,
  },
  {
    title: 'Purchase.WaitForDelivery',
    type: OrderStatusEnum.WaitForDelivery,
  },
  {
    title: 'Purchase.Complete',
    type: OrderStatusEnum.Complete,
  },
  {
    title: 'Purchase.Cancel',
    type: OrderStatusEnum.Cancel,
  },
  // {
  //   title: 'Purchase.ReturnRefund',
  //   type: OrderStatusEnum.ReturnRefund,
  // },
]

export { PurchasePage, Purchases, PurchasedProducts }
