import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class AffiliateCustomerAddressAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.AFFILIATE_CUSTOMER_ADDRESS)
    this.adapter = this.initAdapter(conf)
  }

  async getById(affiliateCustomerAddressId) {
    return await this.adapter.getAsync(`getById?id=${affiliateCustomerAddressId}`)
  }

  async getByAffiliateCustomerRelationId(affiliateCustomerAddressId) {
    return await this.adapter.getAsync(`getByAffiliateCustomerRelationId?id=${affiliateCustomerAddressId}`)
  }

  async saveAffiliateCustomerAddress(payload) {
    return await this.adapter.postAsync('saveAffiliateCustomerAddress', { payload })
  }

  async updateAffiliateCustomerAddress(payload) {
    return await this.adapter.putAsync('updateAffiliateCustomerAddress', { payload })
  }

  async updateIsDefaultCustomerAddress(payload) {
    return await this.adapter.putAsync('updateIsDefaultCustomerAddress', { payload })
  }

  async deleteAffiliateCustomerAddress(affiliateCustomerAddressId) {
    return await this.adapter.deleteAsync(`deleteAffiliateCustomerAddress?id=${affiliateCustomerAddressId}`)
  }
}

export default AffiliateCustomerAddressAPI
