import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { showToast, TOAST_TYPES } from '../../../helpers/Toastify'
import { saveAffiliateCustomerRelation, updateAffiliateCustomerRelation } from '../../../redux/actions/aff/affiliateCustomerRelationAction'
import { getDistrictsByProvinceId, getProvinces, getWardsByDistrictId } from '../../../redux/actions/gnr/provinceActions'
import { validationAllComponents } from '../../../utilities/shareFunction'
import Button from '../componentsShared/Button'
import { ControlType, FormControl } from '../componentsShared/FormControl'
import Modal from '../componentsShared/Modal'

const CreateUserModal = ({ editCustomer, data, closeModal, fetchCustomers }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  const [customerName, setCustomerName] = useState(editCustomer?.name ?? null)
  const [phoneNumber, setPhoneNumber] = useState(editCustomer?.phone ?? null)
  const [email, setEmail] = useState(editCustomer?.email ?? null)
  const [provinceId, setProvinceId] = useState(null)
  const [districtId, setDistrictId] = useState(null)
  const [wardId, setWardId] = useState(null)
  const [address, setAddress] = useState(null)
  const [isDefault, setIsDefault] = useState(false)

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvinces()
      if (result) {
        setProvinces(result)
      }
    }
    fetchProvinces()
  }, [])

  useEffect(() => {
    setDistrictId(null)
    const fetchDistricts = async () => {
      const { result } = await getDistrictsByProvinceId({ provinceId, pageIndex: null, pageSize: null })
      if (result?.items) {
        setDistricts(result.items)
      }
    }
    fetchDistricts()
  }, [provinceId])

  useEffect(() => {
    setWardId(null)
    const fetchWards = async () => {
      const { result } = await getWardsByDistrictId({ districtId, pageIndex: null, pageSize: null })
      if (result?.items) {
        setWards(result.items)
      }
    }
    fetchWards()
  }, [provinceId, districtId])

  const handleSave = async (e) => {
    e.preventDefault()
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const customerData = {
      id: editCustomer?.id,
      affiliateId: editCustomer?.affiliateId,
      name: customerName,
      phone: phoneNumber,
      provinceId,
      districtId,
      wardId,
      address,
      isDefault,
      email
    }

    const { error } = editCustomer?.isAdded ? await saveAffiliateCustomerRelation(customerData) : await updateAffiliateCustomerRelation(customerData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      closeModal(false)
      fetchCustomers()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Customer.Addnew' })} closeModal={closeModal} modalSize='mw-650px'>
      <form className='form'>
        <div
          className='d-flex flex-column'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.customerName = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'CustomerName' })}
                value={customerName}
                isRequired={true}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
            <div className='col-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'PhoneNumber' })}
                value={phoneNumber}
                isRequired={true}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className='col-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Email' })}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {editCustomer?.isAdded && (
              <>
                <div className='col-6 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.provinceId = ref)}
                    tabIndex={3}
                    controlType={ControlType.Select}
                    label={intl.formatMessage({ id: 'Province' })}
                    options={provinces}
                    value={provinceId}
                    isRequired={true}
                    onChange={(e) => setProvinceId(e.value)}
                  />
                </div>
                <div className='col-6 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.districtId = ref)}
                    tabIndex={4}
                    controlType={ControlType.Select}
                    label={intl.formatMessage({ id: 'District' })}
                    options={districts}
                    value={districtId}
                    isRequired={true}
                    onChange={(e) => setDistrictId(e.value)}
                  />
                </div>
                <div className='col-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.wardId = ref)}
                    tabIndex={5}
                    controlType={ControlType.Select}
                    label={intl.formatMessage({ id: 'Ward' })}
                    options={wards}
                    value={wardId}
                    isRequired={true}
                    onChange={(e) => setWardId(e.value)}
                  />
                </div>
                <div className='col-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.address = ref)}
                    tabIndex={6}
                    controlType={ControlType.TextArea}
                    label={intl.formatMessage({ id: 'HouseNumber' })}
                    value={address}
                    isRequired={true}
                    rows={2}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className='col-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.isDefault = ref)}
                    tabIndex={6}
                    controlType={ControlType.Checkbox}
                    label={intl.formatMessage({ id: 'Default' })}
                    value={isDefault}
                    onChange={(e) => setIsDefault(e.target.checked)}
                    isFromSwitch={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className='text-center pt-5'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={7}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={8}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateUserModal
