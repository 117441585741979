/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Auth } from '../../../components/auth/Auth'
import { Icon } from '../../../helpers'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import { deleteAffiliateCustomerAddress, getByAffiliateCustomerRelationId } from '../../../redux/actions/aff/affiliateCustomerAddressAction'
import { deleteAffiliateCustomerRelation, getAffiliateCustomerRelations } from '../../../redux/actions/aff/affiliateCustomerRelationAction'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import ConfirmRemove from '../componentsShared/Confirm'
import Loading from '../componentsShared/Loading'
import Pagination from '../componentsShared/Pagination'
import CreateAddressModal from './CreateAddressModal'
import CreateUserModal from './CreateUserModal'
import PageTitle from '../components/PageTitle'
import { getOrders } from '../../../redux/actions/eco/orderActions'
import { OrderStatusEnum } from '../../../utilities/enums'
import NumberBox from '../componentsShared/NumberBox'

const Customers = () => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [customerSelected, setCustomerSelected] = useState(null)

  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState(null)
  const [addresses, setAddresses] = useState([])
  const [address, setAddress] = useState(null)
  const [isEditCustomer, setIsEditCustomer] = useState(false)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [tabInfo, setTabInfo] = useState(1)

  const [orders, setOrders] = useState([])
  const [orderCurrentPage, setOrderCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [orderPageSize, setOrderPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [orderTotalPages, setOrderTotalPages] = useState(0)

  const statusColor = {
    [OrderStatusEnum.WaitForConfirm]: 'primary text-light',
    [OrderStatusEnum.PreparingGoods]: 'info text-light',
    [OrderStatusEnum.WaitForDelivery]: 'warning text-light',
    [OrderStatusEnum.Complete]: 'success text-light',
    [OrderStatusEnum.Cancel]: 'danger text-light',
    [OrderStatusEnum.ReturnRefund]: 'secondary text-dark',
  }

  useEffect(() => {
    fetchCustomers()
  }, [currentPage, pageSize])

  useEffect(() => {
    fetchOrders()
  }, [orderCurrentPage, orderPageSize])

  useEffect(() => {
    if (!_.isEmpty(customerSelected?.id)) {
      fetchAddresses(customerSelected?.id)
      fetchOrders()
    }
  }, [customerSelected])

  const fetchCustomers = async () => {
    setIsLoading(true)
    try {
      const { result } = await getAffiliateCustomerRelations({ pageIndex: currentPage, pageSize: pageSize, userId: currentUser.id })
      const data = result?.items
      if (data) {
        setCustomers(data)
        setTotalPages(result.totalCount)

        if (!_.isEmpty(data) && data.length > 0) {
          setCustomerSelected(data[0])
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAddresses = async (id) => {
    try {
      const { result } = await getByAffiliateCustomerRelationId(id)
      if (result) {
        setAddresses(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchOrders = async () => {
    const { result } = await getOrders({ pageIndex: orderCurrentPage, pageSize: orderPageSize, affiliateCustomerRelationId: customerSelected?.id })
    if (result && result.items) {
      setOrders(result.items)
      setOrderTotalPages(result.totalCount)
    } else {
      setOrders([])
      setOrderTotalPages(0)
    }
  }

  const hanleCreateCustomer = () => {
    setIsEditCustomer(true)
    setCustomer({ isAdded: true })
  }

  const handleEditCustomer = (item) => {
    setCustomer(item)
    setIsEditCustomer(true)
  }

  const handleCreateAddress = () => {
    setIsEditAddress(true)
    setAddress({ isAdded: true, affiliateCustomerRelationId: customerSelected.id })
  }

  const handleEditAddress = (item) => {
    setAddress(item)
    setIsEditAddress(true)
  }

  const handleRemoveAddress = async (item) => {
    try {
      const { error } = await deleteAffiliateCustomerAddress(item.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        var currentAddresses = addresses.filter((x) => x.id !== item.id)
        setAddresses(currentAddresses)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  const confirmRemove = (item) => {
    setConfirmVisible(true)
    setCustomer(item)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await deleteAffiliateCustomerRelation(customer.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        fetchCustomers()
        setConfirmVisible(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderAddress = () => {
    return (
      <div className='table-responsive'>
        <table className='table dataTable align-middle table-row-dashed table-hover'>
          <tbody className='fw-semibold'>
            {_.map(addresses, function (item, index) {
              return (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      {!_.isEmpty(item?.address) ? `${item?.address}, ` : ''}
                      {`${!_.isEmpty(item?.ward) ? `${item?.ward}, ` : ''} ${!_.isEmpty(item?.district) ? `${item?.district},` : ''}`}
                      {!_.isEmpty(item?.province) ? `${item?.province}` : ''}
                    </div>
                    {item.isDefault && <div className='ms-1 badge bg-danger text-light'>{intl.formatMessage({ id: 'Default' })}</div>}
                  </td>
                  <td className='text-end min-w-100px'>
                    <a className='btn btn-icon btn-light-primary btn-sm ms-1' onClick={() => handleEditAddress(item)}>
                      <i className='fa-solid fa-pen-to-square' />
                    </a>
                    <a className='btn btn-icon btn-light-danger btn-sm ms-1' onClick={() => handleRemoveAddress(item)}>
                      <i className='fa-solid fa-trash' />
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <a className='btn btn-sm btn-primary' onClick={handleCreateAddress}>
          <Icon iconName='plus' className='fs-3' />
          {intl.formatMessage({ id: 'AddNewAddress' })}
        </a>
      </div>
    )
  }

  const renderOrder = () => {
    return (
      <div className='table-responsive'>
        <table className='table dataTable align-middle table-row-dashed table-hover'>
          <thead>
            <tr className='text-start fw-bold text-uppercase'>
              <th className='w-200px'>{intl.formatMessage({ id: 'Order.Code' })}</th>
              <th className='w-100px'>{intl.formatMessage({ id: 'Order.Status' })}</th>
              <th className='w-100px'>{intl.formatMessage({ id: 'Order.Amount' })}</th>
              <th className='w-100px'>{intl.formatMessage({ id: 'Order.Rewards' })}</th>
              <th className='w-100px'>{intl.formatMessage({ id: 'Order.Date' })}</th>
            </tr>
          </thead>
          <tbody className='fw-semibold'>
            {_.map(orders, function (item, index) {
              const totalCommission = item.orderDetails.reduce((sum, detail) => sum + detail.totalCommission, 0)
              return (
                <tr key={index}>
                  <td>{item.orderCode}</td>
                  <td>
                    <div className={`badge bg-${statusColor[item.orderStatus]}`}>
                      <FormattedMessage id={`Purchase.${item.orderStatusName}`} />
                    </div>
                  </td>
                  <td>
                    <NumberBox type='text' value={item.grandTotal} className='' suffix='đ' />
                  </td>
                  <td>
                    <NumberBox type='text' value={totalCommission} className='' suffix='đ' />
                  </td>
                  <td>{item.orderDateStr}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination
          totalPages={orderTotalPages}
          currentPage={orderCurrentPage}
          itemsPerPage={orderPageSize}
          onPageChange={(e) => setOrderCurrentPage(e)}
          onItemsPerPageChange={(e) => setOrderPageSize(e)}
        />
        {isLoading && <Loading />}
      </div>
    )
  }

  const renderBody = () => {
    return _.map(customers, function (item, index) {
      return (
        <tr key={item.id} onClick={() => setCustomerSelected(item)}>
          <td>{index + 1}</td>
          <td>{item.name}</td>
          <td>{item.phone}</td>
          <td>
            <div className='d-flex justify-content-center flex-shrink-0'>
              <a onClick={() => handleEditCustomer(item)} className='btn btn-icon btn-light-primary btn-sm me-1'>
                <i className='fa-solid fa-pen-to-square' />
              </a>
              <a onClick={() => confirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
                <i className='fa-solid fa-trash' />
              </a>
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className='customer'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Customer' })}</h1>
            <PageTitle />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-xl-row mt-4'>
        <div className='flex-lg-row-fluid'>
          <div className='card border mb-5 mb-xl-8'>
            <div className='card-body p-4'>
              <a className='btn btn-primary btn-sm' onClick={hanleCreateCustomer}>
                <Icon iconName='plus' className='fs-2' />
                <FormattedMessage id='AddNew' />
              </a>
              <div className='table-responsive'>
                <table className='table dataTable align-middle table-row-dashed table-hover'>
                  <thead>
                    <tr className='text-start fw-bold text-uppercase'>
                      <th className='w-10px min-w-10px'>{intl.formatMessage({ id: 'SerialNumber' })}</th>
                      <th className='w-200px'>{intl.formatMessage({ id: 'CustomerName' })}</th>
                      <th className='w-100px'>{intl.formatMessage({ id: 'PhoneNumber' })}</th>
                      <th className='w-60px'></th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold'>{renderBody()}</tbody>
                </table>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  itemsPerPage={pageSize}
                  onPageChange={(e) => setCurrentPage(e)}
                  onItemsPerPageChange={(e) => setPageSize(e)}
                />
                {isLoading && <Loading />}
              </div>
            </div>
          </div>
        </div>

        <div className='flex-column flex-lg-row-auto w-100 w-xl-750px mb-10 ms-xl-5'>
          <div className='card border mb-5'>
            <div className='d-flex flex-center flex-column p-4'>
              <div className='symbol symbol-100px symbol-circle mb-2'>
                <img src={_.isEmpty(customerSelected?.avatar) ? '/media/no-avatar.png' : DEFAULT_VALUE.BASE_URL + customerSelected?.avatar} alt='image' />
              </div>
              <a className='fs-3 text-gray-800 text-hover-primary fw-bold mb-1'>{customerSelected?.name}</a>
              <div className='d-flex fw-semibold text-muted gap-2'>
                {customerSelected?.email && (
                  <div className='d-flex align-items-center'>
                    <Icon iconName='sms' className='fs-2 me-1' />
                    {customerSelected?.email}
                  </div>
                )}
                {customerSelected?.phone && (
                  <div className='d-flex align-items-center'>
                    <Icon iconName='phone' className='fs-2 me-1' />
                    {customerSelected?.phone}
                  </div>
                )}
              </div>
            </div>
            <div className='purchase-right-nav'>
              <div className='purchase-right-wrap'>
                <section className='purchase-tablist'>
                  <a
                    className={`purchase-col-all nav-link ${tabInfo === 1 ? 'active text-active-primary purchase-border-primary' : ''}`}
                    onClick={() => setTabInfo(1)}
                  >
                    <span className='purchase-text-all'>{intl.formatMessage({ id: 'Address' })}</span>
                  </a>
                  <a
                    className={`purchase-col-all nav-link ${tabInfo === 2 ? 'active text-active-primary purchase-border-primary' : ''}`}
                    onClick={() => setTabInfo(2)}
                  >
                    <span className='purchase-text-all'>{intl.formatMessage({ id: 'PurchaseOrder' })}</span>
                  </a>
                </section>
              </div>
            </div>
            <div className='card-body p-4'>
              {!_.isEmpty(customerSelected) ? (
                tabInfo === 1 ? (
                  renderAddress()
                ) : (
                  renderOrder()
                )
              ) : (
                <div className='text-center mt-4 px-3 pt-3'>
                  <svg width='48' height='48' viewBox='0 0 48 48' fill='none'>
                    <path d='M44 21V4H4V44H21' stroke='#9B9FAC' strokeWidth='4' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'></path>
                    <path d='M45 45L29 29' stroke='#9B9FAC' strokeWidth='4' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'></path>
                    <path d='M29 44V29H44' stroke='#9B9FAC' strokeWidth='4' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'></path>
                  </svg>
                  <p className='text-center'>{intl.formatMessage({ id: 'Customer.ChooseCustomer' })}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isEditCustomer && <CreateUserModal editCustomer={customer} data={customers} closeModal={setIsEditCustomer} fetchCustomers={fetchCustomers} />}
      {isEditAddress && <CreateAddressModal editAddress={address} addresses={addresses} closeModal={setIsEditAddress} fetchAddresses={fetchAddresses} />}
      {confirmVisible && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={() => setConfirmVisible(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}
    </div>
  )
}

export default Customers
