import React, { forwardRef } from 'react'

const Checkbox = forwardRef((props, ref) => {
  const { title, checked, onChange, isFromSwitch, tabIndex, onBlur, readOnly, isValid, errorMessage } = props
  return (
    <>
      <label className={`form-check ${isFromSwitch ? 'form-switch' : ''} form-check-custom form-check-solid`}>
        <input
          tabIndex={tabIndex ?? 0}
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          data-isvalid={isValid}
          ref={ref}
        />
        <span className="form-check-label fw-bold text-muted">{title}</span>
      </label>
      {isValid === false && (
        <p className="mt-0 mb-0" style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default Checkbox
