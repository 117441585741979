import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class SubCategoryAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.SUB_CATEGORY)
    this.adapter = this.initAdapter(conf)
  }


  async getByCategoryId(payload) {
    return await this.adapter.getAsync('getByCategoryId', { payload })
  }
}

export default SubCategoryAPI
