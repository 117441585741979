/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import { useIntl, FormattedMessage } from 'react-intl'
import { ControlType, FormControl } from '../componentsShared/FormControl'
import Button from '../componentsShared/Button'
import { bindValidationMessages } from '../../../utilities/validationMessage'
import { GenderEnum } from '../../../utilities/enums'
import _ from 'lodash'
import Loading from '../componentsShared/Loading'
import { saveAffiliate, updateAffiliate, getByAffiliateCode, getById } from '../../../redux/actions/aff/affiliateAction'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import { ROLE_NAME } from '../../../utilities/constant'

const RegisterAffiliate = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const validationMessage = bindValidationMessages()
  const [tabInfo, setTabInfo] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const { referralCode, affiliateId } = useParams()

  const [id, setId] = useState(affiliateId)
  const [affiliateCode, setAffiliateCode] = useState(null)
  const [affiliateName, setAffiliateName] = useState(null)
  const [birthday, setBirthday] = useState(null)
  const [genderId, setGenderId] = useState(null)
  const [isVietnamese] = useState(true)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)
  const [address, setAddress] = useState(null)
  const [affiliateParentCode, setAffiliateParentCode] = useState(referralCode)
  const [parrentAfffiliateId, setParrentAfffiliateId] = useState(null)
  const [affiliateParentName, setAffiliateParentName] = useState(null)
  const [isActive] = useState(true)

  const [currentPassword, setCurrentPassword] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [confirmNewPassword, setConfirmNewPassword] = useState(null)
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null)

  const [genders] = useState(
    Object.keys(GenderEnum).map((key) => ({
      value: GenderEnum[key],
      name: <FormattedMessage id={`Enum.${key}`} />,
    }))
  )

  useEffect(() => {
    if (referralCode) {
      validationAffiliate()
    }
  }, [referralCode])

  useEffect(() => {
    if (id) {
      fetchAffiliateById()
    }
  }, [referralCode, id])

  const fetchAffiliateById = async () => {
    try {
      const { result } = await getById(id)
      if (result) {
        setAffiliateCode(result?.afflicateCode ?? null)
        setAffiliateName(result?.affiliateName ?? null)
        setBirthday(result?.birthday != null ? new Date(result?.birthday) : null)
        setGenderId(result?.genderId ?? null)
        setPhone(result?.phone ?? null)
        setEmail(result?.email ?? null)
        setAddress(result?.address ?? null)
        setAffiliateParentCode(result?.affiliateParentCode ?? referralCode)
        setParrentAfffiliateId(result?.parrentAfffiliateId ?? null)
        setAffiliateParentName(result?.affiliateParentName ?? null)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const validationAffiliate = async () => {
    const { result } = await getByAffiliateCode('', affiliateParentCode)
    if (_.isEmpty(result)) {
      setParrentAfffiliateId(null)
      setAffiliateParentCode('')
      setAffiliateParentName('')
      return { error: true, message: validationMessage.invalid }
    } else {
      setParrentAfffiliateId(result.id)
      setAffiliateParentCode(result.afflicateCode)
      setAffiliateParentName(result.affiliateName)
    }
  }

  const validationAllComponents = async () => {
    let isValid = true

    await _.map(componentRefs.current, async (ref) => {
      if (!(await ref.validateData())) {
        isValid = false
      }
    })

    return isValid
  }

  const validatorConfirmNewPassword = () => {
    setIsValidConfirmPassword(true)
    if (_.isEmpty(newPassword) || _.isEmpty(confirmNewPassword)) return
    if (newPassword !== confirmNewPassword) {
      setIsValidConfirmPassword(false)
      return { error: true, message: `${intl.formatMessage({ id: 'User.ResetPassword.ValidatorConfirmPassword' })}` }
    }
  }

  const handleSave = async (e) => {
    e.preventDefault()

    if (!(await validationAllComponents())) {
      return
    }
    setIsLoading(true)

    try {
      const affSave = {
        affiliateName,
        birthday,
        genderId,
        isVietnamese,
        phone,
        email,
        address,
        parrentAfffiliateId,
        id: id ?? null,
        isActive: isActive,
        isAffiliate: true,
      }

      const { error, result } = id ? await updateAffiliate(affSave) : await saveAffiliate(affSave)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setId(result)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
        console.log(error)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderInfo = () => {
    return (
      <>
        <div className='card-body'>
          <div className='row'>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.affiliateName = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.AffiliateName' })}
                value={affiliateName}
                isRequired={true}
                onChange={(e) => setAffiliateName(e.target.value)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.birthday = ref)}
                tabIndex={2}
                controlType={ControlType.DatePicker}
                label={intl.formatMessage({ id: 'Affiliate.Birthday' })}
                value={birthday}
                isRequired={true}
                onChange={(date) => setBirthday(date)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.genderId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Affiliate.Gender' })}
                value={genderId}
                isRequired={true}
                onChange={(e) => setGenderId(e.value)}
                options={genders}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.phone = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Phone' })}
                value={phone}
                isRequired={true}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Email' })}
                value={email}
                isEmail={true}
                isRequired={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.address = ref)}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Affiliate.Address' })}
                value={address}
                isRequired={true}
                onChange={(e) => setAddress(e.target.value)}
                rows={2}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.affiliateParentCode = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.AfflicateCode' })}
                value={affiliateParentCode}
                onChange={(e) => setAffiliateParentCode(e.target.value)}
                validator={validationAffiliate}
                readOnly={referralCode || affiliateId ? true : false}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.affiliateParentName = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.AffiliateParentName' })}
                value={affiliateParentName}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-center py-5'>
          <Button
            onClick={(e) => handleSave(e)}
            title={intl.formatMessage({ id: id ? 'Update' : 'Register' })}
            className={'btn btn-primary d-flex align-items-center'}
            tabIndex={9}
          >
            <i className='fa fa-save'></i>
            <FormattedMessage id={id ? 'Update' : 'Register'} />
          </Button>
        </div>
      </>
    )
  }

  const renderAccount = () => {
    return (
      <>
        <div className='card-body'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='col-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.currentPassword = ref)}
                tabIndex={1}
                className={'form-control form-control-lg form-control-solid'}
                type={'password'}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.CurrentPassword' })}
                value={currentPassword}
                isRequired={true}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.newPassword = ref)}
                tabIndex={2}
                className={'form-control form-control-lg form-control-solid'}
                type={'password'}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.NewPassword' })}
                value={newPassword}
                isRequired={true}
                isPassword={true}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.confirmNewPassword = ref)}
                tabIndex={3}
                className={'form-control form-control-lg form-control-solid'}
                type={'password'}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.ConfirmNewPassword' })}
                value={confirmNewPassword}
                isRequired={true}
                isPassword={true}
                validator={() => validatorConfirmNewPassword()}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-center py-5'>
          <Button
            onClick={(e) => handleSave(e)}
            title={intl.formatMessage({ id: id ? 'Update' : 'Register' })}
            className={'btn btn-primary d-flex align-items-center'}
            tabIndex={9}
          >
            <i className='fa fa-save'></i>
            <FormattedMessage id={id ? 'Update' : 'Register'} />
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className='invite-customer'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Affiliate.Register' })}</h1>
            <PageTitle />
          </div>
        </div>
      </div>

      <form className='form my-5'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10'>
            <div className='card mb-5 border rounded'>
              <div className='d-flex flex-center flex-column p-4'>
                <div className='symbol symbol-100px symbol-circle mb-7'>
                  <img src='/media/no-avatar.png' alt='image' />
                </div>
                <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bold mb-3'>
                  {affiliateName}
                </a>
                <div className='badge badge-lg badge-light-primary d-inline'>{intl.formatMessage({ id: ROLE_NAME.AFFILIATE })}</div>
              </div>

              {affiliateId && (
                <div className='card-body p-4'>
                  <div className='fw-bold'>{intl.formatMessage({ id: 'Affiliate.AfflicateCode' })}</div>
                  <div className='text-gray-600'>{affiliateCode}</div>
                  <div className='fw-bold mt-5'>{intl.formatMessage({ id: 'Affiliate.Address' })}</div>
                  <div className='text-gray-600'>{address}</div>
                </div>
              )}
            </div>
          </div>

          <div className='flex-lg-row-fluid ms-lg-5'>
            <div className='card border border-top-0 rounded mb-5'>
              <div className='purchase-right-nav'>
                <div className='purchase-right-wrap'>
                  <section className='purchase-tablist'>
                    <a
                      className={`purchase-col-all nav-link ${tabInfo === 1 ? 'active text-active-primary purchase-border-primary' : ''}`}
                      onClick={() => setTabInfo(1)}
                    >
                      <span className='purchase-text-all'>{intl.formatMessage({ id: 'Affiliate.Infomation' })}</span>
                    </a>
                    {affiliateId && (
                      <a
                        className={`purchase-col-all nav-link ${tabInfo === 2 ? 'active text-active-primary purchase-border-primary' : ''}`}
                        onClick={() => setTabInfo(2)}
                      >
                        <span className='purchase-text-all'>{intl.formatMessage({ id: 'Affiliate.Account' })}</span>
                      </a>
                    )}
                  </section>
                </div>
              </div>
              {tabInfo === 1 ? renderInfo() : renderAccount()}
            </div>
          </div>
        </div>
      </form>
      {isLoading && <Loading />}
    </div>
  )
}
export default RegisterAffiliate
