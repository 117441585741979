import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useLocation } from 'react-router'
import { FormattedMessage } from 'react-intl'
import SVG from 'react-inlinesvg'
import _ from 'lodash'
import { toAbsoluteUrl, checkIsActive } from '../../../helpers'
import PageTitle from '../components/PageTitle'
import '../../../assets/sass/supplier.scss'
import RatingStar from '../components/RatingStar'

const Supplier = ({ supplier }) => {
  const location = useLocation()

  return (
    <div className='supplier-info'>
      <PageTitle />
      <div className='header-page mt-4 mb-8'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title d-flex justify-content-center align-items-center'>
            <div className='me-4'>
              <div className='rounded-pill border d-inline-block position-relative'>
                <img src={supplier?.logo ?? toAbsoluteUrl('/media/jpg/shop.jpg')} className='supplier-logo object-fit-contain rounded-pill' />
              </div>
            </div>
            <div className='me-4 border-end'>
              <div className='fw-bolder text-uppercase fs-2'>{supplier?.fullName}</div>
              <div className='row align-items-center'>
                <RatingStar rating={supplier?.rating} />
                <div className='my-1'>
                  <SVG src={toAbsoluteUrl('/media/svg/icon/location.svg')} />
                  {supplier?.businessAddress}
                </div>
              </div>
            </div>
          </div>

          <ul className='tags-list'>
            <li className='hover-up'>
              <Link to={`/supplier/${supplier?.id}/home`} className={`${checkIsActive(location.pathname, `/supplier/${supplier?.id}/home`) ? 'active' : ''}`}>
                <FormattedMessage id='Home' />
              </Link>
            </li>
            <li className='hover-up'>
              <Link
                to={`/supplier/${supplier?.id}/products`}
                className={`${checkIsActive(location.pathname, `/supplier/${supplier?.id}/product`) ? 'active' : ''}`}
              >
                <FormattedMessage id='AllProducts' />
              </Link>
            </li>
            <li className='hover-up'>
              <Link to={`/supplier/${supplier?.id}/reviews`} className={`${checkIsActive(location.pathname, `/supplier/${supplier?.id}/reviews`) ? 'active' : ''}`}>
                <FormattedMessage id='Rating' />
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className='supplier-content'>
        <Outlet />
      </div>
    </div>
  )
}

export default Supplier
