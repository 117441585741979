import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'
import Button from '../componentsShared/Button'
import Modal from '../componentsShared/Modal'
import { useState } from 'react'

const DeliveryAddressModal = ({ closeModal, addresses, selectedAddress, setSelectedAddress }) => {
  const intl = useIntl()
  const [address, setAddress] = useState(selectedAddress)
  const handleSave = () => {
    closeModal(false)
    if (address === selectedAddress) return
    setSelectedAddress({ ...address, fullAddress: formatAddress(address) })
  }

  const formatAddress = (item) => {
    const { address, ward, district, province } = item
    return `${address}, ${ward}, ${district}, ${province}`
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Checkout.ChooseDeliveryAddress' })} modalSize='mw-750px' closeModal={closeModal}>
      <div className='shipping-unit'>
        <div className='gray-color text-uppercase'>{intl.formatMessage({ id: 'Checkout.DeliveryAddress' })}</div>

        {addresses?.map((d, index) => (
          <div key={index} className='d-flex flex-column mb-3 mt-3 cursor-pointer' onClick={() => setAddress(d)}>
            <div className={`shipping-item ${address?.id === d.id ? 'active' : ''} d-flex align-items-center`}>
              <div className='me-5'>
                <div className='shipping-type'>{formatAddress(d)}</div>
              </div>
              {address?.id === d.id && (
                <div className='ml-auto'>
                  <img src={toAbsoluteUrl('../media/svg/icon/selected.svg')} alt='' className='stardust-icon stardust-icon-tick' />
                </div>
              )}
            </div>
          </div>
        ))}

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4} />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} />
        </div>
      </div>
    </Modal>
  )
}

export default DeliveryAddressModal
