import React, { useState, useEffect } from 'react'
import { Icon, toAbsoluteUrl } from '../../../helpers'
import PageTitle from '../components/PageTitle'
import { useIntl, FormattedMessage } from 'react-intl'
import NumberBox from '../componentsShared/NumberBox'
import SVG from 'react-inlinesvg'
import Button from '../componentsShared/Button'
import { getTotalCommissionMoney } from '../../../redux/actions/aff/affiliateCommissionMoneyAction'
import { getAccountBalance } from '../../../redux/actions/aff/affiliateAction'

const AccountBalance = () => {
  const intl = useIntl()
  const [monthlyCommission, setMonthlyCommission] = useState(0)
  const [commissionLastMonth, setCommissionLastMonth] = useState(0)
  const [accountBalance, setAccountBalane] = useState(0)

  useEffect(() => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth() + 1

    fetchData(currentYear, currentMonth, true)
    fetchData(currentYear, currentMonth - 1, false)
    fetchAccountBalance()
  }, [])

  const fetchData = async (year, month, isCurrent) => {
    try {
      const { result } = await getTotalCommissionMoney({
        year,
        month,
      })
      if (result) {
        if (isCurrent) {
          setMonthlyCommission(result?.totalCommissionMoney ?? 0)
        } else {
          setCommissionLastMonth(result?.totalCommissionMoney ?? 0)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAccountBalance = async () => {
    try {
      const { result } = await getAccountBalance()
      if (result) {
        setAccountBalane(result?.accountBalance ?? 0)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='account-balance'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Enum.AccountBalance' })}</h1>
            <PageTitle />
          </div>
        </div>
      </div>

      <div className='card shadow-sm rounded border my-5'>
        <div className='card-body d-flex flex-row align-items-center'>
          <div className='text-primary'>
            <SVG src={toAbsoluteUrl('/media/svg/icon/wallet-blue.svg')} />
          </div>
          <div className='w-100 ms-3'>
            <p className='fw-bold mb-1 lh-lg'>Có thể rút</p>
            <h4 className='text-primary'>
              <NumberBox type='text' value={accountBalance} className='' suffix='đ' />
            </h4>
          </div>
          <Button
            // onClick={}
            title={intl.formatMessage({ id: 'AccountBalance.Cash' })}
            className={'btn btn-primary d-flex align-items-center justify-content-center w-150px'}
          >
            <FormattedMessage id='AccountBalance.Cash' />
            <i className='ms-1 fa fa-angle-right'></i>
          </Button>
        </div>
      </div>

      <div className='card pb-5'>
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <div className='shadow-sm card-body rounded d-flex flex-row align-items-center'>
              <div className='text-primary'>
                <SVG src={toAbsoluteUrl('/media/svg/icon/wallet-red.svg')} />
              </div>
              <div className='w-100 ms-3'>
                <p className='fw-bold mb-1 lh-lg'>Doanh số tháng này</p>
                <h4 className='text-primary'>
                  <NumberBox type='text' value={monthlyCommission} className='' suffix='đ' />
                </h4>
              </div>
              <i className='ms-1 fa fa-angle-right'></i>
            </div>
          </div>
          <div className='col-lg-6 col-md-12'>
            <div className='shadow-sm card-body rounded d-flex flex-row align-items-center'>
              <div className='text-primary'>
                <SVG src={toAbsoluteUrl('/media/svg/icon/wallet-red.svg')} />
              </div>
              <div className='w-100 ms-3'>
                <p className='fw-bold mb-1 lh-lg'>Doanh số tháng trước</p>
                <h4 className='text-primary'>
                  {' '}
                  <NumberBox type='text' value={commissionLastMonth} className='' suffix='đ' />
                </h4>
              </div>
              <i className='ms-1 fa fa-angle-right'></i>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body pb-8'>
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <a className='text-dark' href='tel:1900633829' target='_blank'>
              <div className='shadow-sm card'>
                <div className='card-body'>
                  <div className='ratio mb-1c ratio-1x1' style={{ height: '68px', width: '110px' }}>
                    <img alt='' src={toAbsoluteUrl('../media/accountBalance/hotline.png')} loading='lazy' className='object-fit-cover' />
                  </div>
                  <div className='w-100'>
                    <h5 className='fs-2 mt-2' style={{ color: 'rgb(66, 103, 178)' }}>
                      Hotline
                    </h5>
                    <p className='mt-2'>Giải quyết các vấn đề liên quan đến đơn hàng</p>
                  </div>
                  <button type='button' className='btn btn-primary text-white position-absolute' style={{ width: '144px' }}>
                    Gọi ngay
                    <i className='ms-1 fa fa-angle-right'></i>
                  </button>
                </div>
              </div>
            </a>
          </div>
          <div className='col-lg-6 col-md-12'>
            <a className='text-dark' href='#' target='_blank'>
              <div className='shadow-sm card'>
                <div className='card-body'>
                  <div className='ratio mb-1c ratio-1x1' style={{ height: '68px', width: '110px' }}>
                    <img alt='' src={toAbsoluteUrl('../media/accountBalance/social.png')} loading='lazy' className='object-fit-cover' />
                  </div>
                  <div className='w-100'>
                    <h5 className='fs-2 mt-2' style={{ color: 'rgb(66, 103, 178)' }}>
                      Cộng đồng
                    </h5>
                    <p className='mt-2'>Giải đáp thắc mắc và trao đổi kinh nghiệm bán hàng</p>
                  </div>
                  <button type='button' className='btn btn-primary text-white position-absolute' style={{ width: '144px' }}>
                    Khám phá
                    <i className='ms-1 fa fa-angle-right'></i>
                  </button>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountBalance
