import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class ShoppingCartAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.SHOPPING_CART)
    this.adapter = this.initAdapter(conf)
  }

  async getByUserId(userId) {
    return await this.adapter.getAsync(`getByUserId?userId=${userId}`)
  }

  async saveShoppingCart(payload) {
    return await this.adapter.postAsync('saveShoppingCart', { payload })
  }

  async removeShoppingCart(userId, cartDetailId) {
    return await this.adapter.deleteAsync(`removeShoppingCart?userId=${userId}&cartDetailId=${cartDetailId}`)
  }
}

export default ShoppingCartAPI
