export const DiscountTypeEnum = {
  NoDiscount: 1,
  Percentage: 2,
  FixedPrice: 3,
  Bogo: 4,
  Freeship: 5,
}

export const ProductStatusEnum = {
  PendingApproval: 1,
  Approved: 2,
  StopSelling: 3,
  Rejected: 4,
}

export const FeaturedProductTypeEnum = {
  TopSelling: 1,
  TrendingProduct: 2,
  RecentlyAdded: 3,
  TopRated: 4,
  Hot: 5,
  New: 6,
}

export const ShippingMethodEnum = {
  Fast: 1,
  Saving: 2,
}

export const PromotionTypeEnum = {
  ProductPromotions: 1,
  CategoryPromotions: 2,
  SupplierPromotions: 3,
}

export const CouponTypeEnum = { Supplier: 1, System: 2, Ship: 3 }

export const CouponStatusEnum = { NotUsed: 1, InUse: 2, UsedUp: 3, Cancelled: 4 }

export const PaymentMethodEnum = { PaymentOnDelivery: 1, AccountBalance: 2, Paypal: 3, Momo: 4 }

export const PaymentStatusEnum = { Paid: 1, Unpaid: 2 }

export const OrderStatusEnum = {
  All: 1,
  WaitForConfirm: 2,
  PreparingGoods: 3,
  WaitForDelivery: 4,
  Complete: 5,
  Cancel: 6,
  ReturnRefund: 7,
}

export const ProductSortEnum = {
  TrendingProduct: 1,
  RecentlyAdded: 2,
  TopSelling: 3,
  PriceAscending: 4,
  PriceDescending: 5,
}

export const ReasonCancelEnum = {
  NoNeed: 1,
  ChangeAddress: 2,
  ChangeCoupon: 3,
  BuyElsewhere: 4,
  NoSuitableReason: 5,
}

export const AffiliateStatusEnum = {
  PendingApproval: 1,
  Approved: 2,
  Rejected: 3,
}

export const GenderEnum = {
  Male: 1,
  Female: 2,
  Other: 3,
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const getEnums = (enums, intl) => {
  return Object.keys(enums).map((key) => ({
    value: enums[key],
    name: intl.formatMessage({ id: `Enum.${key}` }),
  }))
}
