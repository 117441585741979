import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { MenuComponent } from '../../../assets/ts/components/index.ts'
import { PageDataProvider } from '../../pages/PageData'
import TopNavbar from './TopNavbar'
import ScrollTop from '../ScrollTop'
import { Footer } from './Footer'

const MasterLayout = () => {
  const location = useLocation()
  
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid bg-white'>
          <div id='kt_wrapper' className='wrapper d-flex flex-column flex-row-fluid'>
            <TopNavbar />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div id='kt_content_container' className='container-xxl pt-4'>
                <Outlet />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export default MasterLayout
