import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import { saveOrder } from '../../../redux/actions/eco/orderActions'
import { removeFromCart } from '../../../redux/actions/eco/shoppingCartActions'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import { OrderStatusEnum } from '../../../utilities/enums'
import NumberBox from '../componentsShared/NumberBox'

const CheckoutTotal = ({ orders }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const totalAmount = orders.reduce((acc, current) => acc + current.totalAmount, 0)
  const shippingAmount = orders.reduce((acc, current) => acc + current.shippingAmount, 0)
  const shippingCouponAmount = orders.reduce((acc, current) => acc + current.shippingCouponAmount, 0)
  const supplierCouponAmount = orders.reduce((acc, current) => acc + current.supplierCouponAmount, 0)
  const appCouponAmount = orders.length > 0 ? orders[0].appCouponAmount : 0
  const paymentAmount = totalAmount + shippingAmount - (shippingCouponAmount + supplierCouponAmount + appCouponAmount)

  const handleSaveOrder = async () => {
    if (orders?.length === 0) return

    if (orders.some((x) => _.isEmpty(x.affiliateCustomerRelationId) || _.isEmpty(x.deliveryAddress))) {
      showToast(`${intl.formatMessage({ id: 'Checkout.RequireChoseCustomer' })}`, TOAST_TYPES.ERROR)
      return
    }

    const { error, result } = await saveOrder(orders)
    if (_.isEmpty(error) && result) {
      // clear shoppingCart
      orders.map((x) => x.orderDetails.map((d) => dispatch(removeFromCart(d.id))))

      navigate(`/purchase/${OrderStatusEnum.All}`)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <div className='checkout-table-payment'>
      <h3 className='checkout-text-center checkout-text-gray'>{intl.formatMessage({ id: 'TotalAmount' })}</h3>
      <div className='checkout-text-center checkout-text-flex-end '>
        <NumberBox value={totalAmount} className='' suffix={DEFAULT_VALUE.VND} />
      </div>

      <h3 className='checkout-text-center checkout-text-gray checkout-payment-ship'>{intl.formatMessage({ id: 'Checkout.ShippingFee' })}</h3>
      <div className='checkout-text-center checkout-text-flex-end checkout-payment-ship'>
        <NumberBox value={shippingAmount} className='' suffix={DEFAULT_VALUE.VND} />
      </div>

      {shippingCouponAmount > 0 && (
        <>
          <h3 className='checkout-text-center checkout-text-gray checkout-discount-payment-ship'>{intl.formatMessage({ id: 'Checkout.DiscountShipping' })}</h3>
          <div className='checkout-text-center checkout-text-flex-end checkout-discount-payment-ship'>
            <NumberBox value={shippingCouponAmount} className='' suffix={DEFAULT_VALUE.VND} />
          </div>
        </>
      )}

      {(appCouponAmount > 0 || supplierCouponAmount > 0) && (
        <>
          <h3 className='checkout-text-center checkout-text-gray checkout-discount-payment'>{intl.formatMessage({ id: 'Checkout.TotalDiscountVoucher' })}</h3>
          <div className='checkout-text-center checkout-text-flex-end checkout-discount-payment'>
            <NumberBox value={supplierCouponAmount + appCouponAmount} className='' suffix={DEFAULT_VALUE.VND} />
          </div>
        </>
      )}

      <h3 className='checkout-text-center checkout-text-gray checkout-payment-all'>{intl.formatMessage({ id: 'Checkout.TotalPayment' })}</h3>
      <div className='checkout-text-center checkout-payment-highlight checkout-text-flex-end checkout-payment-all'>
        <NumberBox value={paymentAmount} className='' suffix={DEFAULT_VALUE.VND} />
      </div>
      <div className='checkout-payment-purchase'>
        <Link className='btn me-2' to='/'>
          <i className='pe-2 fa-solid fa-cart-shopping color-white' />
          {intl.formatMessage({ id: 'Checkout.ContinueShopping' })}
        </Link>

        <div className='checkout-payment-agree'>
          <div className='checkout-payment-agree-text'>
            {intl.formatMessage({ id: 'Click' })} <span className='text-brand'>{intl.formatMessage({ id: 'Checkout' })}</span>{' '}
            {intl.formatMessage({ id: 'Checkout.MeanYouAgree' })}{' '}
            <a className='text-brand'>
              {intl.formatMessage({ id: 'Checkout.Terms' })} {intl.formatMessage({ id: 'Green24h' })}
            </a>
          </div>
        </div>

        <a className='btn' onClick={() => handleSaveOrder()}>
          {intl.formatMessage({ id: 'Checkout' })}
          <i className='ps-2 fa-solid fa-right-from-bracket color-white' />
        </a>
      </div>
    </div>
  )
}

export default CheckoutTotal
