import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../helpers'
import { useIntl } from 'react-intl'
import '../../../../assets/sass/shoppingCart.scss'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromCart } from '../../../../redux/actions/eco/shoppingCartActions'
import NumberBox from '../../../pages/componentsShared/NumberBox'
import { DEFAULT_VALUE } from '../../../../utilities/constant'

export const ShoppingCartItems = ({ item }) => {
  const dispatch = useDispatch()

  return (
    <>
      <li key={item.id}>
        <Link to={`/product/${item.productId}`} className=''>
          <div className='shopping-cart-img'>
            <img src={DEFAULT_VALUE.BASE_URL + item.productImage} alt='' />
          </div>
          <div className='shopping-cart-title'>
            <h4 className='hover-text'>{item.productName}</h4>
            <h4>
              <span className='shopping-cart-quantity'>{item.quantity} ×</span>
              <span className='shopping-cart-price ps-1'>
                <NumberBox value={item.unitPrice} className='' suffix={DEFAULT_VALUE.VND} />
              </span>
            </h4>
          </div>
        </Link>
        <div className='shopping-cart-delete cursor-pointer' onClick={() => dispatch(removeFromCart(item.id))}>
          <i className='fa-sharp fa-solid fa-xmark' />
        </div>
      </li>
    </>
  )
}

const ShoppingCart = () => {
  const intl = useIntl()
  const cart = useSelector((state) => state.shoppingCart)
  const products = cart?.shoppingCartDetails

  return (
    <>
      <div className='header-action-icon-2 hover-text'>
        <a className='header-action-content'>
          <img src={toAbsoluteUrl('/media/svg/icon/icon-cart.svg')} alt='' />
          <span className='pro-count blue'>{products?.length}</span>
        </a>
        <a className='header-action-content'>
          <span className='ms-2 label'>{intl.formatMessage({ id: 'ShoppingCart' })}</span>
        </a>
      </div>

      <div className='cart-dropdown-wrap cart-dropdown-items'>
        <ul className='d-display pe-3'>
          {products?.map((item) => {
            return <ShoppingCartItems item={item} key={item.id} />
          })}
        </ul>
        {products?.length > 0 ? (
          <div className='shopping-cart-footer'>
            <div className='shopping-cart-total'>
              <h4>
                {intl.formatMessage({ id: 'TotalAmount' })}{' '}
                <span>
                  <NumberBox value={cart?.totalAmount} suffix={DEFAULT_VALUE.VND} className='' />
                </span>
              </h4>
            </div>
            <div className='shopping-cart-button'>
              <a className='outline' href={`/cart/0`}>
                {intl.formatMessage({ id: 'ViewCart' })}
              </a>
              <a href={`/checkout`}> {intl.formatMessage({ id: 'Checkout' })}</a>
            </div>
          </div>
        ) : (
          <div className='text-center'>{intl.formatMessage({ id: 'NoResult' })}</div>
        )}
      </div>
    </>
  )
}

export default ShoppingCart
