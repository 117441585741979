import { AffiliateCustomerRelation as AffiliateCustomerRelationAPI } from '../../../services'

// Async actions

const getById = async (affiliateCustomerRelationId) => {
  return await AffiliateCustomerRelationAPI.getById(affiliateCustomerRelationId)
}

const getAffiliateCustomerRelations = async (payload) => {
  return await AffiliateCustomerRelationAPI.getAffiliateCustomerRelations(payload)
}

const saveAffiliateCustomerRelation = async (payload) => {
  return await AffiliateCustomerRelationAPI.saveAffiliateCustomerRelation(payload)
}

const updateAffiliateCustomerRelation = async (payload) => {
  return await AffiliateCustomerRelationAPI.updateAffiliateCustomerRelation(payload)
}

const deleteAffiliateCustomerRelation = async (affiliateCustomerRelationId) => {
  return await AffiliateCustomerRelationAPI.deleteAffiliateCustomerRelation(affiliateCustomerRelationId)
}

export { getById, getAffiliateCustomerRelations, saveAffiliateCustomerRelation, updateAffiliateCustomerRelation, deleteAffiliateCustomerRelation }
