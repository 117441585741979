import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withPageData } from '../PageData'

class PageTitle extends Component {
    render() {
        const { pageTitle, pageDescription, pageBreadcrumbs, isHideTitle } = this.props
        return (
            <div
                className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                {pageBreadcrumbs && pageBreadcrumbs.length > 0 && (
                    <ul className='breadcrumb fw-bold my-1'>
                        {pageBreadcrumbs.map((item, index) => (
                            <Fragment key={`${item.path}${index}`}>
                                {
                                    <li className="breadcrumb-item">
                                        <Link to={item.path} className="text-primary">
                                            {item.title}
                                        </Link>
                                    </li>
                                }
                            </Fragment>
                        ))}
                        <li className='breadcrumb-item text-muted'>{pageTitle}</li>
                    </ul>
                )}
                {!isHideTitle && <h1 className='text-dark fw-bolder my-0 fs-2'>
                    {pageTitle && pageTitle}
                    {pageDescription && (
                        <span className='text-muted fs-6 fw-normal ms-1'>{pageDescription}</span>
                    )}
                </h1>}
            </div>
        )
    }
}

export default withPageData(PageTitle)