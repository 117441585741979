import { Order as OrderAPI } from '../../../services'

const saveOrder = async (payload) => {
  return await OrderAPI.saveOrder(payload)
}

const getOrders = async (payload) => {
  return await OrderAPI.getOrders(payload)
}

const cancel = async (payload) => {
  return await OrderAPI.cancel(payload)
}

const getOrderRepurchaseById = async (orderId) => {
  return await OrderAPI.getOrderRepurchaseById(orderId)
}

export { saveOrder, getOrders, cancel, getOrderRepurchaseById }
