import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { toAbsoluteUrl, checkIsActive } from '../../../../helpers'
import { Link } from 'react-router-dom'
import { getCategories } from '../../../../redux/actions/eco/categoryActions'
import { DEFAULT_VALUE } from '../../../../utilities/constant'

const Categories = () => {
  const location = useLocation()
  const categoryMenuRef = useRef(null)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [categories, setCategories] = useState([])
  const firstHalfCategories = categories.slice(0, Math.ceil(categories.length / 2))
  const secondHalfCategories = categories.slice(Math.ceil(categories.length / 2))

  useEffect(() => {
    fetchCategories()
  }, [])

  const fetchCategories = async () => {
    try {
      const { result } = await getCategories()
      if (result) {
        setCategories(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (categoryMenuRef.current && !categoryMenuRef.current.contains(event.target) && isOpenDropdown) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpenDropdown])

  const handleClick = () => {
    setIsOpenDropdown(!isOpenDropdown)
  }

  return (
    <div className='header-wrap bg-white p-4 header-bottom-bg-color'>
      <div className='container-xxl d-flex justify-content-between px-0'>
        <div className='header-nav d-flex align-items-center'>
          <div className='main-categories-wrap'>
            <a className='categories-button cursor-pointer' onClick={handleClick} ref={categoryMenuRef}>
              <i className='fa fa-bars text-white fs-3' aria-hidden='true'></i>
              <span className='mx-3'>
                <FormattedMessage id='AllCategories' />
              </span>
              <i className='fa fa-angle-down text-white' aria-hidden='true'></i>
            </a>
            <div className={`categories-dropdown-wrap categories-dropdown-large ${isOpenDropdown ? 'open' : ''}`}>
              <div className='categori-dropdown-inner row-cols-2'>
                <ul>
                  {firstHalfCategories.map((category) => {
                    return (
                      <li key={`categories-menu-${category.id}`}>
                        <Link to={`/categories/${category.id}`}>
                          <img src={DEFAULT_VALUE.BASE_URL + category.logo} />
                          <span>{category.categoryName}</span>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
                <ul className='end'>
                  {secondHalfCategories.map((category) => {
                    return (
                      <li key={`categories-menu-${category.id}`}>
                        <Link to={`/categories/${category.id}`}>
                          <img src={DEFAULT_VALUE.BASE_URL + category.logo} />
                          {category.categoryName}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className='main-menu'>
            <ul>
              <li>
                <Link to='/home'>
                  <FormattedMessage id='Home' />
                </Link>
              </li>
              <li>
                <div className='menu-item p-0' data-kt-menu-trigger='hover' data-kt-menu-placement='bottom-end' data-kt-menu-flip='bottom'>
                  <a className='content menu-link'>
                    <FormattedMessage id='Categories' />
                    <i className='ms-1 fa fa-angle-down'></i>
                  </a>
                </div>

                <div className='menu-state-bg menu-sub menu-sub-dropdown py-4' data-kt-menu='true'>
                  {categories.map((category) => {
                    return (
                      <div className='content menu-item px-3' key={`categories-dropdown-${category.id}`}>
                        <Link
                          className={`menu-link d-flex px-5 ${checkIsActive(location.pathname, `/categories/${category.id}`) ? 'active' : ''}`}
                          to={`/categories/${category.id}`}
                        >
                          {category.categoryName}
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </li>
              <li>
                <Link to='/suppliers'>
                  <FormattedMessage id='Supplier' />
                </Link>
              </li>
              <li>
                <a href='/contact'>
                  <FormattedMessage id='Contact' />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='hotline d-flex align-items-center'>
          <img src={toAbsoluteUrl('/media/svg/icon/icon-headphone.svg')} alt='hotline' />
          <p>
            1900 - 888
            <span>
              24/7 <FormattedMessage id='SupportCenter' />
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Categories
