import { useLang, setLanguage } from '../../../../language/Appi18nProvider'
import { languages } from '../../../../language/constant'

const Languages = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  return (
    <>
      <div className='menu-item p-0' data-kt-menu-trigger='hover' data-kt-menu-placement='bottom-end' data-kt-menu-flip='bottom'>
        <a className='content menu-link'>
          <span className='menu-title position-relative'>
            <img className='w-15px h-15px rounded-1' src={currentLanguage?.flag} alt='flag' />
            <span className='ms-1'>{currentLanguage?.name}</span>
          </span>
        </a>
      </div>

      <div className='menu-gray-600 menu-state-bg menu-sub menu-sub-dropdown w-175px py-4' data-kt-menu='true'>
        {languages.map((l) => (
          <div
            className='content menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a className={`menu-link d-flex px-5 ${l.lang === currentLanguage?.lang ? 'active' : ''}`}>
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='flag' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </>
  )
}

export default Languages
