import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class ProductCategoryAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.PRODUCT_CATEGORY)
    this.adapter = this.initAdapter(conf)
  }

  async getByProductId(productId) {
    return await this.adapter.getAsync(`getByProductId?productId=${productId}`)
  }
}

export default ProductCategoryAPI
