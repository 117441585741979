import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import 'animate.css/animate.min.css'
import { DEFAULT_VALUE } from '../../../utilities/constant'

const Banner = ({ banners }) => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <section id='home-banner'>
      <div className='card mb-8 mb-xl-14'>
        <Carousel activeIndex={index} onSelect={handleSelect} nextIcon={<span aria-hidden='true' className='carousel-control-next-icon changed' />}>
          {banners.map((banner) => (
            <Carousel.Item
              key={banner.id}
              interval={
                banner.displaySeconds
                  ? banner.displaySeconds * DEFAULT_VALUE.THOUDSAND_MILLISECOND
                  : DEFAULT_VALUE.DISPLAY_SECOND * DEFAULT_VALUE.THOUDSAND_MILLISECOND
              }
            >
              <img className='d-block w-100 rounded-4' src={DEFAULT_VALUE.BASE_URL + banner.imageUrl} alt='' />
              <Carousel.Caption>
                <div className='position-relative'>
                  <div className='newsletter-content'>
                    <h2>{banner.title}</h2>
                    <p>{banner.subTitle}</p>
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default Banner
