import { useIntl } from 'react-intl'
import Button from '../componentsShared/Button'
import Modal from '../componentsShared/Modal'
import { toAbsoluteUrl } from '../../../helpers'
import { useState } from 'react'
import { cancel } from '../../../redux/actions/eco/orderActions'
import { OrderStatusEnum, ReasonCancelEnum, getEnums } from '../../../utilities/enums'
import { useNavigate } from 'react-router-dom'

const ReasonCancelModal = ({ closeModal, order }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [reasonCancels] = useState(() => getEnums(ReasonCancelEnum, intl))
  const [reason, setReason] = useState(null)

  const handleSave = async () => {
    if (!reason) return

    const req = { orderId: order.id, reasonCancel: reason.value }

    const { error, result } = await cancel(req)
    if (result) {
      navigate(`/purchase/${OrderStatusEnum.Cancel}`)
    } else {
      console.log(error)
    }
    closeModal(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Product.ChooseReasonCancel' })} modalSize='mw-650px' closeModal={closeModal}>
      <div className='shipping-unit'>
        <div className='gray-color text-uppercase'>{intl.formatMessage({ id: 'Product.CancellationReason' })}</div>

        <div className='d-flex flex-column mb-3 mt-3 cursor-pointer'>
          {reasonCancels.map((d, index) => (
            <div
              key={d.value + index}
              className={`shipping-item d-flex align-items-center ${d.value === reason?.value ? 'active' : ''} ${index === 0 ? '' : 'mt-2'}`}
              onClick={() => setReason(d)}
            >
              <div className='me-3'>
                <div className='shipping-type'>{d.name}</div>
                <div className='shipping-time gray-color'></div>
                <div className='shipping-voucher gray-color'></div>
              </div>
              {d.value === reason?.value && (
                <div className='ml-auto'>
                  <img src={toAbsoluteUrl('../media/svg/icon/selected.svg')} alt='' className='stardust-icon stardust-icon-tick' />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4} />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} />
        </div>
      </div>
    </Modal>
  )
}

export default ReasonCancelModal
