import React, { useState, useEffect } from 'react'
import { Route, Routes, useParams, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { Category } from '../category'
import { PageTitle } from '../PageData'
import { getById } from '../../../redux/actions/eco/categoryActions'

const CategoryPage = () => {
  const intl = useIntl()
  const { categoryId } = useParams()
  const [item, setItem] = useState({})

  useEffect(() => {
    fetchCategories()
  }, [])

  const fetchCategories = async () => {
    try {
      const { result } = await getById({ id: categoryId })
      if (result) {
        setItem(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }
  
  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {item.categoryName}
            </PageTitle>
            <Category />
          </>
        }
      />
    </Routes>
  )
}

export default CategoryPage
