import React, { useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ControlType, FormControl } from '../componentsShared/FormControl'
import { ProductSortEnum } from '../../../utilities/enums'

const SortBar = ({ currentPage, totalPages, onPageChange, priceSort, featuredSort, onPriceSort, onFeaturedSort }) => {
  const componentRefs = useRef({})
  const intl = useIntl()

  const [sortPrices] = useState(
    Object.keys(ProductSortEnum)
      .filter((key) => ProductSortEnum[key] === ProductSortEnum.PriceAscending || ProductSortEnum[key] === ProductSortEnum.PriceDescending)
      .map((key) => ({
        value: ProductSortEnum[key],
        name: intl.formatMessage({ id: `SortProduct.${key}` }),
      }))
  )

  return (
    <div className='sort-bar'>
      <span><FormattedMessage id='SortBy' /></span>
      <div className='sort-by-options'>
        <div className='option-group'>
          <button aria-pressed='true' onClick={() => onFeaturedSort(ProductSortEnum.TrendingProduct)} className={`option ${featuredSort === ProductSortEnum.TrendingProduct ? 'selected' : ''}`}>
            <span aria-hidden='true'>
              <FormattedMessage id='SortProduct.TrendingProduct' />
            </span>
          </button>
          <button aria-pressed='false' onClick={() => onFeaturedSort(ProductSortEnum.RecentlyAdded)} className={`option ${featuredSort === ProductSortEnum.RecentlyAdded ? 'selected' : ''}`}>
            <span aria-hidden='true'>
              <FormattedMessage id='SortProduct.RecentlyAdded' />
            </span>
          </button>
          <button aria-pressed='false' onClick={() => onFeaturedSort(ProductSortEnum.TopSelling)} className={`option ${featuredSort === ProductSortEnum.TopSelling ? 'selected' : ''}`}>
            <span aria-hidden='true'>
              <FormattedMessage id='SortProduct.TopSelling' />
            </span>
          </button>
        </div>

        <div>
          <FormControl
            ref={(ref) => (componentRefs.current.priceSort = ref)}
            tabIndex={3}
            controlType={ControlType.Select}
            options={sortPrices}
            value={priceSort}
            onChange={(e) => onPriceSort(e.value)}
            isHideLabel={true}
            isClearable={false}
          />
        </div>
      </div>

      <div className='mini-page-controller'>
        <div className='state'>
          <span className='current'>{currentPage}</span>/<span className='total'>{totalPages}</span>
        </div>
        <a
          onClick={() => onPageChange(currentPage - 1)}
          className={`prev-btn btn button-outline ms-5 ${currentPage > 1 && currentPage <= totalPages ? '' : 'disabled'}`}
        >
          <i className='fa fa-angle-left' aria-hidden='true'></i>
        </a>

        <a onClick={() => onPageChange(currentPage + 1)} className={`next-btn btn button-outline ${currentPage < totalPages ? '' : 'disabled'}`}>
          <i className='fa fa-angle-right' aria-hidden='true'></i>
        </a>
      </div>
    </div>
  )
}
export default SortBar
