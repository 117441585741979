import React from 'react'
import Tooltip from './Tooltip'

const Label = ({ title, isRequired, className, contentTooltip, ...restProps }) => {
  return (
    <>
      <div className="label-container">
        <label className={`${isRequired ? 'required' : ''} ${className ?? 'fw-bold fs-6 mb-2'}`} {...restProps}>
          {title}
          {contentTooltip && <Tooltip content={contentTooltip} />}
        </label>
      </div>
    </>
  )
}

export default Label
