import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class CouponAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.COUPON)
    this.adapter = this.initAdapter(conf)
  }

  async getCoupons(payload) {
    return await this.adapter.postAsync('getCoupons', { payload })
  }
}

export default CouponAPI
