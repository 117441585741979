const MarqueeText = ({ items }) => {
  return (
    <section id='home-marquee-text'>
      <div className='mb-5 mb-xl-10'>
        <div className='marquee-container'>
          <div className='marquee-content'>
            {items.map((text, index) => (
              <span key={index}>{text}</span>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MarqueeText
