import { useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import Button from '../componentsShared/Button'
import { useIntl } from 'react-intl'
import Modal from '../componentsShared/Modal'

const CustomerRelationModal = ({ closeModal, customerRelationSelected, setCustomerRelationSelected, customerRelations }) => {
  const intl = useIntl()
  const [customerRelation, setCustomerRelation] = useState(customerRelationSelected)
  const handleSave = () => {
    setCustomerRelationSelected(customerRelation)
    closeModal(false)
  }
  return (
    <Modal title={intl.formatMessage({ id: 'Checkout.ChooseCustomer' })} modalSize='mw-750px' closeModal={closeModal}>
      <div className='shipping-unit'>
        <div className='gray-color text-uppercase'>{intl.formatMessage({ id: 'Checkout.Customers' })}</div>

        {customerRelations?.map((d, index) => (
          <div key={index} className='d-flex flex-column mb-3 mt-3 cursor-pointer' onClick={() => setCustomerRelation(d)}>
            <div className={`shipping-item ${customerRelation?.id === d.id ? 'active' : ''} d-flex align-items-center`}>
              <div className='me-5'>
                <div className='shipping-type'>{d.name + ` (${d.phone})`}</div>
              </div>
              {customerRelation?.id === d.id && (
                <div className='ml-auto'>
                  <img src={toAbsoluteUrl('../media/svg/icon/selected.svg')} alt='' className='stardust-icon stardust-icon-tick' />
                </div>
              )}
            </div>
          </div>
        ))}

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4} />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} />
        </div>
      </div>
    </Modal>
  )
}

export default CustomerRelationModal
