/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Icon } from '../../../helpers'
import PageTitle from '../components/PageTitle'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../helpers'
import { useIntl, FormattedMessage } from 'react-intl'
import { Auth } from '../../../components/auth/Auth'
import { getById, getAffiliates } from '../../../redux/actions/aff/affiliateAction'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Pagination from '../componentsShared/Pagination'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import Loading from '../componentsShared/Loading'
import { AffiliateStatusEnum, getKeyByValue } from '../../../utilities/enums'

const InviteCustomer = () => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [affiliate, setaffiliate] = useState(null)
  const [affiliates, setAffiliates] = useState(null)

  useEffect(() => {
    fetchAffiliate()
    fetchAffiliates()
  }, [])

  const fetchAffiliate = async () => {
    try {
      const { result } = await getById(currentUser?.affiliateId)
      if (result) {
        setaffiliate(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAffiliates = async (afflicateCode, affiliateName, email, phone) => {
    setIsLoading(true)
    try {
      const { result } = await getAffiliates({
        pageIndex: currentPage,
        pageSize: pageSize,
        isAffiliate: 1,
        afflicateCode,
        affiliateName,
        email,
        phone,
        parrentAfffiliateId: currentUser?.affiliateId,
      })

      if (result) {
        setAffiliates(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
  }

  return (
    <div className='invite-customer'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'IntroduceCollaborator' })}</h1>
            <PageTitle />
          </div>
        </div>
      </div>

      {/* <div className='card my-5'>
        <div className='card-body rounded border'>
          <div className='d-flex flex-row flex-wrap justify-content-between align-items-center'>
            <span>
              Tổng người mời:
              <span className='fw-bold text-primary ms-1'>
                <NumberBox type='text' value={0} className='' />
              </span>
            </span>
            <span>
              Doanh số tháng này:
              <span className='fw-bold text-primary ms-1'>
                <NumberBox type='text' value={0} className='' suffix='đ' />
              </span>
            </span>
            <span>
              Doanh số tháng trước:
              <span className='fw-bold text-primary ms-1'>
                <NumberBox type='text' value={0} className='' suffix='đ' />
              </span>
            </span>
          </div>
        </div>
      </div> */}

      <div className='d-flex flex-column flex-xl-row pt-10'>
        <div className='flex-column flex-lg-row-auto w-100 w-xl-450px mb-5'>
          <div className='card border'>
            <div className='card-body d-flex flex-row'>
              <div className='text-primary'>
                <SVG src={toAbsoluteUrl('/media/svg/icon/people-100.svg')} />
              </div>
              <div className='w-100 ms-5'>
                <p className='fw-bold mb-0'>Mã mời bạn</p>
                <p className='text-primary fs-5 fw-bolder mb-0'>
                  {affiliate?.afflicateCode ?? '...'}
                  <a className={'btn btn-sm btn-light-primary bg-transparent mx-3'} onClick={() => handleCopyClick(affiliate?.afflicateCode)}>
                    <Icon iconName='copy' className='fs-3' />
                  </a>
                </p>
                <p className='fw-bold mb-0'>Link mời bạn</p>
                <p className='text-primary mb-0 text-break'>
                  {`${window.location.href}/${affiliate?.afflicateCode}/register`}
                  <a className={'btn btn-sm btn-light-primary bg-transparent mx-3'} onClick={() => handleCopyClick(`${window.location.href}/${affiliate?.afflicateCode}/register`)}>
                    <Icon iconName='copy' className='fs-3' />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <Link
            className='btn btn-primary w-100 mt-5 d-flex align-items-center justify-content-center'
            to={`/pages/affiliate/${affiliate?.afflicateCode}/register`}
          >
            <Icon iconName='plus' className='fs-2' />
            <FormattedMessage id='AddNew' />
          </Link>
        </div>

        <div className='flex-lg-row-fluid ms-xl-5'>
          <div className='card border w-100 mb-5 min-h-500px'>
            <div className={`card-body ${_.isEmpty(affiliates) ? 'd-flex align-items-center' : ''} `}>
              {_.isEmpty(affiliates) ? (
                <div className='text-center w-100'>
                  <SVG src={toAbsoluteUrl('/media/svg/icon/sad-face.svg')} />
                  <p className='mt-2 fs-6'>Danh sách đang trống</p>
                </div>
              ) : (
                <div className='table-responsive w-100'>
                  <table className='table dataTable align-middle table-row-dashed table-hover'>
                    <thead>
                      <tr className='text-start fw-bold text-uppercase'>
                        <th className='w-200px'>{intl.formatMessage({ id: 'Affiliate.AffiliateName' })}</th>
                        <th className='w-100px'>{intl.formatMessage({ id: 'Affiliate.Email' })}</th>
                        <th className='w-100px'>{intl.formatMessage({ id: 'Affiliate.Phone' })}</th>
                        <th className='w-100px'>{intl.formatMessage({ id: 'Affiliate.ApproveStatusName' })}</th>
                        <th className='w-100px'>{intl.formatMessage({ id: 'Affiliate.RegisterDate' })}</th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold'>
                      {affiliates.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>{item.affiliateName}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>
                              <span
                                className={`badge badge-${
                                  item.approveStatusId === AffiliateStatusEnum.PendingApproval
                                    ? 'primary'
                                    : item.approveStatusId === AffiliateStatusEnum.Approved
                                    ? 'success'
                                    : 'secondary'
                                } me-1 fs-7`}
                              >
                                <FormattedMessage id={`Enum.${getKeyByValue(AffiliateStatusEnum, item.approveStatusId)}`} />
                              </span>
                            </td>
                            <td>{item.registerDateStr}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    itemsPerPage={pageSize}
                    onPageChange={(e) => setCurrentPage(e)}
                    onItemsPerPageChange={(e) => setPageSize(e)}
                  />

                  {isLoading && <Loading />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteCustomer
