import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class ProductAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.PRODUCT)
    this.adapter = this.initAdapter(conf)
  }

  async getById(productId) {
    return await this.adapter.getAsync(`getById?id=${productId}`)
  }

  async getProductsByCategoryId(categoryId, pageIndex, pageSize) {
    return await this.adapter.getAsync(`getProductsByCategoryId?categoryId=${categoryId}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
  }

  async searchProducts(payload) {
    return await this.adapter.postAsync('searchProducts', { payload })
  }
}

export default ProductAPI
