import ImageCarousel from '../components/ImageCarousel'
import Product from '../components/Product'
import { FormattedMessage } from 'react-intl'

const OtherProduct = ({ products }) => {
  return (
    <section>
      <div className='pt-8 mb-8 mb-xl-14'>
        <div className='d-flex justify-content-between'>
          <h1 className='fw-bolder'>
            <FormattedMessage id='OtherProduct' />
          </h1>
          <div className='fw-bold text-primary'>
            <FormattedMessage id='ViewAll' />
            <i className='fa fa-angle-right ms-2 text-primary' aria-hidden='true'></i>
          </div>
        </div>

        <ImageCarousel Component={Product} products={products} columns={5} />
      </div>
    </section>
  )
}

export default OtherProduct
