import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'

const CheckoutPayment = ({ paymentMethods, paymentMethodSelected, onChangePaymentMethod }) => {
  const intl = useIntl()
  return (
    <>
      <div className='checkout-payment-wrap'>
        <div style={{ display: 'contents' }}>
          <div>
            <div className='checkout-payment-method checkout-payment-setting'>
              <div className='checkout-payment-method-title'>{intl.formatMessage({ id: 'PaymentMethod' })}</div>
              <div className='checkout-payment-method-tab'>
                {paymentMethods.map((d, index) => (
                  <span key={d.value}>
                    <button
                      className={`product-variation ${d.value === paymentMethodSelected.value ? 'product-variation-selected' : 'product-variation-disabled'}`}
                      onClick={() => onChangePaymentMethod(d)}
                      disabled={true}
                    >
                      {d.name}
                      {d.value === paymentMethodSelected.value && (
                        <div className='product-variation_tick'>
                          <img src={toAbsoluteUrl('../media/svg/icon/tick.svg')} alt='' className='checkout-svg-icon tick-svg me-2 cursor-pointer' />
                        </div>
                      )}
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckoutPayment
