import { Route, Routes, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { MasterLayout } from '../components/layout'
import { PageTitle } from '../components/pages/PageData'
import { Home } from '../components/pages/home'
import { CategoryPage } from '../components/pages/category'
import { ProductDetailPage } from '../components/pages/productDetail'
import { SupplierPage, ListSupplierPage } from '../components/pages/supplier'
import { CustomerPage } from '../components/pages/customer'
import { InviteCustomerPage } from '../components/pages/inviteCustomer'
import { OrderPage } from '../components/pages/order'
import { PurchasePage } from '../components/pages/purchase'
import { CheckoutPage } from '../components/pages/checkout'
import { AccountBalancePage } from '../components/pages/accountBalance'
import Search from '../components/pages/search'
import { MyOfferPage } from '../components/pages/myOffer'

const AffRoutes = () => {
  const intl = useIntl()

  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }

  const SearchPage = () => {
    return (
      <>
        <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
          {intl.formatMessage({ id: 'Search' })}
        </PageTitle>
        <Search />
      </>
    )
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/home' />} />
        <Route path='home' element={<Home />} />
        <Route path='search/:categoryId/:searchValue' element={<SearchPage />} />
        <Route path='search/:categoryId' element={<SearchPage />} />
        <Route path='categories/:categoryId/*' element={<CategoryPage />} />
        <Route path='product/:productId/*' element={<ProductDetailPage />} />
        <Route path='supplier/:supplierId/*' element={<SupplierPage />} />
        <Route path='suppliers/*' element={<ListSupplierPage />} />
        <Route path='/pages/customer' element={<CustomerPage />} />
        <Route path='/cart/:cartDetailId/*' element={<OrderPage />} />
        <Route path='/checkout/*' element={<CheckoutPage />} />
        <Route path='/purchase/:type/*' element={<PurchasePage />} />
        <Route path='/pages/affiliate/*' element={<InviteCustomerPage />} />
        <Route path='/pages/accountBalance' element={<AccountBalancePage />} />
        <Route path='/pages/my-offer' element={<MyOfferPage />} />
      </Route>
    </Routes>
  )
}

export { AffRoutes }
