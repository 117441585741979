import React, { useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { register } from '../../../redux/actions/auth/authActions'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, ControlType } from '../../pages/componentsShared/FormControl'
import _ from 'lodash'
import { login } from '../../../redux/actions/auth/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { bindValidationMessages } from '../../../utilities/validationMessage'
import * as userApi from '../../../redux/actions/auth/userActions'

const Registration = () => {
  const intl = useIntl()
  const location = useLocation()
  const componentRefs = useRef({})
  const dispatch = useDispatch()
  const validationMessage = bindValidationMessages()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(useSelector((state) => state.auth.error))

  const urlParams = new URLSearchParams(location.search)
  const returnUrl = urlParams.get('returnUrl')
  const loginLink = returnUrl ? `/auth/login?returnUrl=${encodeURIComponent(returnUrl)}` : '/auth/login'

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const validationAllComponents = async () => {
    let isValid = true

    await _.map(componentRefs.current, async (ref) => {
      if (!(await ref.validateData())) {
        isValid = false
      }
    })

    return isValid
  }

  const validationUserName = async () => {
    const { error, result } = await userApi.checkDuplicateUserName({ email })
    if (!_.isEmpty(error) || result) {
      if (!_.isEmpty(error)) {
        return { error: true, message: error.message }
      }
      return { error: true, message: validationMessage.isExisted }
    }
  }

  const validationEmail = async () => {
    const { error, result } = await userApi.checkDuplicateEmail({ email })
    if (!_.isEmpty(error) || result) {
      if (!_.isEmpty(error)) {
        return { error: true, message: error?.message }
      }
      return { error: true, message: validationMessage.isExisted }
    }
  }

  const comparePasswords = () => {
    if (password !== confirmPassword) {
      return { error: true, message: validationMessage.confirmPasswordInvalid }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const isValid = await validationAllComponents()
      if (!isValid) return

      const { error } = await register({ firstName, lastName, phoneNumber, email, password, confirmPassword })
      if (error) {
        setError(error.message)
      } else {
        await dispatch(login(email, password, returnUrl))
      }
    } catch (error) {
      console.error('An error occurred:', error)
    } finally {
      setLoading(false)
    }
  }

  const formattedError = error?.includes('\n')
    ? error.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))
    : error

  return (
    <form className='form w-100' onSubmit={handleSubmit}>
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>
          <FormattedMessage id='Register' />
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>Green24h.com</div>
      </div>

      {error && (
        <div className='alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formattedError}</div>
        </div>
      )}

      <div className='d-flex gap-4'>
        <div className='fv-row mb-8 w-50'>
          <FormControl
            ref={(ref) => (componentRefs.current.firstName = ref)}
            controlType={ControlType.Input}
            label={intl.formatMessage({ id: 'Register.FirstName' })}
            value={firstName}
            isRequired={true}
            isHideLabel={true}
            onChange={(e) => setFirstName(e.target.value)}
            className={'form-control mb-3 py-3 px-4'}
          />
        </div>

        <div className='fv-row mb-8 w-50'>
          <FormControl
            ref={(ref) => (componentRefs.current.lastName = ref)}
            controlType={ControlType.Input}
            label={intl.formatMessage({ id: 'Register.LastName' })}
            value={lastName}
            isRequired={true}
            isHideLabel={true}
            onChange={(e) => setLastName(e.target.value)}
            className={'form-control mb-3 py-3 px-4'}
          />
        </div>
      </div>

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.email = ref)}
          controlType={ControlType.Input}
          label={intl.formatMessage({ id: 'Email' })}
          value={email}
          isEmail={true}
          isRequired={true}
          isHideLabel={true}
          onChange={(e) => setEmail(e.target.value)}
          className={'form-control mb-3 py-3 px-4'}
          validator={validationEmail}
        />
      </div>

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.phoneNumber = ref)}
          controlType={ControlType.Input}
          label={intl.formatMessage({ id: 'PhoneNumber' })}
          value={phoneNumber}
          isRequired={true}
          isHideLabel={true}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className={'form-control mb-3 py-3 px-4'}
        />
      </div>

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.password = ref)}
          controlType={ControlType.Input}
          label={intl.formatMessage({ id: 'Login.Password' })}
          value={password}
          isRequired={true}
          isHideLabel={true}
          onChange={(e) => setPassword(e.target.value)}
          className={'form-control mb-3 py-3 px-4'}
          type='password'
          validator={comparePasswords}
        />
      </div>
      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.confirmPassword = ref)}
          controlType={ControlType.Input}
          label={intl.formatMessage({ id: 'Register.ConfirmPassword' })}
          value={confirmPassword}
          isRequired={true}
          isHideLabel={true}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={'form-control mb-3 py-3 px-4'}
          type='password'
          validator={comparePasswords}
        />
      </div>

      <div className='d-grid mb-10'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='Register' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Confirm.PleaseWait' />
              ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        <FormattedMessage id='Register.HaveAMemberYet' />{' '}
        <Link to={loginLink} className='link-primary fw-semibold'>
          <FormattedMessage id='Login' />
        </Link>
      </div>
    </form>
  )
}

export default Registration
