import React, { useState, useEffect } from 'react'
import { Route, Routes, useParams, Navigate, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { PageTitle } from '../PageData'
import { Supplier, ListProduct, ListReview, SupplierHome } from '.'
import { getSupplierById } from '../../../redux/actions/eco/supplierActions'

const SupplierPage = () => {
  const intl = useIntl()
  const { supplierId } = useParams()
  const [supplier, setSupplier] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchSupplier()
  }, [supplierId])

  const fetchSupplier = async () => {
    try {
      const { result } = await getSupplierById(supplierId)
      if (result) {
        setSupplier(result)
      } else {
        navigate(`/notFound`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadBreadcrumbs = () => {
    let item = [
      { title: intl.formatMessage({ id: 'Home' }), path: '/home' },
      { title: intl.formatMessage({ id: 'Supplier' }), path: '/suppliers' },
    ]
    return item
  }

  return (
    <Routes>
      <Route path='/' element={<Navigate to='home' />} />
      <Route path='/' element={<Supplier supplier={supplier} />}>
        <Route
          path='home'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {supplier.fullName}
              </PageTitle>
              <SupplierHome supplier={supplier} />
            </>
          }
        />
        <Route
          path='products'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {supplier.fullName}
              </PageTitle>
              <ListProduct supplier={supplier} />
            </>
          }
        />
        <Route
          path='reviews'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {supplier.fullName}
              </PageTitle>
              <ListReview supplier={supplier} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default SupplierPage
