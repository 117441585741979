import { useIntl } from 'react-intl'
import Button from '../componentsShared/Button'
import Modal from '../componentsShared/Modal'
import { toAbsoluteUrl } from '../../../helpers'
import { useState } from 'react'
import NumberBox from '../componentsShared/NumberBox'
import { DEFAULT_VALUE } from '../../../utilities/constant'

const ShippingUnitModal = ({ closeModal, shippingMethodSelected, shippingMethods, onChangeShippingMethod }) => {
  const intl = useIntl()
  const today = new Date()
  const [shippingMethod, setShippingMethod] = useState(shippingMethodSelected)

  const handleSave = () => {
    closeModal(false)
    onChangeShippingMethod(shippingMethod)
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Checkout.ChooseShippingUnit' })} modalSize='mw-650px' closeModal={closeModal}>
      <div className='shipping-unit'>
        <div className='gray-color text-uppercase'>
          {intl.formatMessage({ id: 'Checkout.TranspotChannelLink' })} {intl.formatMessage({ id: 'Green24h' })}
        </div>

        <div className='d-flex flex-column mb-3 mt-3 cursor-pointer'>
          {shippingMethods.map((d, index) => (
            <div
              key={d + index}
              className={`shipping-item d-flex align-items-center ${d.value === shippingMethod.value ? 'active' : ''} ${index === 0 ? '' : 'mt-2'}`}
              onClick={() => setShippingMethod(d)}
            >
              <div className='me-3'>
                <div className='shipping-type'>
                  {d.name} <span className='color-red'>({<NumberBox value={d.shippingAmount} className='' suffix={DEFAULT_VALUE.VND} />})</span>
                </div>
                <div className='shipping-time gray-color'>
                  <span>
                    {intl.formatMessage({ id: 'Checkout.GuaranteedReceiptGoods' })} {today.getUTCDate() + d.receivedDay} {intl.formatMessage({ id: 'Month' })}{' '}
                    {today.getMonth() + 1} - {today.getUTCDate() + d.receivedDay + 2} {intl.formatMessage({ id: 'Month' })} {today.getMonth() + 1}
                  </span>
                </div>
                <div className='shipping-voucher gray-color'>
                  {intl.formatMessage({ id: 'Checkout.ReceiveVoucher' })} {today.getUTCDate() + d.receivedDay + 2} {intl.formatMessage({ id: 'Month' })}{' '}
                  {today.getMonth() + 1} {today.getFullYear()}.
                </div>
              </div>
              {d.value === shippingMethod.value && (
                <div className='ml-auto'>
                  <img src={toAbsoluteUrl('../media/svg/icon/selected.svg')} alt='' className='stardust-icon stardust-icon-tick' />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4} />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} />
        </div>
      </div>
    </Modal>
  )
}

export default ShippingUnitModal
