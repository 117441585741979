import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class CategoryAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.CATEGORY)
    this.adapter = this.initAdapter(conf)
  }

  async getCategories() {
    return await this.adapter.getAsync('getAll')
  }

  async getById(payload) {
    return await this.adapter.getAsync('getById', { payload })
  }

  async getAll(payload) {
    return await this.adapter.getAsync('getAll/categoryId', { payload })
  }
}

export default CategoryAPI
