import _ from 'lodash'
import { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import { useIntl } from 'react-intl'
import { getCoupons } from '../../../redux/actions/eco/couponActions'
import { CouponStatusEnum, CouponTypeEnum, DiscountTypeEnum, getEnums } from '../../../utilities/enums'
import PageTitle from '../components/PageTitle'
import { toAbsoluteUrl } from '../../../helpers'
import NumberBox from '../componentsShared/NumberBox'
import { DEFAULT_VALUE, ROLE_NAME } from '../../../utilities/constant'
import { useSelector } from 'react-redux'

const MyOffers = () => {
  const intl = useIntl()
  const [coupons, setCoupons] = useState([])
  const [rowLength, setRowLength] = useState(0)
  const [discountTypes] = useState(() => getEnums(DiscountTypeEnum, intl))
  const user = useSelector((state) => state.auth.user)

  useEffect(() => {
    const fetchCoupon = async () => {
      const { result } = await getCoupons({
        pageIndex: -1,
        toDate: new Date(),
        couponStatus: CouponStatusEnum.InUse,
      })
      if (result) {
        let items = []
        if (user) {
          items =
            user.roleName === ROLE_NAME.AFFILIATE || user.roleName === ROLE_NAME.CUSTOMER
              ? result.items.filter((d) => d.couponType === CouponTypeEnum.System)
              : user.roleName === ROLE_NAME.SUPPLIER
              ? result.items.filter((d) => d.couponType === CouponTypeEnum.Supplier)
              : result.items
        }
        setCoupons(items)
        const row = items.length % 2 === 0 ? items.length / 2 : parseInt(items.length / 2 + 1)
        setRowLength(row)
      }
    }

    fetchCoupon()
  }, [user])

  const genRowBody = () => {
    let removeCoupons = _.cloneDeep(coupons)
    const rowBody = []
    for (let i = 0; i < rowLength; i++) {
      const sliceCoupons = removeCoupons.slice(0, 2)
      removeCoupons = removeCoupons.filter((d) => !sliceCoupons.some((s) => s.id === d.id))

      rowBody.push(
        <div className={`card pb-5`}>
          <div className={`row ${i === rowLength - 1 ? '' : 'mb-5'}`}>
            {sliceCoupons &&
              sliceCoupons.map((d) => (
                <div className='col-lg-6 col-md-12'>
                  <div className='shadow-sm card-body rounded d-flex flex-row align-items-center'>
                    <div className='text-primary'>
                      <SVG src={toAbsoluteUrl('/media/svg/icon/coupon.svg')} style={{ width: '24', height: '24' }} />
                    </div>

                    <div className='w-100 ms-3'>
                      <p className='fw-bold mb-1 lh-lg fs-2' style={{ color: 'rgb(66, 103, 178)' }}>
                        {d.couponName}
                      </p>
                      <h4 className='text-primary'>{discountTypes.find((e) => e.value === d.discountType)?.name} </h4>
                      <div className='shipping-voucher gray-color'>
                        {intl.formatMessage({ id: 'Checkout.Value' })}:{' '}
                        <NumberBox
                          value={d.discountValue}
                          className=''
                          suffix={d.discountType === DiscountTypeEnum.FixedPrice ? DEFAULT_VALUE.VND : DEFAULT_VALUE.PERCENT}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )
    }
    return rowBody
  }

  return (
    <>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'MyOffer' })}</h1>
            <PageTitle />
          </div>
        </div>
      </div>
      <div className='card rounded mt-10'>{rowLength > 0 && genRowBody()}</div>
    </>
  )
}

export default MyOffers
