import React from 'react'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import NumberBox from '../componentsShared/NumberBox'
import { toAbsoluteUrl } from '../../../helpers'
import RatingStar from '../components/RatingStar'
import { FormattedMessage } from 'react-intl'

const Supplier = ({ supplier }) => {
  return (
    <Link className='card mb-4' to={`/supplier/${supplier?.id}`}>
      <div className='d-flex flex-row justify-content-center p-4 border hover rounded'>
        <div className='me-4'>
          <div className='rounded-pill border d-inline-block position-relative'>
            <img width='70' height='70' src={toAbsoluteUrl('/media/jpg/shop.jpg')} loading='lazy' className='object-fit-contain rounded-pill' alt='' />
          </div>
        </div>
        <div className='me-4 border-end w-50'>
          <div className='fw-bold text-uppercase'>{supplier?.fullName}</div>
          <div className='row align-items-center'>
            <RatingStar rating={supplier?.rating} />
            <div className='text-muted my-1'>
              <SVG src={toAbsoluteUrl('/media/svg/icon/location.svg')} />
              {supplier?.businessAddress}
            </div>
          </div>
        </div>
        <div className='col me-4 pt-6'>
          <div className='row'>
            <div className='col-12'>
              <span className='text-primary fw-bold'>
                <NumberBox value={supplier?.quantityProduct} className='' />
              </span>
              <span className='text-muted'> <FormattedMessage id='Product' /></span>
            </div>
            <div className='col-12'>
              <span className='text-primary fw-bold'>
                <NumberBox value={supplier?.quantityProductSold ?? 0} className='' />
              </span>
              <span className='text-muted'> <FormattedMessage id='Sold' /></span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default Supplier
