import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class AffiliateApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.AFFILIATE)
    this.adapter = this.initAdapter(conf)
  }

  async getById(id) {
    return await this.adapter.getAsync(`getById?id=${id}`)
  }

  async getAffiliates(payload) {
    return await this.adapter.postAsync('getAffiliates', { payload })
  }

  async getByAffiliateCode(id, affiliateCode) {
    return await this.adapter.getAsync(`getByAffiliateCode?id=${id}&affiliateCode=${affiliateCode}`)
  }

  async saveAffiliate(payload) {
    return await this.adapter.postAsync('saveAffiliate', { payload })
  }

  async updateAffiliate(payload) {
    return await this.adapter.putAsync('updateAffiliate', { payload })
  }

  async getAccountBalance() {
    return await this.adapter.getAsync(`getAccountBalance`)
  }
}

export default AffiliateApi
