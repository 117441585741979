import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { InitLayout } from './components/layout'
import { Toastify } from './helpers/Toastify'

const App = () => {
  return (
    <Suspense fallback={<TopBarProgress />}>
      <Outlet />
      <InitLayout />
      <Toastify />
    </Suspense>
  )
}

export { App }
