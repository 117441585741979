import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { ScrollTopComponent, DrawerComponent, StickyComponent, ToggleComponent } from '../../assets/ts/components/index.ts'
import { Icon } from '../../helpers'

const ScrollTop = () => {
  const { pathname } = useLocation()
  const isFirstRun = useRef(true)

  const pluginsReinitialization = () => {
    setTimeout(() => {
      StickyComponent.reInitialization()
      setTimeout(() => {
        ToggleComponent.reinitialization()
        DrawerComponent.reinitialization()
      }, 70)
    }, 140)
  }

  const scrollTop = () => {
    ScrollTopComponent.goTop()
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
    } else {
      pluginsReinitialization()
    }

    setTimeout(() => {
      scrollTop()
    }, 0)
  }, [pathname])

  return (
    <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true'>
      <Icon iconName='arrow-up' />
    </div>
  )
}

export default ScrollTop
