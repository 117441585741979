import { AffiliateCustomerAddress as AffiliateCustomerAddressAPI } from '../../../services'

// Async actions

const getById = async (affiliateCustomerAddressId) => {
  return await AffiliateCustomerAddressAPI.getById(affiliateCustomerAddressId)
}

const getByAffiliateCustomerRelationId = async (affiliateCustomerAddressId) => {
  return await AffiliateCustomerAddressAPI.getByAffiliateCustomerRelationId(affiliateCustomerAddressId)
}

const saveAffiliateCustomerAddress = async (payload) => {
  return await AffiliateCustomerAddressAPI.saveAffiliateCustomerAddress(payload)
}

const updateAffiliateCustomerAddress = async (payload) => {
  return await AffiliateCustomerAddressAPI.updateAffiliateCustomerAddress(payload)
}

const updateIsDefaultCustomerAddress = async (payload) => {
  return await AffiliateCustomerAddressAPI.updateIsDefaultCustomerAddress(payload)
}

const deleteAffiliateCustomerAddress = async (affiliateCustomerAddressId) => {
  return await AffiliateCustomerAddressAPI.deleteAffiliateCustomerAddress(affiliateCustomerAddressId)
}

export { getById, getByAffiliateCustomerRelationId, saveAffiliateCustomerAddress, updateAffiliateCustomerAddress, updateIsDefaultCustomerAddress, deleteAffiliateCustomerAddress }
