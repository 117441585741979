import React, { useState } from 'react'

const Tooltip = ({ content }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const handleMouseEnter = () => {
    setTooltipVisible(true)
  }

  const handleMouseLeave = () => {
    setTooltipVisible(false)
  }

  return (
    <span className="tooltip-container pointer wrap-text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <i className="fa fa-question-circle ms-1"></i>
      {isTooltipVisible && <div className="tooltip-content">{content}</div>}
    </span>
  )
}

export default Tooltip
