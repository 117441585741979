import React, { useEffect, useState } from 'react'
import { Route, Routes, useParams, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../PageData'
import Purchases from './Purchases'
import { purchaseRoutes, PurchasedProducts } from '.'
import { getOrders } from '../../../redux/actions/eco/orderActions'
import { Auth } from '../../auth/Auth'
import { OrderStatusEnum } from '../../../utilities/enums'

const PurchasePage = () => {
  const intl = useIntl()
  const { type } = useParams()
  const [orders, setOrders] = useState([])

  useEffect(() => {
    const fetchOrders = async () => {
      const { result } = await getOrders({ userId: Auth.getUserId(), pageSize: -1 })
      if (result && result.items) {
        const prods = result.items.filter((d) => d.orderStatus.toString() === type || type === OrderStatusEnum.All.toString())
        setOrders(prods)
      }
    }
    fetchOrders()
  }, [type])

  const route = purchaseRoutes.find((d) => d.type.toString() === type)
  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route path='/' element={<Purchases />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {intl.formatMessage({ id: route.title })}
              </PageTitle>
              <PurchasedProducts orders={orders} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default PurchasePage
