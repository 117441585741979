import React from 'react'

const Confirm = ({ onConfirm, onCancel, message, title, btnCancelText, btnConfirmText }) => {
  const handleConfirm = () => {
    onConfirm()
  }

  const handleCancel = () => {
    onCancel()
  }

  return (
    <>
      <div className="modal fade show d-block" id="kt_modal" role="dialog" tabIndex={-1} aria-modal="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0">
              <div className="text-center">
                <h5 className="fw-bolder fs-1 mb-5">{title}</h5>
                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                <div className="mb-9">{message}</div>
                <div className="d-flex flex-center flex-wrap">
                  <a onClick={handleCancel} className="btn btn-outline btn-outline-danger btn-active-danger m-2 btn-sm">
                    <i className="fa-solid fa-times"></i>
                    {btnCancelText}
                  </a>
                  <a onClick={handleConfirm} className="btn btn-danger m-2 btn-sm">
                    <i className="fa-solid fa-check"></i>
                    {btnConfirmText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  )
}

export default Confirm
