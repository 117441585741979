import BaseApi from '../base/baseApi'
import Config from '../base/config'

class FileAttachmentAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getFileAttachmentConfig()
    this.adapter = this.initAdapter(conf)
  }

  async getFiles(payload) {
    return await this.adapter.getAsync('getFiles', { payload })
  }
}

export default FileAttachmentAPI
