import { v4 as uuidv4 } from 'uuid'

const DATETIME_FORMAT = {
  ddMMyyyy: 'dd/MM/yyyy',
}

const DEFAULT_VALUE = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  DISPLAY_SECOND: 5,
  THOUDSAND_MILLISECOND: 1000,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  REGEX_EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  GUID_EMPTY: '00000000-0000-0000-0000-000000000000',
  GUID_RANDOM: uuidv4(),
  VND: '₫',
  PERCENT: '%',
  SLASH: '/',
}

const GUID_NEWID = () => uuidv4()

export const PURCHASE_TYPE = {
  All: '1',
  WaitForConfirm: '2',
  PreparingGoods: '3',
  WaitForDelivery: '4',
  Complete: '5',
  Cancel: '6',
  ReturnRefund: '7',
}

export const BANNER_POSITION_ENUM = {
  TOP: 1,
  MIDDLE: 2,
  BOTTOM: 3,
}

export const TABLE_NAME = {
  PRODUCT: 'Product',
}

export const FILE_TYPE = {
  PRODUCT_MAIN_IMAGE: 'ProductMainImage',
  PRODUCT_SUB_IMAGE: 'ProductSubImage',
}

export const ROLE_NAME = {
  ADMIN: 'Admin',
  SUPPLIER: 'Supplier',
  AFFILIATE: 'Affiliate',
  CUSTOMER: 'Customer',
}

export { DEFAULT_VALUE, DATETIME_FORMAT, GUID_NEWID }
