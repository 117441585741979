import { Supplier as SupplierAPI } from '../../../services'

// Async actions

const getSupplierById = async (supplierId) => {
  return await SupplierAPI.getSupplierById(supplierId)
}

const getProductSupplierBySupplierId = async (supplierId) => {
  return await SupplierAPI.getProductSupplierBySupplierId(supplierId)
}

const getSameProductsBySupplierId = async (supplierId) => {
  return await SupplierAPI.getSameProductsBySupplierId(supplierId)
}

const getSuppliers = async (payload) => {
  return await SupplierAPI.getSuppliers(payload)
}

const searchSuppliers = async (payload) => {
  return await SupplierAPI.searchSuppliers(payload)
}

export { getSupplierById, getProductSupplierBySupplierId, getSameProductsBySupplierId, getSuppliers, searchSuppliers }
