import { useIntl, FormattedMessage } from 'react-intl'
import useCountdownTimer from '../../../helpers/useCountdownTimer'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import NumberBox from '../componentsShared/NumberBox'

const PromotionCountdown = ({ promotion }) => {
  const intl = useIntl()
  const countdown = useCountdownTimer(promotion.toDate)

  return (
    <>
      <div className='col-xl-3 col-lg-4 col-md-6'>
        <div className='product-cart-wrap style-2'>
          <div className='product-img-action-wrap'>
            <div className='product-img'>
              <a href='/shop-product-right'>
                <img src={DEFAULT_VALUE.BASE_URL + promotion.backgroundImageUrl} alt='' />
              </a>
            </div>
          </div>
          <div className='product-content-wrap'>
            <div className='deals-countdown-wrap'>
              <div className='deals-countdown' data-countdown='2025/03/25 00:00:00'>
                <span className='countdown-section'>
                  <span className='countdown-amount hover-up'>{countdown.days}</span>
                  <span className='countdown-period'> days </span>
                </span>
                <span className='countdown-section'>
                  <span className='countdown-amount hover-up'>{countdown.hours}</span>
                  <span className='countdown-period'> hours </span>
                </span>
                <span className='countdown-section'>
                  <span className='countdown-amount hover-up'>{countdown.minutes}</span>
                  <span className='countdown-period'> mins </span>
                </span>
                <span className='countdown-section'>
                  <span className='countdown-amount hover-up'>{countdown.seconds}</span>
                  <span className='countdown-period'> sec </span>
                </span>
              </div>
            </div>
            <div className='deals-content'>
              <h2>
                <a>{promotion.name}</a>
              </h2>
              <div>
                <span className='font-small text-muted'>
                  {intl.formatMessage({ id: 'By' })} <a className='supplier-name'>{promotion.supplierName}</a>
                </span>
              </div>
              <div className='product-card-bottom'>
                <div className='product-price'>
                  <span>{intl.formatMessage({ id: 'Discount' })}</span>
                  <span className='old-price'>
                    <NumberBox value={promotion.discountValue} suffix={DEFAULT_VALUE.VND} className='' />
                  </span>
                </div>
                <div className='add-cart'>
                  <a className='add'>
                    <i className='fa-solid fa-solid fa-cart-shopping pe-2' />
                    {intl.formatMessage({ id: 'Add' })}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Promotions = ({ promotions }) => {
  return (
    <section id='home-promotion'>
      <div className='mb-5 mb-xl-10'>
        <div className='section-title d-flex align-items-center justify-content-between'>
          <h3>
            <FormattedMessage id='Promotion' />
          </h3>
          <div className='fw-bold text-primary cursor-pointer'>
            <FormattedMessage id='ViewAll' />
            <i className='fa fa-angle-right ms-2 text-primary' aria-hidden='true'></i>
          </div>
        </div>

        <div className='row'>
          {promotions?.map((d, index) => {
            return <PromotionCountdown promotion={d} key={index} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Promotions
