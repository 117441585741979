import { useEffect } from 'react'
import { Icon } from '../../../helpers'

const Modal = (props) => {
  const { title, closeModal, children, modalSize } = props
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleCloseModal = () => {
    closeModal(false)
  }

  return (
    <>
      <div className="modal fade show d-block" id="kt_modal" role="dialog" tabIndex={-1} aria-modal="true">
        <div className={`modal-dialog modal-dialog-centered ${modalSize ?? 'mw-950px'}`}>
          <div className="modal-content">
            <div className="modal-header p-4">
              <h2 className="fw-bolder">{title}</h2>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-customers-modal-action="close"
                onClick={() => handleCloseModal()}
                style={{ cursor: 'pointer' }}
              >
                <Icon iconName="cross" className="fs-1" />
              </div>
            </div>

            <div className="modal-body m-1 p-4">{children}</div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  )
}

export default Modal
