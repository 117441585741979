import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { App } from '../App'
import { AuthPage } from '../components/auth'
import NotFound from '../components/pages/NotFound'
import { Auth } from '../components/auth/Auth'
import { AffRoutes } from './AffRoutes'
import Logout from '../components/auth/components/Logout'

const AppRoutes = () => {
  const user = useSelector((state) => state.auth.user)
  const returnUrl = useSelector((state) => state.auth.returnUrl)
  const isAuthenticated = !!Auth.getToken()

  useEffect(() => {
    if (isAuthenticated && !_.isEmpty(returnUrl) && !_.isEmpty(user)) {
      window.location.href = returnUrl
    }
  }, [user, returnUrl])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='/notfound' element={<NotFound />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/*' element={<AffRoutes />} />
          {_.isEmpty(user) && <Route path='auth/*' element={<AuthPage />} />}
          <Route path='*' element={<Navigate to='/home' />} />
          <Route path='/' element={<Navigate to='/home' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
