import { useIntl, FormattedMessage } from 'react-intl'
import Button from '../componentsShared/Button'
import Modal from '../componentsShared/Modal'
import { useState } from 'react'
import { DiscountTypeEnum, CouponTypeEnum } from '../../../utilities/enums'
import NumberBox from '../componentsShared/NumberBox'
import { DEFAULT_VALUE } from '../../../utilities/constant'

const SystemVoucherModal = ({ closeModal, voucherSelected, coupons, onChangeVoucher, couponType }) => {
  const intl = useIntl()
  const [voucher, setVoucher] = useState(voucherSelected)
  const title =
    couponType === CouponTypeEnum.Ship ? 'Coupon.FreeShip' : couponType === CouponTypeEnum.Supplier ? 'Checkout.ShopVoucher' : 'Checkout.GreenVoucher'

  const handleSave = () => {
    onChangeVoucher(voucher)
    closeModal(false)
  }

  const calculatePercentage = (usedNumber, maxUsesNumber) => {
    if (maxUsesNumber <= 0) return 0
    return ((usedNumber ?? 0) / maxUsesNumber) * 100
  }

  return (
    <Modal title={intl.formatMessage({ id: title })} modalSize='mw-650px' closeModal={closeModal}>
      <div className='shipping-unit'>
        <div className='gray-color text-uppercase'>{coupons.length === 0 ? intl.formatMessage({ id: 'NoResult' }) : ''}</div>

        <div className='my-3 cursor-pointer'>
          {coupons?.length > 0 &&
            coupons.map((d, index) => (
              <div
                key={d + index}
                className={`shipping-item ${voucher?.id === d.id ? 'active' : ''} ${index === 0 ? '' : 'mt-2'}`}
                onClick={() => setVoucher(d)}
              >
                <div>
                  <div className='shipping-type'>
                    {d.discountType === DiscountTypeEnum.FixedPrice ? (
                      <FormattedMessage
                        id='Coupon.MaxDiscountAmount'
                        values={{ maxDiscountAmount: <NumberBox value={d.maxDiscountAmount} className='' suffix={DEFAULT_VALUE.VND} /> }}
                      />
                    ) : d.discountType === DiscountTypeEnum.Percentage ? (
                      <>
                        <FormattedMessage id='Coupon.DiscountPercent' values={{ discountValue: d.discountValue }} />{' '}
                        <FormattedMessage
                          id='Coupon.MaxDiscountAmount'
                          values={{ maxDiscountAmount: <NumberBox value={d.maxDiscountAmount} className='' suffix={DEFAULT_VALUE.VND} /> }}
                        />
                      </>
                    ) : (
                      <>
                        <FormattedMessage
                          id='Coupon.MaxDiscountAmount'
                          values={{ maxDiscountAmount: <NumberBox value={d.maxDiscountAmount} className='' suffix={DEFAULT_VALUE.VND} /> }}
                        />
                      </>
                    )}
                    <br />
                    <FormattedMessage
                      id='Coupon.MinAmount'
                      values={{ minAmount: <NumberBox value={d.minAmount ?? 0} className='' suffix={DEFAULT_VALUE.VND} /> }}
                    />
                  </div>
                  <div className='shipping-time gray-color'>
                    {d.maxUsesNumber && (
                      <FormattedMessage id='Coupon.UsedNumber' values={{ usedNumber: calculatePercentage(d.couponUsedNumber, d.maxUsesNumber) }} />
                    )}{' '}
                    {d.toDate && <FormattedMessage id='Coupon.ToDate' values={{ toDate: d.toDateStr }} />}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4} />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} />
        </div>
      </div>
    </Modal>
  )
}

export default SystemVoucherModal
