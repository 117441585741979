import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { showToast, TOAST_TYPES } from '../../../helpers/Toastify'
import { saveAffiliateCustomerAddress, updateAffiliateCustomerAddress } from '../../../redux/actions/aff/affiliateCustomerAddressAction'
import { getDistrictsByProvinceId, getProvinces, getWardsByDistrictId } from '../../../redux/actions/gnr/provinceActions'
import { validationAllComponents } from '../../../utilities/shareFunction'
import Button from '../componentsShared/Button'
import { ControlType, FormControl } from '../componentsShared/FormControl'
import Modal from '../componentsShared/Modal'

const CreateAddressModal = ({ editAddress, addresses, closeModal, fetchAddresses }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  const [provinceId, setProvinceId] = useState(editAddress?.provinceId?.toUpperCase() ?? null)
  const [districtId, setDistrictId] = useState(editAddress?.districtId?.toUpperCase() ?? null)
  const [wardId, setWardId] = useState(editAddress?.wardId?.toUpperCase() ?? null)
  const [address, setAddress] = useState(editAddress?.address ?? null)
  const [isDefault, setIsDefault] = useState(editAddress?.isDefault ?? false)

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvinces()
      if (result) {
        setProvinces(result)
      }
    }
    fetchProvinces()
  }, [provinceId])

  useEffect(() => {
    if (provinceId) {
      const fetchDistricts = async () => {
        const { result } = await getDistrictsByProvinceId({ provinceId, pageIndex: null, pageSize: null })
        if (result?.items) {
          setDistricts(result.items)
          if (districtId) {
            const fetchWards = async () => {
              const { result } = await getWardsByDistrictId({ districtId, pageIndex: null, pageSize: null })
              if (result?.items) {
                setWards(result.items)
              }
            }
            fetchWards()
          }
        }
      }
      fetchDistricts()
    }
  }, [editAddress, provinceId, districtId])

  const handleSave = async (e) => {
    e.preventDefault()
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const province = _.filter(provinces, { value: provinceId }) ? _.map(_.filter(provinces, { value: provinceId }), 'name')[0] : ''
    const district = _.filter(districts, { value: districtId }) ? _.map(_.filter(districts, { value: districtId }), 'name')[0] : ''
    const ward = _.filter(wards, { value: wardId }) ? _.map(_.filter(wards, { value: wardId }), 'name')[0] : ''

    const affiliateCustomerAddressData = {
      id: editAddress?.id,
      affiliateCustomerRelationId: editAddress.affiliateCustomerRelationId,
      province,
      provinceId,
      district,
      districtId,
      ward,
      wardId,
      address,
      isDefault,
    }

    const { error } = editAddress.isAdded
      ? await saveAffiliateCustomerAddress(affiliateCustomerAddressData)
      : await updateAffiliateCustomerAddress(affiliateCustomerAddressData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editAddress.isAdded = false
      fetchAddresses(editAddress.affiliateCustomerRelationId)
      closeModal(false)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'AddNewAddress' })} closeModal={closeModal} modalSize='mw-650px'>
      <form className='form'>
        <div
          className='d-flex flex-column'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.provinceId = ref)}
                tabIndex={3}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Province' })}
                options={provinces}
                value={provinceId}
                isRequired={true}
                onChange={(e) => setProvinceId(e.value)}
              />
            </div>
            <div className='col-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.districtId = ref)}
                tabIndex={4}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'District' })}
                options={districts}
                value={districtId}
                isRequired={true}
                onChange={(e) => setDistrictId(e.value)}
              />
            </div>
            <div className='col-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.wardId = ref)}
                tabIndex={5}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Ward' })}
                options={wards}
                value={wardId}
                isRequired={true}
                onChange={(e) => setWardId(e.value)}
              />
            </div>
            <div className='col-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.address = ref)}
                tabIndex={6}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'HouseNumber' })}
                value={address}
                isRequired={true}
                rows={2}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.isDefault = ref)}
                tabIndex={6}
                controlType={ControlType.Checkbox}
                label={intl.formatMessage({ id: 'Default' })}
                value={isDefault}
                onChange={(e) => setIsDefault(e.target.checked)}
                isFromSwitch={true}
              />
            </div>
          </div>
        </div>

        <div className='text-center pt-5'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={5}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateAddressModal
