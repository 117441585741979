import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import '../../../assets/sass/order.scss'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import { decreaseCart, increaseCart, removeAllCart, removeFromCart } from '../../../redux/actions/eco/shoppingCartActions'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import PageTitle from '../components/PageTitle'
import RatingStar from '../components/RatingStar'
import NumberBox from '../componentsShared/NumberBox'

const Orders = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cartDetailId } = useParams()
  const cart = useSelector((state) => state.shoppingCart)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [rows, setRows] = useState([])
  const [totalAmountSelected, setTotalAmountSelected] = useState(0)
  const [selectedCarts, setSelectedCarts] = useState([])

  useEffect(() => {
    const checkedRows = rows.filter((item) => item.isChecked)
    const totalAmount = checkedRows.reduce((total, { unitPrice, quantity }) => total + unitPrice * quantity, 0)
    setTotalAmountSelected(totalAmount)
    setSelectedCarts(checkedRows)

    const allChecked = rows.every((row) => row.isChecked)
    setIsAllChecked(allChecked)
  }, [rows])

  useEffect(() => {
    setRows(cart.shoppingCartDetails?.map((row) => ({ ...row, isChecked: row.id === cartDetailId || selectedCarts.some((d) => d.id === row.id) })))
  }, [cart])

  const renderPropertyName = (propertyName) => {
    if (propertyName) {
      const elements = propertyName.split(';').filter((element) => element.trim() !== '')
      return (
        <>
          {elements.map((element, index) => (
            <div key={index}>{element.trim()}</div>
          ))}
        </>
      )
    }
  }

  const handleCheckAll = (e) => {
    const checked = e.target.checked
    setIsAllChecked(checked)
    setRows(rows.map((row) => ({ ...row, isChecked: checked })))
  }

  const handleCheckRow = (index) => (e) => {
    const checked = e.target.checked
    const updatedRows = [...rows]
    updatedRows[index].isChecked = checked

    const allChecked = updatedRows.every((row) => row.isChecked)
    setIsAllChecked(allChecked)

    setRows(updatedRows)
  }

  const handleIncrease = (index, id) => {
    const updatedRows = [...rows]
    const newQuantity = updatedRows[index].quantity + 1
    updatedRows[index].quantity = newQuantity
    setRows(updatedRows)
    dispatch(increaseCart(id))
  }

  const handleDecrease = (index, id) => {
    const updatedRows = [...rows]
    const newQuantity = updatedRows[index].quantity - 1
    if (newQuantity < 1) return
    updatedRows[index].quantity = newQuantity
    setRows(updatedRows)
    dispatch(decreaseCart(id))
  }

  const handleCheckout = () => {
    if (selectedCarts.length === 0) {
      showToast(`${intl.formatMessage({ id: 'Order.ChooseProduct' })}`, TOAST_TYPES.WARNING)
    } else {
      navigate('/checkout', { state: { selectedCarts } })
    }
  }

  const renderBody = (item, index) => {
    return (
      <tr key={item.id}>
        <td className='custome-checkbox ps-10'>
          <input className='form-check-input' type='checkbox' id={`order-${item.id}`} checked={item.isChecked} onChange={handleCheckRow(index)} />
          <label className='form-check-label' htmlFor={`order-${item.id}`} />
        </td>
        <td className='image product-thumbnail'>
          <img src={DEFAULT_VALUE.BASE_URL + item.productImage} alt='' />
        </td>
        <td className='product-description product-name'>
          <h6 className='mb-2'>
            <a className='product-name mb-10 text-heading'>{item.productName}</a>
          </h6>
          <div className='mb-2'>{renderPropertyName(item.productPropertyName)}</div>
          <RatingStar rating={5} />
        </td>
        <td className='price' data-title='Price'>
          <h2 className='text-body'>
            <NumberBox value={item.unitPrice} className='' suffix={DEFAULT_VALUE.VND} />
          </h2>
        </td>
        <td className='text-center detail-info' data-title='Stock'>
          <div className='detail-extralink mr-15'>
            <div className='detail-qty'>
              <a className='qty-down' onClick={() => handleDecrease(index, item.id)}>
                <i className='fa-solid fa-caret-down' />
              </a>
              <input type='number' name='quantity' className='qty-val' value={item.quantity} min={1} />
              <a className='qty-up' onClick={() => handleIncrease(index, item.id)}>
                <i className='fa-solid fa-caret-up' />
              </a>
            </div>
          </div>
        </td>
        <td className='price' data-title='Price'>
          <h2 className='text-brand'>
            <NumberBox value={item.unitPrice * item.quantity} className='' suffix={DEFAULT_VALUE.VND} />
          </h2>
        </td>
        <td className='action text-center' data-title='Remove'>
          <a className='text-body' onClick={() => dispatch(removeFromCart(item.id))}>
            <i className='fa-solid fa-trash-can text-brand' />
          </a>
        </td>
      </tr>
    )
  }

  return (
    <>
      <PageTitle />
      <div id='order' className='d-flex flex-column'>
        <div className='d-flex flex-column'>
          <div className='mb-10 mt-10'>
            <h1 className='heading-2 mb-5'>{intl.formatMessage({ id: 'ShoppingCart' })}</h1>
            <div className='d-flex justify-content-between'>
              <h2 className='text-body'>
                {intl.formatMessage({ id: 'Order.YouHave' })} <span className='text-brand'>{cart.count}</span>{' '}
                {intl.formatMessage({ id: 'Order.ProductInCart' })}
              </h2>

              <Link className='btn' to='/'>
                <i className='fa-solid fa-solid fa-cart-shopping trash-bottom color-white' />
                {intl.formatMessage({ id: 'Checkout.ContinueShopping' })}
              </Link>

              <a className='btn' onClick={() => dispatch(removeAllCart())}>
                <i className='fa-solid fa-trash-can me-2 trash-bottom color-white' />
                {intl.formatMessage({ id: 'Order.RemoveCart' })}
              </a>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column'>
          <div className='table-responsive shopping-summery'>
            <table className='table table-wishlist'>
              <thead className='table-header'>
                <tr className='main-heading'>
                  <th className='custome-checkbox start ps-10'>
                    <input className='form-check-input' type='checkbox' id='ckbAll' checked={isAllChecked} onChange={handleCheckAll} />
                    <label className='form-check-label' htmlFor='ckbAll' />
                  </th>
                  <th scope='col' colSpan={2}>
                    {intl.formatMessage({ id: 'Product' })}
                  </th>
                  <th scope='col'>{intl.formatMessage({ id: 'UnitPrice' })}</th>
                  <th scope='col' className='ps-15'>
                    {intl.formatMessage({ id: 'Quantity' })}
                  </th>
                  <th scope='col'>{intl.formatMessage({ id: 'TotalAmount' })}</th>
                  <th scope='col' className='end'>
                    {intl.formatMessage({ id: 'Action' })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows &&
                  rows.map((item, index) => {
                    return renderBody(item, index)
                  })}
              </tbody>
            </table>
          </div>

          <table className='table table-wishlist mt-5'>
            <thead className='table-header'>
              <tr className='main-heading'>
                <th className='text-end'>
                  <h2>
                    {intl.formatMessage({ id: 'TotalAmount' })} (<span className='text-brand'>{selectedCarts.length}</span>{' '}
                    <span className='text-lowercase'>{intl.formatMessage({ id: 'Products' })}</span>):
                  </h2>
                </th>
                <th className='text-end'>
                  <h2 className='color-orange'>
                    <NumberBox value={totalAmountSelected} className='' suffix={DEFAULT_VALUE.VND} />
                  </h2>
                </th>
                <th className='text-end'>
                  <a className='btn' onClick={() => handleCheckout()}>
                    {intl.formatMessage({ id: 'Checkout' })}
                    <i className='ps-2 fa-solid fa-right-from-bracket color-white' />
                  </a>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </>
  )
}

export default Orders
