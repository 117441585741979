import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DATETIME_FORMAT } from '../../../utilities/constant'

const DatePickerComponent = forwardRef((props, ref) => {
  const { selectedDate, onChange, tabIndex, onBlur, isValid, errorMessage } = props
  return (
    <>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        onBlur={onBlur}
        timeIntervals={15}
        dateFormat={DATETIME_FORMAT.ddMMyyyy}
        className="form-control mb-3 mb-lg-0"
        tabIndex={tabIndex ?? 0}
        data-isvalid={isValid}
        ref={ref}
      />
      {isValid === false && (
        <p className="mt-0 mb-0" style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default DatePickerComponent
