import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import '../../../assets/sass/checkout.scss'
import { getAffiliateCustomerRelations } from '../../../redux/actions/aff/affiliateCustomerRelationAction'
import { getCoupons } from '../../../redux/actions/eco/couponActions'
import { CouponStatusEnum, CouponTypeEnum, DiscountTypeEnum, PaymentMethodEnum, PaymentStatusEnum, getEnums } from '../../../utilities/enums'
import { Auth } from '../../auth/Auth'
import PageTitle from '../components/PageTitle'
import CheckoutBody from './CheckoutBody'
import CheckoutCoupon from './CheckoutCoupon'
import CheckoutHeader from './CheckoutHeader'
import CheckoutPayment from './CheckoutPayment'
import CheckoutTotal from './CheckoutTotal'
import { toAbsoluteUrl } from '../../../helpers'

const Checkout = () => {
  const intl = useIntl()
  const location = useLocation()
  const cart = useSelector((state) => state.shoppingCart)

  const [paymentMethods] = useState(getEnums(PaymentMethodEnum, intl))
  const [customerRelations, setCustomerRelations] = useState([])
  const [suppliers, setSuppliers] = useState([])

  const [selectedCarts] = useState(location.state?.selectedCarts || cart.shoppingCartDetails)
  const [selectedAddress, setSelectedAddress] = useState()
  const [coupons, setCoupons] = useState([])
  const [systemVoucherSelected, setSystemVoucherSelected] = useState(null)
  const [shipVoucherSelected, setShipVoucherSelected] = useState(null)
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(paymentMethods[0])
  const [customerRelationSelected, setCustomerRelationSelected] = useState(null)

  useEffect(() => {
    const uniqueSuppliers = Object.values(
      selectedCarts.reduce((acc, cart) => {
        if (!acc[cart.supplierId]) {
          const totalAmount = selectedCarts
            .filter((x) => x.supplierId === cart.supplierId)
            .reduce((total, { unitPrice, quantity }) => total + unitPrice * quantity, 0)

          acc[cart.supplierId] = {
            supplierId: cart.supplierId,
            supplierName: cart.supplierName,
            message: null,
            expectedDeliveryDate: null,
            shippingMethod: null,
            supplierCouponId: null,
            supplierCouponAmount: null,
            shippingAmount: null,
            totalAmount: totalAmount,
          }
        }
        return acc
      }, {})
    )

    fetchDataInit()
    setSuppliers(uniqueSuppliers)
  }, [])

  const fetchDataInit = async () => {
    const { result } = await getCoupons({
      pageIndex: -1,
      toDate: new Date(),
      couponStatus: CouponStatusEnum.InUse,
    })
    if (result) {
      setCoupons(result.items)
    }

    const { result: rs } = await getAffiliateCustomerRelations({ pageIndex: -1, userId: Auth.getUserId() })
    if (rs && rs.items?.length > 0) {
      setCustomerRelations(rs.items)
    }
  }

  const handleChangeSupplier = (updatedSupplier) => {
    const updateSuppliers = suppliers.map((supplier) => {
      if (supplier.supplierId === updatedSupplier.supplierId) {
        return { ...supplier, ...updatedSupplier }
      }
      return supplier
    })

    setSuppliers(updateSuppliers)
  }

  const prepareOrderModel = () => {
    const orders = suppliers?.map((supplier) => {
      const products = selectedCarts.filter((x) => x.supplierId === supplier.supplierId)
      const sysCouponAmount = systemVoucherSelected
        ? systemVoucherSelected.discountType === DiscountTypeEnum.FixedPrice
          ? systemVoucherSelected.discountValue
          : Math.min((supplier.totalAmount * systemVoucherSelected.discountValue) / 100, systemVoucherSelected.maxDiscountAmount)
        : 0

      return {
        ...supplier,
        appCouponAmount: sysCouponAmount,
        shippingCouponAmount: shipVoucherSelected?.discountValue ?? 0,
        paymentAmount: 0,
        deliveryAddress: selectedAddress?.fullAddress,
        paymentMethod: paymentMethodSelected?.value,
        paymentStatus: PaymentStatusEnum.Unpaid,
        appCouponId: systemVoucherSelected?.id,
        shippingCouponId: shipVoucherSelected?.id,
        affiliateCustomerRelationId: customerRelationSelected?.id,
        orderDetails: products?.map((product) => ({
          id: product.id,
          productId: product.productId,
          quantity: product.quantity,
          basePrice: product.unitPrice,
          discountAmount: product.discountAmount,
          productPropertyName: product.productPropertyName,
        })),
      }
    })
    return orders
  }

  const totalAmount = suppliers.reduce((acc, current) => acc + current.totalAmount, 0)

  return (
    <div className='checkout'>
      <PageTitle />
      <div className='checkout-main mt-4'>
        <CheckoutHeader
          customerRelations={customerRelations}
          customerRelationSelected={customerRelationSelected}
          onChangeCustomerRelation={setCustomerRelationSelected}
          selectedAddress={selectedAddress}
          onChangeAddress={setSelectedAddress}
        />

        {suppliers?.map((d, index) => (
          <div className='checkout-suppliers' key={d.supplierId + index}>
            <div className='checkout-table-header'>
              <div className='checkout-table-column'>
                <div className='checkout-table-text-left checkout-flex-4'>
                  <div className='checkout-column-product d-flex align-items-center gap-2'>
                    <h2 className='checkout-supplier-name'>{d.supplierName}</h2>

                    <a className='purchase-supplier-visit' href={`/supplier/${d.supplierId}`}>
                      <div className='stardust-button'>
                        <img src={toAbsoluteUrl('../media/svg/icon/shop.svg')} alt='' className='checkout-svg-icon fs-3' />
                        <span>
                          <FormattedMessage id='VisitShop' />
                        </span>
                      </div>
                    </a>
                    {/* <div className='supplier-icon'>
                      <img src={toAbsoluteUrl('../media/svg/icon/favorite.svg')} alt='' />
                    </div> */}
                  </div>
                </div>
                <div className='checkout-table-text-left checkout-flex-2'></div>
                <div className='checkout-table-text-left'>{intl.formatMessage({ id: 'UnitPrice' })}</div>
                <div className='checkout-table-text-left'>{intl.formatMessage({ id: 'Quantity' })}</div>
                <div className='checkout-table-text-left checkout-flex-2'>{intl.formatMessage({ id: 'TotalAmount' })}</div>
              </div>
            </div>
            <div className='checkout-table-body'>
              <CheckoutBody
                key={d.supplierId}
                productOrders={selectedCarts.filter((e) => e.supplierId === d.supplierId)}
                supplier={d}
                onChangeSupplier={handleChangeSupplier}
                coupons={coupons.filter((d) => d.couponType === CouponTypeEnum.Supplier)}
              />
            </div>
          </div>
        ))}
        <div className='d-flex row-cols-2'>
          <CheckoutCoupon
            coupons={coupons.filter((d) => (d.minAmount == null || d.minAmount <= totalAmount) && d.couponType === CouponTypeEnum.Ship)}
            voucherSelected={shipVoucherSelected}
            onChangeVoucher={setShipVoucherSelected}
            couponType={CouponTypeEnum.Ship}
          />

          <CheckoutCoupon
            coupons={coupons.filter((d) => (d.minAmount == null || d.minAmount <= totalAmount) && d.couponType === CouponTypeEnum.System)}
            voucherSelected={systemVoucherSelected}
            onChangeVoucher={setSystemVoucherSelected}
            couponType={CouponTypeEnum.System}
          />
        </div>
        <div className='checkout-payments'>
          <CheckoutPayment paymentMethods={paymentMethods} paymentMethodSelected={paymentMethodSelected} onChangePaymentMethod={setPaymentMethodSelected} />
          <CheckoutTotal orders={prepareOrderModel()} />
        </div>
      </div>
    </div>
  )
}

export default Checkout
