import React, { useState, useEffect } from 'react'
import { Route, Routes, useParams, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { ProductDetail } from '../productDetail'
import { PageTitle } from '../PageData'
import { getById } from '../../../redux/actions/eco/productActions'

const ProductDetailPage = () => {
  const intl = useIntl()
  const { productId } = useParams()
  const [product, setProduct] = useState(null)

  useEffect(() => {
    fetchProduct()
  }, [productId])

  const fetchProduct = async () => {
    const { result } = await getById(productId)
    if (result) {
      setProduct(result)
    }
  }

  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    const category = {
      categoryId: product?.productCategory?.categoryId,
      categoryName: product?.productCategory?.categoryName,
    }

    const subCategory = {
      subCategoryId: product?.productCategory?.subCategoryId,
      subCategoryName: product?.productCategory?.subCategoryName,
    }

    if (!_.isEmpty(category)) {
      item.push({ title: category.categoryName, path: `/categories/${category.categoryId}` })
    }
    if (!_.isEmpty(subCategory)) {
      item.push({ title: subCategory.subCategoryName, path: `/categories/${category.categoryId}` })
    }
    return item
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {product?.productName}
            </PageTitle>
            <ProductDetail />
          </>
        }
      />
    </Routes>
  )
}

export default ProductDetailPage
