import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import _ from 'lodash'
import { bindValidationMessages } from '../../../utilities/validationMessage'
import { formatString, isValidEmail } from '../../../utilities/shareFunction'
import Input from './Input'
import Label from './Label'
import Select from './Select'
import TextArea from './TextArea'
import Checkbox from './Checkbox'
import DatetimePicker from './DatetimePicker'
import DatePicker from './DatePicker'
import RadioButton from './RadioButton'
import NumberBox from './NumberBox'

export const ControlType = {
  Input: 'Input',
  Select: 'Select',
  TextArea: 'TextArea',
  Checkbox: 'Checkbox',
  DatetimePicker: 'DatetimePicker',
  DatePicker: 'DatePicker',
  RadioButton: 'RadioButton',
  NumberBox: 'NumberBox',
}

const FormControl = forwardRef((props, ref) => {
  const { label, controlType, onChange, readOnly, value, tabIndex, validator, className, type, placeholder, contentTooltip, isMultiple, isHideLabel } = props
  const { isRequired, isEmail, isPassword } = props
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const validationMessage = bindValidationMessages()
  const controlRef = useRef(null)

  // Ref sẽ được truyền từ ParentComponent và được sử dụng để truy cập hàm validateData từ bên ngoài.
  useImperativeHandle(ref, () => ({
    validateData,
    clearValue: () => {
      if (controlRef.current.clearValue) controlRef.current.clearValue()
    },
  }))

  const validateData = async () => {
    let hasValue = value !== null && value !== undefined && value !== ''
    if (controlType !== ControlType.DatetimePicker && controlType !== ControlType.DatePicker) {
      hasValue = hasValue && value.toString().length > 0
    }
    let error = false
    let message = []

    //Check required
    if (isRequired && !hasValue) {
      error = true
      message = [...message, formatString(validationMessage.required, label)]
    } else if (hasValue) {
      //Check email
      if (isEmail) {
        if (!isValidEmail(value)) {
          error = true
          message = [...message, formatString(validationMessage.invalid, label)]
        }
      }
      //check other
      if (_.isFunction(validator)) {
        const result = await validator()
        if (result?.error) {
          error = true
          message = [...message, formatString(result?.message, label)]
        }
      }
    }
    setIsValid(!error)
    setErrorMessage(message.join('\n'))
    return !error
  }

  let controlProps = {
    tabIndex,
    value,
    isValid,
    isRequired,
    errorMessage,
    onChange: onChange,
    onBlur: validateData,
    type: type ?? 'text',
    className: className ?? 'form-control mb-3 mb-lg-0',
    placeholder: placeholder ?? label,
    ref: controlRef,
    readOnly: readOnly,
  }

  const renderControl = () => {
    switch (controlType) {
      case ControlType.Select:
        const { options, displayValue, displayName, isClearable } = props
        controlProps.className = ''
        return <Select {...controlProps} options={options} displayValue={displayValue} displayName={displayName} isClearable={isClearable} />
      case ControlType.TextArea:
        return <TextArea {...controlProps} rows={props.rows ?? 1} />
      case ControlType.Checkbox:
        const { isFromSwitch } = props
        return <Checkbox {...controlProps} checked={value} isFromSwitch={isFromSwitch} title={label} />
      case ControlType.DatetimePicker:
        return <DatetimePicker {...controlProps} selectedDate={value} />
      case ControlType.DatePicker:
        return <DatePicker {...controlProps} selectedDate={value} />
      case ControlType.NumberBox:
        return <NumberBox {...controlProps} />
      case ControlType.RadioButton:
        const { options: radioOptions, isHorizontal } = props
        return <RadioButton {...controlProps} selectedOption={value} options={radioOptions} isHorizontal={isHorizontal} />
      default:
        return <Input {...controlProps} />
    }
  }

  return (
    <>
      {controlType !== ControlType.Checkbox && !isHideLabel && <Label title={label} isRequired={isRequired} contentTooltip={contentTooltip} />}
      {renderControl()}
    </>
  )
})

export { FormControl }
