import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import RatingStar from '../components/RatingStar'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import NumberBox from '../componentsShared/NumberBox'
import { FeaturedProductTypeEnum, getKeyByValue } from '../../../utilities/enums'
import { getFeaturedProductsByType } from '../../../redux/actions/eco/featuredProductActions'
import { useIntl } from 'react-intl'

const TopProducts = () => {
  const intl = useIntl()
  const [topSelling, setTopSelling] = useState([])
  const [trendingProduct, setTrendingProduct] = useState([])
  const [recentlyAdded, setRecentlyAdded] = useState([])
  const [topRated, setTopRated] = useState([])

  const [productGroups] = useState([
    FeaturedProductTypeEnum.TopSelling,
    FeaturedProductTypeEnum.TrendingProduct,
    FeaturedProductTypeEnum.RecentlyAdded,
    FeaturedProductTypeEnum.TopRated,
  ])

  useEffect(() => {
    getTopSelling()
    getTrendingProduct()
    getTopRated()
    getRecentlyAdded()
  }, [])

  const getTopSelling = async () => {
    try {
      const { result } = await getFeaturedProductsByType({
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
        type: FeaturedProductTypeEnum.TopSelling,
        current: true,
      })

      if (result) {
        setTopSelling(result.items)
      } else {
        setTopSelling([])
      }
    } catch (error) {
      console.error('Error fetching initial getTopSelling:', error)
    }
  }

  const getTrendingProduct = async () => {
    try {
      const { result } = await getFeaturedProductsByType({
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
        type: FeaturedProductTypeEnum.TrendingProduct,
        current: true,
      })

      if (result) {
        setTrendingProduct(result.items)
      } else {
        setTrendingProduct([])
      }
    } catch (error) {
      console.error('Error fetching initial getTrendingProduct:', error)
    }
  }

  const getTopRated = async () => {
    try {
      const { result } = await getFeaturedProductsByType({
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
        type: FeaturedProductTypeEnum.TopRated,
        current: true,
      })

      if (result) {
        setTopRated(result.items)
      } else {
        setTopRated([])
      }
    } catch (error) {
      console.error('Error fetching initial getTopRated:', error)
    }
  }

  const getRecentlyAdded = async () => {
    try {
      const { result } = await getFeaturedProductsByType({
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
        type: FeaturedProductTypeEnum.RecentlyAdded,
        current: true,
      })

      if (result) {
        setRecentlyAdded(result.items)
      } else {
        setRecentlyAdded([])
      }
    } catch (error) {
      console.error('Error fetching initial getRecentlyAdded:', error)
    }
  }

  return (
    <section id='home-top-product'>
      <div className='mb-8 mb-xl-14'>
        <div className='row'>
          {productGroups?.map((groupName) => {
            let products =
              {
                [FeaturedProductTypeEnum.TopSelling]: topSelling,
                [FeaturedProductTypeEnum.TrendingProduct]: trendingProduct,
                [FeaturedProductTypeEnum.RecentlyAdded]: recentlyAdded,
                [FeaturedProductTypeEnum.TopRated]: topRated,
              }[groupName] || []
            return (
              <ShowTopProduct
                key={getKeyByValue(FeaturedProductTypeEnum, groupName)}
                products={products}
                name={intl.formatMessage({ id: `FeatureProduct.${getKeyByValue(FeaturedProductTypeEnum, groupName)}` })}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

const ShowTopProduct = ({ products, name }) => {
  return (
    <div className='col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0'>
      <h4 className='section-title style-1'>{name}</h4>
      <div className='product-list-small'>
        {products?.map((d) => {
          return (
            <article className='row align-items-center hover-up' key={d.productId}>
              <figure className='col-md-4 mb-0'>
                <Link to={`/product/${d.productId}`}>
                  <img className='rounded' src={DEFAULT_VALUE.BASE_URL + d.imageUrl} alt='' />
                </Link>
              </figure>
              <div className='col-md-8 mb-0'>
                <h6>
                  <Link to={`/product/${d.productId}`}>{d.productName}</Link>
                </h6>
                <RatingStar rating={d.rating} />
                <div className='product-price'>
                  <NumberBox type='text' value={d.basePrice - d.discountedPrice} className='' suffix='đ' />
                  <NumberBox type='text' value={d.basePrice} className='old-price' suffix='đ' />
                </div>
              </div>
            </article>
          )
        })}
      </div>
    </div>
  )
}

export default TopProducts
