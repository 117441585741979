import { Auth } from '../../../components/auth/Auth'
import { ShoppingCart as ShoppingCartAPI } from '../../../services'

// Async actions

export const ADD_TO_CART = 'add'
export const DECREASE_CART = 'decreaseCart'
export const REMOVE_FROM_CART = 'remove'
export const REMOVE_ALL_CART = 'removeAll'
export const INCREASE_CART = 'increaseCart'

const getByUserId = async (userId) => {
  return await ShoppingCartAPI.getByUserId(userId)
}

const saveShoppingCart = async (payload) => {
  return await ShoppingCartAPI.saveShoppingCart(payload)
}

const removeShoppingCart = async (userId, cartDetailId) => {
  return await ShoppingCartAPI.removeShoppingCart(userId, cartDetailId)
}

const addToCart = (productOrder) => async (dispatch) => {
  Auth.requireLogin()
  dispatch(addToCartReducer(productOrder))
  //updating shopping cart
  await saveShoppingCart(Auth.getUserShoppingCart())
}

const increaseCart = (cartDetailId) => async (dispatch) => {
  dispatch(increaseCartReducer(cartDetailId))
  //updating shopping cart
  await saveShoppingCart(Auth.getUserShoppingCart())
}

const decreaseCart = (cartDetailId) => async (dispatch) => {
  dispatch(decreaseCartReducer(cartDetailId))
  //updating shopping cart
  await saveShoppingCart(Auth.getUserShoppingCart())
}

const removeFromCart = (cartDetailId) => async (dispatch) => {
  dispatch(removeFromCartReducer(cartDetailId))
  //updating shopping cart
  const cart = Auth.getUserShoppingCart()
  cart.count > 0 ? await saveShoppingCart(cart) : await removeShoppingCart(Auth.getUserId(), cartDetailId)
}

const removeAllCart = () => async (dispatch) => {
  dispatch(removeAllCartReducer())
  //remove shopping cart
  await removeShoppingCart(Auth.getUserId())
}

export { getByUserId, addToCart, decreaseCart, removeFromCart, removeAllCart, increaseCart }

const addToCartReducer = (productOrder) => {
  return {
    type: ADD_TO_CART,
    product: productOrder,
  }
}

const increaseCartReducer = (cartDetailId) => {
  return {
    type: INCREASE_CART,
    cartDetailId,
  }
}

const decreaseCartReducer = (cartDetailId) => {
  return {
    type: DECREASE_CART,
    cartDetailId,
  }
}

const removeFromCartReducer = (cartDetailId) => {
  return {
    type: REMOVE_FROM_CART,
    cartDetailId,
  }
}

const removeAllCartReducer = () => {
  return {
    type: REMOVE_ALL_CART,
  }
}
