import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon, toAbsoluteUrl } from '../../../../helpers'
import { FormattedMessage } from 'react-intl'

const Notification = () => {
  const [countNotifications, setCountNotifications] = useState(0)

  return (
    <div className='d-flex'>
      <div className='content cursor-pointer' data-kt-menu-trigger='hover' data-kt-menu-attach='parent' data-kt-menu-placement='bottom-end'>
        <i className='fa fa-bell text-white' aria-hidden='true'></i>
        <span className='ms-1'>
          <FormattedMessage id='Notifications' />
        </span>
      </div>

      <div className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px' data-kt-menu='true'>
        <div className='d-flex flex-column bgi-no-repeat rounded-top' style={{ backgroundImage: `url('${toAbsoluteUrl('/media/patterns/header-bg.jpg')}')` }}>
          <h3 className='text-white fw-bold px-8 my-4'>
            <FormattedMessage id='Notifications' />
            <span className='badge badge-danger ms-1 mb-1 fs-8 ps-3 float-end'>
              {countNotifications} <FormattedMessage id='News' />{' '}
            </span>
          </h3>
        </div>

        <div className='scroll-y mh-325px my-5 px-8'>
          {/* {defaultLogs.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span>

                <a  className='text-gray-800 text-hover-primary fw-bold'>
                  {log.message}
                </a>

                <span className='badge badge-light fs-8'>{log.time}</span>
              </div>
            </div>
          ))} */}
        </div>
        <div className='text-center border-top'>
          <Link to='/home' className='btn btn-color-gray-600 btn-active-color-primary'>
            <FormattedMessage id='ViewAll' />
            <Icon iconName='arrow-right' className='fs-5' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Notification
