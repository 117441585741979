import { useState, useEffect } from 'react'
import Product from '../components/Product'
import { useIntl, FormattedMessage } from 'react-intl'
import { FeaturedProductTypeEnum } from '../../../utilities/enums'
import { getFeaturedProductsByType } from '../../../redux/actions/eco/featuredProductActions'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import PageNumber from '../components/PageNumber'

const SuggestedProducts = () => {
  const intl = useIntl()
  const [featuredProducts, setFeaturedProducts] = useState([])
  const [selectedItem, setSelectedItem] = useState(FeaturedProductTypeEnum.TopSelling)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize] = useState(20)
  const [totalPages, setTotalPages] = useState(0)

  const [productGroups] = useState(
    Object.keys(FeaturedProductTypeEnum).map((key) => ({
      value: FeaturedProductTypeEnum[key],
      name: intl.formatMessage({ id: `FeatureProduct.${key}` }),
    }))
  )

  useEffect(() => {
    getFeaturedProducts()
  }, [selectedItem, currentPage])

  const getFeaturedProducts = async () => {
    try {
      const { result } = await getFeaturedProductsByType({
        pageIndex: currentPage,
        pageSize: pageSize,
        type: selectedItem,
        current: true,
      })

      if (result) {
        setFeaturedProducts(result.items)
        setTotalPages(result.totalPageCount)
      } else {
        setFeaturedProducts([])
        setTotalPages(0)
      }
    } catch (error) {
      console.error('Error fetching initial productPriority:', error)
    }
  }

  return (
    <section id='home-product-suggestions'>
      <div className='mb-8 mb-xl-14'>
        <div className='section-title d-flex align-items-center justify-content-between overflow-auto'>
          <h3>
            <FormattedMessage id='PopularProduct' />
          </h3>

          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {productGroups.map((item) => {
              return (
                <li className='nav-item' key={item.value}>
                  <a onClick={() => setSelectedItem(item.value)} className={`nav-link text-active-primary px-4 ${item.value === selectedItem ? 'active' : ''}`}>
                    {item.name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>

        <div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5'>
          <Product products={featuredProducts} />
        </div>
        <div className='p-4'>
          <PageNumber currentPage={currentPage} totalPages={totalPages} className='pagination-lg' onPageChange={setCurrentPage} />
        </div>
      </div>
    </section>
  )
}

export default SuggestedProducts
