const CustomeCheckbox = ({ ...props }) => {
  const { id, name, text, value, className, onChange } = props

  return (
    <div className={`custome-checkbox ${className}`}>
      <input className='form-check-input' type='checkbox' name={name} id={id} value={value} onChange={onChange}  />
      <label className='form-check-label' htmlFor={id}>
        <span className='hover-text'>{text}</span>
      </label>
    </div>
  )
}

export default CustomeCheckbox
