import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class AffiliateCustomerRelationAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.AFFILIATE_CUSTOMER_RELATION)
    this.adapter = this.initAdapter(conf)
  }

  async getById(affiliateCustomerRelationId) {
    return await this.adapter.getAsync(`getById?id=${affiliateCustomerRelationId}`)
  }

  async getAffiliateCustomerRelations(payload) {
    return await this.adapter.postAsync('getAffiliateCustomerRelations', { payload })
  }

  async saveAffiliateCustomerRelation(payload) {
    return await this.adapter.postAsync('saveAffiliateCustomerRelation', { payload })
  }

  async updateAffiliateCustomerRelation(payload) {
    return await this.adapter.putAsync('updateAffiliateCustomerRelation', { payload })
  }

  async deleteAffiliateCustomerRelation(affiliateCustomerRelationId) {
    return await this.adapter.deleteAsync(`deleteAffiliateCustomerRelation?id=${affiliateCustomerRelationId}`)
  }
}

export default AffiliateCustomerRelationAPI
