class Auth {
  static AUTH_LOCAL_USER = process.env.REACT_APP_AUTH_LOCAL_USER
  static AUTH_LOCAL_CART = process.env.REACT_APP_AUTH_LOCAL_CART
  static AUTH_LOCAL_TOKEN = process.env.REACT_APP_AUTH_LOCAL_TOKEN
  static AUTH_LOCAL_MODULE = process.env.REACT_APP_AUTH_LOCAL_MODULE
  static loginUrl = `/auth/login?returnUrl=${encodeURIComponent(window.location.href)}`

  static saveUserData(user, token, modules = []) {
    if (!localStorage) {
      return
    }

    try {
      localStorage.setItem(Auth.AUTH_LOCAL_USER, JSON.stringify(user))
      localStorage.setItem(Auth.AUTH_LOCAL_TOKEN, token)
      localStorage.setItem(Auth.AUTH_LOCAL_MODULE, JSON.stringify(modules))
    } catch (error) {
      console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
  }

  static async saveUserShoppingCart(cart) {
    if (!localStorage) {
      return
    }

    try {
      localStorage.setItem(Auth.AUTH_LOCAL_CART, JSON.stringify(cart))
    } catch (error) {
      console.error('AUTH LOCAL STORAGE SAVE CART ERROR', error)
    }
  }

  static getUserShoppingCart() {
    if (!localStorage) {
      return
    }

    try {
      const cart = JSON.parse(localStorage.getItem(Auth.AUTH_LOCAL_CART))
      const userId = this.getUserId()
      return cart && cart.userId === userId ? cart : null
    } catch (error) {
      console.error('AUTH LOCAL STORAGE PARSE CART ERROR', error)
    }
  }

  static getUserId() {
    if (!localStorage) {
      return
    }

    try {
      const user = this.getUserData()
      return user && user?.id
    } catch (error) {
      console.error('AUTH LOCAL STORAGE GET USER ID ERROR', error)
    }
  }

  static getUserData() {
    if (!localStorage) {
      return
    }

    try {
      const user = localStorage.getItem(Auth.AUTH_LOCAL_USER)
      return user ? JSON.parse(user) : null
    } catch (error) {
      console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
  }

  static getToken() {
    if (!localStorage) {
      return
    }

    try {
      return localStorage.getItem(Auth.AUTH_LOCAL_TOKEN)
    } catch (error) {
      console.error('AUTH LOCAL STORAGE GET TOKEN ERROR', error)
    }
  }

  static getModule() {
    if (!localStorage) {
      return
    }

    try {
      const modules = localStorage.getItem(Auth.AUTH_LOCAL_MODULE)
      return modules ? JSON.parse(modules) : []
    } catch (error) {
      console.error('AUTH LOCAL STORAGE GET TOKEN ERROR', error)
    }
  }

  static clearUserData() {
    if (!localStorage) {
      return
    }

    try {
      localStorage.removeItem(Auth.AUTH_LOCAL_USER)
      localStorage.removeItem(Auth.AUTH_LOCAL_TOKEN)
      localStorage.removeItem(Auth.AUTH_LOCAL_MODULE)
      localStorage.removeItem(Auth.AUTH_LOCAL_CART)
    } catch (error) {
      console.error('AUTH LOCAL STORAGE CLEAR ERROR', error)
    }
  }

  static requireLogin() {
    const user = Auth.getUserData()
    if (!user) {
      Auth.clearUserData()
      window.location.href = Auth.loginUrl
    }
  }
}

export { Auth }
