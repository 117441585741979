import React, { createContext, useContext, useEffect, useState } from 'react'

const PageDataContext = createContext({
  setPageTitle: (_title) => { },
  setPageBreadcrumbs: (_breadcrumbs) => { },
  setPageDescription: (_description) => { },
  setIsHideTitle: (_description) => { },
})

function usePageData() {
  return useContext(PageDataContext)
}

function withPageData(Component) {
  return function (props) {
    const { pageTitle, pageDescription, pageBreadcrumbs, isHideTitle } = usePageData()
    return <Component
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pageBreadcrumbs={pageBreadcrumbs}
      isHideTitle={isHideTitle}
      {...props}
    />
  }
}

const PageDataProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('')
  const [pageDescription, setPageDescription] = useState('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([])
  const [isHideTitle, setIsHideTitle] = useState(false)
  const value = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    isHideTitle, 
    setIsHideTitle
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

const PageTitle = ({ children, description, breadcrumbs, isHideTitle }) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs, setIsHideTitle } = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (isHideTitle) {
      setIsHideTitle(isHideTitle)
    }
    return () => {
      setIsHideTitle(false)
    }
  }, [isHideTitle])

  return <></>
}

const PageDescription = ({ children }) => {
  const { setPageDescription } = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export { PageDescription, PageTitle, PageDataProvider, usePageData, withPageData }