import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ImageMagnify from 'react-image-magnify'
import SVG from 'react-inlinesvg'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import '../../../assets/sass/productDetail.scss'
import { toAbsoluteUrl } from '../../../helpers'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import useGetFiles from '../../../helpers/useGetFiles'
import { getById } from '../../../redux/actions/eco/productActions'
import { getByProductId } from '../../../redux/actions/eco/productCategoryActions'
import { addToCart } from '../../../redux/actions/eco/shoppingCartActions'
import { getProductSupplierBySupplierId, getSameProductsBySupplierId } from '../../../redux/actions/eco/supplierActions'
import { DEFAULT_VALUE, FILE_TYPE, GUID_NEWID, TABLE_NAME } from '../../../utilities/constant'
import { DiscountTypeEnum } from '../../../utilities/enums'
import { Auth } from '../../auth/Auth'
import NewsBanner from '../components/NewsBanner'
import PageTitle from '../components/PageTitle'
import RatingStar from '../components/RatingStar'
import Input from '../componentsShared/Input'
import NumberBox from '../componentsShared/NumberBox'
import CreateAddressModal from './CreateAddressModal'
import OtherProduct from './OtherProduct'
import ProductSupplier from './ProductSupplier'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const ProductDetail = () => {
  const { productId } = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [logo] = useGetFiles(TABLE_NAME.PRODUCT, productId, FILE_TYPE.PRODUCT_MAIN_IMAGE)
  const [productImages] = useGetFiles(TABLE_NAME.PRODUCT, productId, FILE_TYPE.PRODUCT_SUB_IMAGE)
  const combinedImages = [...logo, ...productImages]

  const [product, setProduct] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [productCategories, setProductCategories] = useState([])
  const [supplierProducts, setSupplierProducts] = useState([])
  const [qtyRemain, setQtyRemain] = useState(0)
  const [quantityOrder, setQuantityOrder] = useState(1)

  const [isEmptyAddress, setIsEmptyAddress] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [propertySelected, setPropertySelected] = useState([])
  const [address, setAddress] = useState(null)
  const [isEditAddress, setIsEditAddress] = useState(false)

  const [hoveredImageId, setHoveredImageId] = useState(null)

  const cart = useSelector((state) => state.shoppingCart)

  useEffect(() => {
    if (logo.length > 0) {
      setHoveredImageId(logo[0].id)
    }
  }, [logo])

  useEffect(() => {
    const fetchData = async () => {
      const { result: prod } = await getById(productId)
      if (prod) {
        setProduct(prod)
        if (prod.qtyRemain && prod.qtyRemain > 0) {
          setQtyRemain(prod.qtyRemain)
        } else {
          setQuantityOrder(0)
        }

        const { result: supplierResult } = await getProductSupplierBySupplierId(prod.supplierId)
        if (supplierResult) {
          setSupplier(supplierResult)
        }

        const { result: productCategoriesResult } = await getByProductId(productId)
        if (productCategoriesResult) {
          setProductCategories(productCategoriesResult)
        }

        const { result: supplierProductsResult } = await getSameProductsBySupplierId(prod.supplierId)
        if (supplierProductsResult) {
          setSupplierProducts(supplierProductsResult)
        }
      }
    }

    fetchData()
  }, [productId])

  const increase = (e) => {
    let _value = Number(e.target.value || quantityOrder) + 1
    if (_value > qtyRemain) {
      _value = qtyRemain
    } else if (qtyRemain === 0) {
      _value = 0
    }
    setQuantityOrder(_value)
  }

  const decrease = (e) => {
    let _value = Number(e.target.value || quantityOrder) - 1
    if (_value < 1 && _value <= qtyRemain) {
      _value = 1
    } else if (qtyRemain === 0) {
      _value = 0
    }
    setQuantityOrder(_value)
  }

  const handleChangeQuantity = (e) => {
    let _value = Number(e.target.value)
    if (qtyRemain !== undefined && _value > qtyRemain) {
      _value = qtyRemain
    } else if (_value < 1 && _value <= qtyRemain) {
      _value = 1
    } else {
      _value = 0
    }
    setQuantityOrder(_value)
  }

  const handleImageHover = (index) => {
    setHoveredImageId(index)
  }

  const handleChangeProperty = (propId, propName, propDetailNewId, propNewValue) => {
    const updateProp = propertySelected.some((d) => d.propId === propId)
      ? propertySelected.map((d) => (d.propId === propId ? { ...d, propDetailId: propDetailNewId, propValue: propNewValue } : d))
      : [...propertySelected, { propId, propName, propDetailId: propDetailNewId, propValue: propNewValue }]
    setPropertySelected(updateProp)
  }

  const handleAddToCart = (isShowToast) => {
    if (quantityOrder === 0) {
      showToast(`Sản phẩm đã hết hàng`, TOAST_TYPES.WARNING)
      return
    }
    Auth.requireLogin()

    const isEmptyProperty = checkHasError()
    if (isEmptyProperty) {
      if (isEmptyProperty) setHasError(true)
      return
    }

    const fullAddress = formatAddress()
    const cartDetails = cart?.shoppingCartDetails
    let cartDetailId = cartDetails?.find((d) => d.productId === productId)?.id
    cartDetailId = cartDetailId ?? GUID_NEWID()

    const productOrder = {
      id: cartDetailId,
      productId: product?.id,
      productName: product?.productName,
      quantity: quantityOrder,
      unitPrice: product?.basePrice,
      productImage: logo && logo[0]?.filePath + DEFAULT_VALUE.SLASH + logo[0]?.fileNameServer,
      address: address && fullAddress,
      productPropertyName: propertySelected?.map((item) => `${item.propName}: ${item.propValue}`).join('; '),
      shippingAmount: 0,
      supplierId: product?.supplierId,
      supplierName: product?.supplierName,
      discountAmount: 0,
    }
    dispatch(addToCart(productOrder))

    if (!isShowToast) {
      navigate(`/cart/${cartDetailId}`)
    } else {
      showToast(intl.formatMessage({ id: 'Product.AddCartSuccess' }), TOAST_TYPES.SUCCESS)
    }
  }

  const checkHasError = () => {
    const productProperties = product?.productPropperties || []
    return !_.isEmpty(productProperties) && (_.isEmpty(propertySelected) || productProperties.length !== propertySelected.length)
  }

  const refreshHasError = () => {
    const productProps = product?.productPropperties || []
    if (productProps.length === propertySelected?.length) {
      setHasError(false)
    }
  }

  const refreshIsEmptyAddress = () => {
    if (address) {
      setIsEmptyAddress(false)
    }
  }

  useEffect(() => {
    refreshHasError()
  }, [propertySelected])

  useEffect(() => {
    refreshIsEmptyAddress()
  }, [address])

  const handleCreateAddress = () => {
    setIsEditAddress(true)
  }

  const formatAddress = () => {
    if (!address) return intl.formatMessage({ id: 'AddNewAddress' })
    const { street, wardName, districtName, provinceName } = address
    return `${street}, ${wardName}, ${districtName}, ${provinceName}`
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  }

  return (
    <div className='product-detail'>
      <PageTitle />
      <div className='d-flex'>
        <div className='col-xl-9'>
          <section className='card d-flex flex-row mt-4'>
            <div className='product-image-content'>
              <div className='d-flex flex-column'>
                <div className='border rounded'>
                  {!_.isEmpty(hoveredImageId) && (
                    <ImageMagnify
                      {...{
                        smallImage: {
                          alt: 'Zoomable Image',
                          isFluidWidth: true,
                          src: DEFAULT_VALUE.BASE_URL + combinedImages.find((x) => x.id === hoveredImageId)?.fullPath,
                          className: 'product-image rounded',
                        },
                        largeImage: {
                          src: DEFAULT_VALUE.BASE_URL + combinedImages.find((x) => x.id === hoveredImageId)?.fullPath,
                          width: 1200,
                          height: 800,
                        },
                        isHintEnabled: true,
                        enlargedImagePosition: 'over',
                        enlargedImageContainerDimensions: {
                          width: '200%',
                          height: '200%',
                        },
                        enlargedImageContainerStyle: {
                          zIndex: 1000,
                        },
                        shouldHideHintAfterFirstActivation: false,
                      }}
                    />
                  )}
                </div>

                <Slider {...sliderSettings} className='mt-4'>
                  {!_.isEmpty(logo) &&
                    !_.isEmpty(productImages) &&
                    combinedImages.map((item) => (
                      <div
                        key={item.id}
                        className={`border rounded ${hoveredImageId === item.id ? 'product-image-hovered' : ''}`}
                        onMouseEnter={() => handleImageHover(item.id)}
                      >
                        <div className='product-image-wrapper'>
                          <img className='product-image rounded' src={DEFAULT_VALUE.BASE_URL + item.fullPath} alt='' />
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>

            <div className='d-flex flex-auto product-info-content'>
              <div className='flex-auto flex-column product-info-wrapper'>
                {/* <span className='stock-status out-stock'> Sale Off </span>
                <span className='stock-status free-ship'> Freeship </span> */}
                <div className='product-title'>
                  <h2>{product?.productName}</h2>
                </div>

                <div className='d-flex my-2'>
                  <div className='d-flex cursor-pointer'>
                    <div className='product-rate-text product-rate fw-bold fs-5 border-bottom'>{product?.rating}</div>
                    <RatingStar rating={product?.rating} isShowRating={false} />
                  </div>

                  <span className='ps-4 border-end'></span>
                  <div className='d-flex ps-4 cursor-pointer'>
                    <span className='product-rate-text border-bottom fw-bold'>
                      <NumberBox value={product?.quantityProductReviewed} className='' />
                    </span>
                    <span className='text-dark'>{intl.formatMessage({ id: 'Rating' })}</span>
                  </div>

                  <span className='ps-4 border-end'></span>
                  <div className='d-flex ps-4 cursor-pointer'>
                    <span className='product-rate-text border-bottom fw-bold'>
                      <NumberBox value={product?.quantityProductSold} className='' />
                    </span>
                    <div className='text-dark'>{intl.formatMessage({ id: 'Sold' })}</div>
                  </div>
                </div>

                <div className='flex flex-column bg-light p-3 mt-4 rounded '>
                  <section className='d-flex align-items-center' aria-live='polite'>
                    <div className='d-flex align-items-center product-price-wrapper'>
                      <div className='product-price-new'>
                        <NumberBox value={product?.basePrice} className='' prefix={DEFAULT_VALUE.VND} />
                      </div>
                      <div className='d-flex align-items-center ms-2'>
                        {product && product.discountType === DiscountTypeEnum.FixedPrice && (
                          <div className='product-price-old'>
                            <NumberBox value={product.discountedPrice} className='' prefix={DEFAULT_VALUE.VND} />
                          </div>
                        )}
                        {product && product.discountType !== DiscountTypeEnum.NoDiscount && (
                          <div className='product-price-discount-rate'>
                            <NumberBox
                              value={Math.floor(
                                product.discountType === DiscountTypeEnum.FixedPrice
                                  ? ((product.basePrice - product.discountedPrice) / product.basePrice) * 100
                                  : product.discountedPercent
                              )}
                              className=''
                              suffix={`${DEFAULT_VALUE.PERCENT} `}
                            />
                            <span className='text-lowercase'>{intl.formatMessage({ id: 'Discount' })}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>

                <div className={`bg-light p-3 my-2 rounded ${isEmptyAddress ? 'has-error' : ''}`}>
                  <div className='d-flex align-items-start row'>
                    <div className='col-4'>
                      <div
                        className='flex-shrink-0 d-flex align-items-center bg-primary rounded-1 fst-italic fw-bold text-white px-2'
                        style={{ paddingTop: '6px', paddingBottom: '6px', width: 'fit-content' }}
                      >
                        <div>
                          <SVG src={toAbsoluteUrl('/media/svg/icon/ship.svg')} />
                          <span>Freeship 80K</span>
                        </div>
                      </div>
                    </div>
                    <ul className='m-0 ps-6 col'>
                      <li>{intl.formatMessage({ id: 'Product.FreeShipping25k' })}</li>
                      <li>{intl.formatMessage({ id: 'Product.FreeShipping30k' })}</li>
                      <li>{intl.formatMessage({ id: 'Product.FreeShipping80k' })}</li>
                    </ul>
                  </div>
                  <pre className='m-0 bg-secondary my-2' style={{ height: '1px' }}></pre>
                  <div className='d-flex justify-content-start row'>
                    <div className='col-4 fw-bold'>{intl.formatMessage({ id: 'Product.TransportTo' })}</div>
                    <div
                      className='fw-bold d-flex justify-content-between align-content-center overflow-hidden cursor-pointer col-8'
                      onClick={handleCreateAddress}
                    >
                      <p className='text-truncate '>{formatAddress()}</p>
                      <i className={`fa fa-chevron-${address ? 'down' : 'right'}`}></i>
                    </div>

                    {isEditAddress && <CreateAddressModal editAddress={address} closeModal={setIsEditAddress} setAddress={setAddress} />}
                  </div>
                  <pre className='app-spacer m-0 bg-secondary my-2' style={{ height: '1px' }}></pre>
                  <div className='d-flex justify-content-start row'>
                    <div className='col-4 fw-bold'>{intl.formatMessage({ id: 'Product.EstimatedShippingCost' })}</div>
                    <div className='text-muted fw-bold d-flex justify-content-between align-content-center overflow-hidden cursor-pointer col-8'>
                      <p className='fw-bold'>
                        <span className='text-muted text-decoration-line-through'>{/* 24.630đ */}</span>
                        &ensp;<span className='text-primary'>{/* 0đ */}</span>
                      </p>
                      <i className='fa fa-chevron-right' aria-hidden='true'></i>
                    </div>
                  </div>
                  <div className={`${isEmptyAddress ? 'show-error' : 'hidden'}`}>
                    {intl.formatMessage({ id: 'PleaseChoose' })} {intl.formatMessage({ id: 'Product.ShipTo' })}
                  </div>
                </div>

                {product?.productPropperties?.length > 0 && (
                  <div className={`bg-light p-3 my-2 prop-box rounded ${hasError ? 'has-error' : ''}`}>
                    <div className={`row`}>
                      {product.productPropperties.map((d) => {
                        return (
                          <React.Fragment key={d.id}>
                            <div className='pt-2 col-xl-2 col-3 fw-bold'>{d.name}</div>
                            <div className='col-xl-10 col-9'>
                              <div role='group' className='app-toggle-group flex-wrap btn-group' style={{ margin: '0px -10px' }}>
                                {d.productPropertyDetails?.map((e) => {
                                  return (
                                    <label
                                      role='button'
                                      tabIndex='0'
                                      className={`fw-light text-dark rounded flex-grow-0 m-1 btn prop-btn-light ${
                                        propertySelected?.find((d) => d.propDetailId === e.id) ? 'prop-active' : ''
                                      }`}
                                      onClick={() => handleChangeProperty(d.id, d.name, e.id, e.value)}
                                      key={e.id}
                                    >
                                      {e.value}
                                    </label>
                                  )
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      })}
                      <div className={`${hasError ? 'show-error' : 'hidden'}`}>
                        {intl.formatMessage({ id: 'PleaseChoose' })} {intl.formatMessage({ id: 'Product.ProductClassification' })}
                      </div>
                    </div>
                  </div>
                )}

                <div className='my-2 d-flex flex-auto align-items-center'>
                  <div className='pt-2 col-xl-2 col-3 fw-bold'>{intl.formatMessage({ id: 'Quantity' })}</div>
                  <div className='d-flex align-items-center'>
                    <div className='me-3'>
                      <div className='d-flex align-items-center'>
                        <button className='product-quantity' onClick={decrease}>
                          <i className='fa fa-minus text-dark'></i>
                        </button>
                        <Input className='product-quantity product-quantity-text' value={quantityOrder} onChange={handleChangeQuantity} />
                        <button aria-label='Increase' className='product-quantity' onClick={increase}>
                          <i className='fa fa-plus text-dark'></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      {qtyRemain} {intl.formatMessage({ id: 'Product.ProductAvailable' })}
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-row row-cols-lg-3 mt-6 pe-4'>
                  <button type='button' className='btn btn-primary btn-lg me-2 fw-bold px-3'>
                    <i className='fa fa-share-square me-3'></i>
                    {intl.formatMessage({ id: 'Product.PostForSale' })}
                  </button>

                  <button type='button' className='btn btn-primary btn-lg me-2 fw-bold px-3' onClick={() => handleAddToCart(true)}>
                    <i className='fa fa-cart-plus me-3'></i>
                    {intl.formatMessage({ id: 'AddToCart' })}
                  </button>

                  <button type='button' className='btn btn-primary btn-lg fw-bold px-3' onClick={() => handleAddToCart(false)}>
                    {intl.formatMessage({ id: 'OrderNow' })}
                    <i className='fa fa-angle-right ms-3'></i>
                  </button>
                </div>
              </div>
            </div>
          </section>

          <ProductSupplier supplier={supplier} product={product} />
        </div>

        <div className='col-xl-3'>
          <div className='product-sidebar'>
            <div className='sidebar-widget' id='benefit-overview'>
              <div className='module_benefits'>
                <div className='buyer-benefits'>
                  <h4>
                    <span>{intl.formatMessage({ id: 'Product.MembershipBenefit' })}</span>
                  </h4>
                  <div className='buyer-item'>
                    <span className='golden-buyer-first-benefit'>{intl.formatMessage({ id: 'Product.Coupon80K' })} </span>
                    <a style={{ textDecoration: 'underline' }}>{intl.formatMessage({ id: 'ViewMore' })}</a>
                  </div>
                </div>
              </div>

              <div className='module_ta_plus'>
                <div className='ta-semi'>
                  <h3>
                    <div className='ta-header'>
                      <div className='ta-item'>
                        <span className='title'>{intl.formatMessage({ id: 'Product.ProtectiveMeasure' })}</span>
                      </div>
                    </div>
                  </h3>
                  <div className='semi-list'>
                    <div className='semi-item'>
                      <h4>
                        <img src={toAbsoluteUrl('/media/png/icons/payment-safe.png')} alt='' />
                        <div>
                          <span>{intl.formatMessage({ id: 'Footer.SecuredPayment' })}</span>
                        </div>
                      </h4>
                      <div>{intl.formatMessage({ id: 'Product.Policy' })}</div>
                    </div>
                    <div className='semi-item'>
                      <h4>
                        <img src={toAbsoluteUrl('/media/png/icons/policy-rerturn.png')} alt='' />
                        <div>
                          <span>{intl.formatMessage({ id: 'Product.RefundPolicy' })}</span>
                        </div>
                      </h4>
                      <div>{intl.formatMessage({ id: 'Product.RefundRequest' })}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='product-sidebar mt-8'>
            <div className='sidebar-widget'>
              <h5 className='section-title style-1 mb-5'>{intl.formatMessage({ id: 'Product.SimilarProduct' })}</h5>
              <div style={{ height: '285px', overflow: 'auto' }}>
                {productCategories?.map((d, index) => {
                  return (
                    <div className='single-post' key={d.id + index}>
                      <Link className='hover-up' to={`/product/${d.id}`}>
                        <div className='image'>
                          <img src={DEFAULT_VALUE.BASE_URL + d.logoUrl} alt='' />
                        </div>
                        <div className='content'>
                          <h5>
                            <a>{d.productName}</a>
                          </h5>
                          <p className='price mb-0'>
                            <NumberBox value={d.basePrice} className='' suffix={DEFAULT_VALUE.VND} />
                          </p>
                          <RatingStar rating={d.rating} />
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <NewsBanner news={[]} />
        </div>
      </div>
      <OtherProduct products={supplierProducts} />
    </div>
  )
}

export default ProductDetail
