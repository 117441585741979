import { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import DeliveryAddressModal from './DeliveryAddressModal'
import { getByAffiliateCustomerRelationId } from '../../../redux/actions/aff/affiliateCustomerAddressAction'
import CustomerRelationModal from './CustomerRelationModal'
import { useIntl } from 'react-intl'

const CheckoutHeader = ({ customerRelations, customerRelationSelected, onChangeCustomerRelation, selectedAddress, onChangeAddress }) => {
  const intl = useIntl()
  const [isOpenDeliveryAddress, setIsOpenDeliveryAddress] = useState(false)
  const [isOpenCustomer, setIsOpenCustomer] = useState(false)
  const [addresses, setAddresses] = useState([])

  useEffect(() => {
    if (customerRelationSelected) {
      fetchAddress()
    }
  }, [customerRelationSelected])

  const fetchAddress = async () => {
    const { result } = await getByAffiliateCustomerRelationId(customerRelationSelected?.id)
    if (result) {
      setAddresses(result)
      var defaultAdress = result.length > 0 ? result.find((x) => x.isDefault) || result[0] : null
      onChangeAddress({ ...defaultAdress, fullAddress: formatAddress(defaultAdress) })
    }
  }

  const formatAddress = (item) => {
    const { address, ward, district, province } = item
    return address ? `${address}, ${ward}, ${district}, ${province}` : item
  }

  return (
    <div className='checkout-header'>
      <div className='checkout-top-dash' />
      <div className='checkout-header-wrap d-flex pe-8'>
        <div className='col-md-6 checkout-clear-both'>
          <div className='checkout-header-address-wrap'>
            <div className='checkout-header-address-flex'>
              <div className='checkout-header-address-icon'>
                <img src={toAbsoluteUrl('../media/svg/icon/people.svg')} alt='' className='checkout-svg-icon icon-user' />
              </div>
              <h2>{intl.formatMessage({ id: 'Customer' })}</h2>
            </div>
            <div className='checkout-body-address-wrap d-flex cursor-pointer pe-8' onClick={() => setIsOpenCustomer(true)}>
              <div className='checkout-body-address-content flex-fill' onClick={() => setIsOpenCustomer(true)}>
                {customerRelationSelected ? (
                  <>
                    <div className='checkout-body-address-phone flex-fill'>{customerRelationSelected?.name}</div>
                    <div className='checkout-body-address-phone flex-fill'>{customerRelationSelected?.phone}</div>
                  </>
                ) : (
                  <span className='checkout-body-address-phone flex-fill'>{intl.formatMessage({ id: 'Checkout.ChooseCustomer' })}</span>
                )}
              </div>
              <div className='checkout-body-address-btn-change'>{intl.formatMessage({ id: 'Change' })}</div>
            </div>
          </div>
        </div>

        <div className='col-md-6 ps-10'>
          <div className='checkout-header-address-wrap'>
            <div className='checkout-header-address-flex'>
              <div className='checkout-header-address-icon'>
                <img src={toAbsoluteUrl('../media/svg/icon/location.svg')} alt='' className='checkout-svg-icon icon-location-marker' />
              </div>
              <h2>{intl.formatMessage({ id: 'Checkout.DeliveryAddress' })}</h2>
            </div>
            <div className='checkout-body-address-wrap d-flex cursor-pointer' onClick={() => setIsOpenDeliveryAddress(true)}>
              <div className='checkout-body-address-content flex-fill'>
                <div className='checkout-body-address-phone flex-fill'>
                  {selectedAddress ? formatAddress(selectedAddress) : <span>{intl.formatMessage({ id: 'Checkout.EnterAddress' })}</span>}
                </div>
              </div>
              <div className='checkout-body-address-btn-change'>{intl.formatMessage({ id: 'Change' })}</div>
            </div>
          </div>
        </div>
      </div>

      {isOpenDeliveryAddress && (
        <DeliveryAddressModal
          closeModal={setIsOpenDeliveryAddress}
          setSelectedAddress={onChangeAddress}
          addresses={addresses}
          selectedAddress={selectedAddress}
        />
      )}

      {isOpenCustomer && (
        <CustomerRelationModal
          closeModal={setIsOpenCustomer}
          customerRelationSelected={customerRelationSelected}
          setCustomerRelationSelected={onChangeCustomerRelation}
          customerRelations={customerRelations}
        />
      )}
    </div>
  )
}

export default CheckoutHeader
