import { FeaturedProduct as FeaturedProductAPI } from '../../../services'

// Async actions

const getFeaturedProducts = async () => {
  return await FeaturedProductAPI.getFeaturedProducts()
}

const getFeaturedProductsByCategoryId = async (categoryId, pageIndex, pageSize) => {
  return await FeaturedProductAPI.getFeaturedProductsByCategoryId(categoryId, pageIndex, pageSize)
}

const getFeaturedProductsByType = async (payload) => {
  return await FeaturedProductAPI.getFeaturedProductsByType(payload)
}

export { getFeaturedProducts, getFeaturedProductsByCategoryId, getFeaturedProductsByType }
