import { Product as ProductAPI } from '../../../services'

// Async actions

const getById = async (productId) => {
  return await ProductAPI.getById(productId)
}

const getProductsByCategoryId = async (categoryId, pageIndex, pageSize) => {
  return await ProductAPI.getProductsByCategoryId(categoryId, pageIndex, pageSize)
}

const searchProducts = async (payload) => {
  return await ProductAPI.searchProducts(payload)
}

export { getById, getProductsByCategoryId, searchProducts }
