import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../helpers'

const RatingStar = ({ rating, isShowRating = true }) => {
  const stars = [1, 2, 3, 4, 5]

  const getStar = (index) => {
    let starType = ''
    if (index <= rating) {
      starType = 'full-'
    } else if (index > rating && index === Math.floor(rating) + 1 && rating % 1 !== 0) {
      starType = 'haft-'
    }

    return (
      <span key={`star-${index}`} className={`${starType}star-icon`}>
        <SVG style={{ verticalAlign: 'sub' }} width={18} height={18} src={toAbsoluteUrl(`/media/svg/icon/${starType}star.svg`)} />
      </span>
    )
  }
  return (
    <div className='product-rate-cover d-flex'>
      <div className='react-stars-wrapper'>
        {stars.map((index) => {
          return getStar(index)
        })}
      </div>
      {isShowRating && <span className='ms-2 text-muted'> ({rating ?? 0})</span>}
    </div>
  )
}
export default RatingStar
