import { Affiliate as AffiliateAPI } from '../../../services'

const getById = async (id) => {
  return await AffiliateAPI.getById(id)
}

const getAffiliates = async (payload) => {
  return await AffiliateAPI.getAffiliates(payload)
}

const getByAffiliateCode = async (id, affiliateCode) => {
  return await AffiliateAPI.getByAffiliateCode(id, affiliateCode)
}

const saveAffiliate = async (payload) => {
  return await AffiliateAPI.saveAffiliate(payload)
}

const updateAffiliate = async (payload) => {
  return await AffiliateAPI.updateAffiliate(payload)
}

const getAccountBalance = async () => {
  return await AffiliateAPI.getAccountBalance()
}


export { getById, getAffiliates, getByAffiliateCode, saveAffiliate, updateAffiliate, getAccountBalance}
