import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'
import { Auth } from '../../components/auth/Auth'
import { getByUserId } from '../../redux/actions/eco/shoppingCartActions'

class AuthApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.ACCOUNT)
    this.adapter = this.initAdapter(conf)
  }

  async login(payload) {
    const { error, result: authResult } = await this.adapter.postAsync('Login', { payload })
    if (authResult) {
      Auth.saveUserData(authResult.user, authResult.token)
      const { result: cartResult } = await getByUserId(authResult.user?.id)
      if (cartResult) {
        Auth.saveUserShoppingCart(cartResult)
      }
      return authResult
    }
    return { error: true, message: error.message }
  }

  async logout() {
    Auth.clearUserData()
    await this.adapter.postAsync('account/Logout')
  }

  async resetPassword(payload) {
    payload =  { ...payload, loginPage: 99998888 }
    return await this.adapter.postAsync('resetPassword', { payload })
  }

  async newPassword(payload) {
    return await this.adapter.postAsync('newPassword', { payload })
  }

  async register(payload) {
    return await this.adapter.postAsync('register', { payload })
  }
}

export default AuthApi
