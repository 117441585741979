import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { Auth } from '../../../../components/auth/Auth'
import { toAbsoluteUrl } from '../../../../helpers'
import { Search } from './Search'
import ShoppingCart from './ShoppingCart'
import Notification from './Notification'
import Languages from './Languages'
import UserMenu from './UserMenu'
import Categories from './Categories'

const TopNavbar = () => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <div id='kt_header' className='d-flex flex-column'>
      <div className={`bg-primary`}>
        <div className='container-xxl navbar-wrapper d-flex justify-content-between py-2'>
          <div className='d-flex align-items-stretch'>
            <Link to='/home' className='content'>
              <FormattedMessage id='Home' />
            </Link>
            <div className='border-start mx-2'></div>
            <Link to='#' className='content'>
              <FormattedMessage id='AboutUs' />
            </Link>
            <div className='border-start mx-2'></div>
            <Link to='/purchase/1' className='content'>
              <FormattedMessage id='OrderTracking' />
            </Link>
            <div className='border-start mx-2'></div>
            <div className='content'>
              <FormattedMessage id='FollowUsOn' />
            </div>
            <div className='content'>
              <a target='_blank' title={intl.formatMessage({ id: 'FollowUsOnFacebook' })}>
                <i className='fa-brands fa-facebook text-white' aria-hidden='true'></i>
              </a>
              <a target='_blank' title={intl.formatMessage({ id: 'FollowUsOnInstagram' })} className='ms-2'>
                <i className='fa-brands fa-instagram text-white' aria-hidden='true'></i>
              </a>
              <a target='_blank' title={intl.formatMessage({ id: 'FollowUsOnTwitter' })} className='ms-2'>
                <i className='fa-brands fa-twitter text-white' aria-hidden='true'></i>
              </a>
              <a target='_blank' title={intl.formatMessage({ id: 'FollowUsOnYoutube' })} className='ms-2'>
                <i className='fa-brands fa-youtube text-white' aria-hidden='true'></i>
              </a>
              <a target='_blank' title={intl.formatMessage({ id: 'FollowUsOnTiktok' })} className='ms-2'>
                <i className='fa-brands fa-tiktok text-white' aria-hidden='true'></i>
              </a>
            </div>
          </div>

          <div className='d-flex align-items-stretch'>
            <div className='d-flex'>
              <a target='_blank' className='content'>
                <span className='ms-1'>
                  <FormattedMessage id='CallUs' />: + 1800 900
                </span>
              </a>
            </div>
            <div className='border-start mx-2' />
            <Notification />
            <div className='border-start mx-2' />
            <Languages />
          </div>
        </div>
      </div>

      <div className={`bg-white`}>
        <div className='container-xxl d-flex py-9'>
          <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
            <Link to='/home'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='logo-sticky h-45px me-10' />
            </Link>
          </div>

          <div className='header-wrap'>
            <div className='header-right' data-select2-id='9'>
              <Search />
              <div className='header-action-right'>
                <div className='header-action-2'>
                  <div className='header-action-icon-2 hover-text'>
                    <a className='header-action-content' href='/shop-wishlist'>
                      <img src={toAbsoluteUrl('/media/svg/icon/icon-heart.svg')} />
                      <span className='pro-count blue'>0</span>
                    </a>
                    <a className='header-action-content' href='/shop-wishlist'>
                      <span className='ms-2 label'>
                        <FormattedMessage id='Wishlist' />
                      </span>
                    </a>
                  </div>
                  <div className='header-action-icon-2'>
                    <ShoppingCart />
                  </div>
                  <div className='header-action-icon-2'>
                    {_.isEmpty(currentUser) ? (
                      <div className='d-flex ms-1 ms-lg-3'>
                        <img src={toAbsoluteUrl('/media/svg/icon/icon-user.svg')} />
                        <a href='/auth/registration' className='header-action-content'>
                          <span className='ms-2 label hover-text'>
                            <FormattedMessage id='Register' />
                          </span>
                        </a>
                        <div className='border-start border-dark mt-3  mx-2'></div>
                        <a href='/auth/login' className='header-action-content'>
                          <span className='ms-2 label hover-text'>
                            <FormattedMessage id='Login' />
                          </span>
                        </a>
                      </div>
                    ) : (
                      <div className='d-flex' id='kt_header_user_menu_toggle'>
                        <a
                          className='cursor-pointer header-action-content'
                          data-kt-menu-trigger='click'
                          data-kt-menu-attach='parent'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='bottom'
                        >
                          <img src={toAbsoluteUrl('/media/svg/icon/icon-user.svg')} />
                          <span className='ms-2 label'>{currentUser?.fullName || 'Phạm Minh Thức'}</span>
                        </a>
                        <UserMenu />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${scrollPosition > 200 ? 'fixed-header show animate__animated animate__fadeInDown' : ''}`}>
          <Categories />
        </div>
      </div>
    </div>
  )
}

export default TopNavbar
