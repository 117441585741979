import React, { useState, useEffect } from 'react'
import * as home from '../home'
import '../../../assets/sass/home.scss'
import { getBannerDisplays } from '../../../redux/actions/eco/bannerActions'
import { BANNER_POSITION_ENUM, DEFAULT_VALUE } from '../../../utilities/constant'
import { getCategories } from '../../../redux/actions/eco/categoryActions'
import { getPromotions } from '../../../redux/actions/eco/promotionActions'

const Home = () => {
  const [banners, setBanners] = useState([])
  const [categories, setCategories] = useState([])
  const [promotions, setPromotions] = useState([])

  const fetchBanners = async () => {
    try {
      const { result } = await getBannerDisplays()
      if (result) {
        setBanners(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCategories = async () => {
    try {
      const { result } = await getCategories()
      if (result) {
        setCategories(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPromotions = async () => {
    try {
      const { result } = await getPromotions({ pageIndex: DEFAULT_VALUE.PAGE_INDEX, pageSize: 4, isDisplayOnAff: true })
      if (result) {
        setPromotions(result.items)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchBanners()
    fetchCategories()
    fetchPromotions()
  }, [])

  const getRandomItems = (arr, n) => {
    let result = []
    let copy = [...arr]
    while (result.length < n && copy.length) {
      const index = Math.floor(Math.random() * copy.length)
      result.push(copy.splice(index, 1)[0])
    }
    return result
  }

  return (
    <>
      <home.Banner banners={banners.filter((e) => e.positionId === BANNER_POSITION_ENUM.TOP)} />
      <home.Categories categories={categories} />
      <home.CategoryBanners categories={getRandomItems(categories, 3)} />
      <home.MarqueeText items={categories.slice(0, 5).map((x) => x.categoryName)} />
      <home.SuggestedProducts />
      <home.MarqueeText items={categories.slice(5, 10).map((x) => x.categoryName)} />
      <home.ProductsByCategory categories={categories} />
      <home.Banner banners={banners.filter((e) => e.positionId === BANNER_POSITION_ENUM.MIDDLE)} />
      <home.Promotions promotions={promotions} />
      <home.Banner banners={banners.filter((e) => e.positionId === BANNER_POSITION_ENUM.BOTTOM)} />
      <home.TopProducts />
    </>
  )
}

export default Home
