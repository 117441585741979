import AuthApi from './auth/authApi'
import UserApi from './auth/userApi'
import AffiliateAPI from './aff/affiliateApi'
import AffiliateCustomerRelationAPI from './aff/affiliateCustomerRelationApi'
import AffiliateCustomerAddressAPI from './aff/affiliateCustomerAddressApi'
import AffiliateCommissionMoneyAPI from './aff/affiliateCommissionMoneyApi'
import BannerAPI from './eco/bannerApi'
import FileAttachmentAPI from './gnr/fileAttachmentApi'
import CategoryAPI from './eco/categoryApi'
import FeaturedProductAPI from './eco/featuredProductApi'
import DanhMucAPI from './gnr/danhMucApi'
import PromotionAPI from './eco/promotionApi'
import ShoppingCartAPI from './eco/shoppingCartApi'
import ProductAPI from './eco/productApi'
import SupplierAPI from './eco/supplierApi'
import ProductCategoryAPI from './eco/productCategoryApi'
import ProvinceAPI from './gnr/provinceApi'
import SubCategoryAPI from './eco/subCategoryApi'
import CouponAPI from './eco/couponApi'
import OrderAPI from './eco/orderApi'

const DanhMuc = new DanhMucAPI()
const Auth = new AuthApi()
const User = new UserApi()
const Affiliate= new AffiliateAPI()
const AffiliateCustomerRelation = new AffiliateCustomerRelationAPI()
const AffiliateCustomerAddress = new AffiliateCustomerAddressAPI()
const AffiliateCommissionMoney = new AffiliateCommissionMoneyAPI()
const Banner = new BannerAPI()
const FileAttachment = new FileAttachmentAPI()
const Category = new CategoryAPI()
const FeaturedProduct = new FeaturedProductAPI()
const Promotion = new PromotionAPI()
const ShoppingCart = new ShoppingCartAPI()
const Product = new ProductAPI()
const Supplier = new SupplierAPI()
const ProductCategory = new ProductCategoryAPI()
const Province = new ProvinceAPI()
const SubCategory = new SubCategoryAPI()
const Coupon = new CouponAPI()
const Order = new OrderAPI()

export {
  DanhMuc,
  Auth,
  User,
  Affiliate,
  AffiliateCustomerRelation,
  AffiliateCustomerAddress,
  AffiliateCommissionMoney,
  Banner,
  FileAttachment,
  Category,
  FeaturedProduct,
  Promotion,
  ShoppingCart,
  Product,
  Supplier,
  ProductCategory,
  Province,
  SubCategory,
  Coupon,
  Order,
}
