import React, { useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, ControlType } from '../../pages/componentsShared/FormControl'
import _ from 'lodash'
import { newPassword } from '../../../redux/actions/auth/authActions'
import { bindValidationMessages } from '../../../utilities/validationMessage'

const NewPassword = () => {
  const intl = useIntl()
  const location = useLocation()
  const validationMessage = bindValidationMessages()

  const componentRefs = useRef({})
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)
  const [error, setError] = useState('')

  const urlParams = new URLSearchParams(location.search)
  const returnUrl = urlParams.get('returnUrl')
  const email = urlParams.get('email')
  const token = urlParams.get('token')
  const loginLink = returnUrl ? `/auth/login?returnUrl=${encodeURIComponent(returnUrl)}` : '/auth/login'

  const validationAllComponents = async () => {
    let isValid = true

    await _.map(componentRefs.current, async (ref) => {
      if (!(await ref.validateData())) {
        isValid = false
      }
    })

    return isValid
  }

  const comparePasswords = () => {
    if (password !== confirmPassword) {
      return { error: true, message: validationMessage.confirmPasswordInvalid }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const isValid = await validationAllComponents()
      if (!isValid) return

      const { error } = await newPassword({ email, token, password, confirmPassword })
      if (error) {
        setError(error.message)
      } else {
        setIsSubmited(true)
      }
    } catch (error) {
      console.error('An error occurred:', error)
    } finally {
      setLoading(false)
    }
  }

  const formattedError = error?.includes('\n')
    ? error.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))
    : error

  return (
    <>
      {isSubmited ? (
        <>
          <div className='d-flex flex-column flex-center text-center'>
            <div className='mb-14'>
              <a href='index.html' className=''>
                <img alt='Logo' src='/media/logos/logo.png' className='h-40px' />
              </a>
            </div>
            <h1 className='fw-bolder text-gray-900 mb-5'>
              <FormattedMessage id='Login.PasswordChanged' />
            </h1>
            <div className='fs-6 fw-semibold text-gray-500 mb-10'>
              <FormattedMessage id='Login.LoginToContinue' />
            </div>
            <div className='mb-11'>
              <Link to={loginLink} className='btn btn-primary'>
                <FormattedMessage id='Login' />
              </Link>
            </div>
          </div>
        </>
      ) : (
        <form className='form w-100' onSubmit={handleSubmit}>
          <div className='text-center mb-10'>
            <h1 className='text-gray-900 fw-bolder mb-3'>
              <FormattedMessage id='Login.SetupNewPassword' />
            </h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              <FormattedMessage id='Login.HaveYouResetPassword' />{' '}
              <Link to={loginLink} className='link-primary fw-semibold'>
                <FormattedMessage id='Login' />
              </Link>
            </div>
          </div>

          {error && (
            <div className='alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formattedError}</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <FormControl
              ref={(ref) => (componentRefs.current.password = ref)}
              controlType={ControlType.Input}
              label={intl.formatMessage({ id: 'User.ResetPassword.NewPassword' })}
              value={password}
              isRequired={true}
              isHideLabel={true}
              onChange={(e) => setPassword(e.target.value)}
              className={'form-control mb-3 py-3 px-4'}
              type='password'
              validator={comparePasswords}
            />
          </div>
          <div className='fv-row mb-8'>
            <FormControl
              ref={(ref) => (componentRefs.current.confirmPassword = ref)}
              controlType={ControlType.Input}
              label={intl.formatMessage({ id: 'User.ResetPassword.ConfirmNewPassword' })}
              value={confirmPassword}
              isRequired={true}
              isHideLabel={true}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={'form-control mb-3 py-3 px-4'}
              type='password'
              validator={comparePasswords}
            />
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={loading}>
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id='Submit' />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  <FormattedMessage id='Confirm.PleaseWait' />
                  ...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to={loginLink} className='btn btn-light me-4'>
              <FormattedMessage id='Confirm.Button.Cancel' />
            </Link>
          </div>
        </form>
      )}
    </>
  )
}

export default NewPassword
