import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Carousel } from 'react-bootstrap'
import ImageCarousel from '../components/ImageCarousel'
import Product from '../components/Product'
import { getFeaturedProductsByCategoryId } from '../../../redux/actions/eco/featuredProductActions'

import { DEFAULT_VALUE } from '../../../utilities/constant'

const ProductsByCategory = ({ categories }) => {
  const [currentBanner, setCurrentBanner] = useState(null)
  const [index, setIndex] = useState(0)
  const [products, setProducts] = useState([])

  useEffect(() => {
    setCurrentBanner(categories[index])
    if (categories[index]) {
      fetchProductsByCategoryId(categories[index].id)
    }
  }, [index, categories])

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  const handleInterval = () => {
    setCurrentBanner(categories[index])
  }

  const fetchProductsByCategoryId = async (categoryId) => {
    const { result, error } = await getFeaturedProductsByCategoryId(categoryId, 0, 20)
    if (result) {
      setProducts(result.items)
    } else {
      console.error('fetchProductsByCategoryId:', error)
    }
  }

  const responsive = (baseSlidesToShow) => {
    return [
      { breakpoint: 1500, settings: { slidesToShow: baseSlidesToShow - 1 } },
      { breakpoint: 650, settings: { slidesToShow: baseSlidesToShow - 2 } },
      { breakpoint: 400, settings: { slidesToShow: baseSlidesToShow - 3 } },
    ]
  }

  return (
    <section id='home-product-by-category'>
      <div className='card mb-8 mb-xl-14'>
        <div className='section-title d-flex align-items-center justify-content-between'>
          <h3>{currentBanner?.categoryName}</h3>
          <Link to={`/categories/${currentBanner?.id}`} className='fw-bold text-primary'>
            <FormattedMessage id='ViewAll' />
            <i className='fa fa-angle-right ms-2 text-primary' aria-hidden='true'></i>
          </Link>
        </div>
        <div className='d-flex justify-content-between card-body p-0'>
          <div className='col-3 category-banner'>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              onSlide={handleInterval}
              nextIcon={<span aria-hidden='true' className='carousel-control-next-icon changed' />}
            >
              {categories.map((item) => (
                <Carousel.Item key={item.id} interval={300 * DEFAULT_VALUE.THOUDSAND_MILLISECOND}>
                  <img className='d-block w-100 rounded' src={DEFAULT_VALUE.BASE_URL + item.banner} alt='First slide' />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className='col-9 ps-8'>
            <ImageCarousel Component={Product} products={products} columns={4} responsive={responsive} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductsByCategory
