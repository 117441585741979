import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class FeaturedProductAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.FEATURED_PRODUCT)
    this.adapter = this.initAdapter(conf)
  }

  async getFeaturedProducts() {
    return await this.adapter.getAsync(`getFeaturedProducts`)
  }

  async getFeaturedProductsByCategoryId(categoryId, pageIndex, pageSize) {
    return await this.adapter.getAsync(`getFeaturedProductsByCategoryId?categoryId=${categoryId}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
  }

  async getFeaturedProductsByType(payload) {
    return await this.adapter.getAsync('getFeaturedProductsByType', { payload })
  }
}

export default FeaturedProductAPI
