import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import RatingStar from './RatingStar'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import { useIntl } from 'react-intl'
import { FeaturedProductTypeEnum, getKeyByValue } from '../../../utilities/enums'
import NumberBox from '../componentsShared/NumberBox'

const Product = ({ products }) => {
  const intl = useIntl()
  const [hoveredIndex, setHoveredIndex] = useState(null)

  const handleProductHover = (index) => {
    setHoveredIndex(index)
  }

  return products?.map((item, index) => {
    return (
      <div key={`${item.id}-${item.productId}`} className={`product-item ${hoveredIndex === index ? 'hovered' : ''}`}>
        <div className='card overflow-auto overflow-hidden rounded product-item-content'>
          <div className='card-body p-2'>
            <div className='mb-2 position-relative t-0'>
              <Link
                className={`ratio ratio-1x1`}
                to={`/product/${item.productId}`}
                onMouseEnter={() => handleProductHover(index)}
                onMouseLeave={handleProductHover}
              >
                <img src={DEFAULT_VALUE.BASE_URL + item.imageUrl} loading='lazy' className='object-fit-contain rounded-2' alt='' />
              </Link>
            </div>

            <div className='top-0 start-0 position-absolute'>
              <span
                className={`product-badges rounded-bottom-right
                ${item.type ? getKeyByValue(FeaturedProductTypeEnum, item.type).toLocaleLowerCase() : ''}`}
              >
                {item.type ? intl.formatMessage({ id: `Enum.${getKeyByValue(FeaturedProductTypeEnum, item.type)}` }) : ''}
              </span>
            </div>

            <div style={{ minHeight: '130px' }}>
              <div className='product-item-text overflow-hidden mb-1 text-dark'>
                <Link to={`/product/${item.productId}`} onMouseEnter={() => handleProductHover(index)} onMouseLeave={handleProductHover}>
                  <span className='fw-bold'>{item.productName}</span>
                </Link>
              </div>

              <div className='product-item-text d-flex align-items-center justify-content-between mb-1'>
                <RatingStar rating={item.rating} />
                <div className='text-truncate text-muted fs-7'>
                  {intl.formatMessage({ id: 'Sold' })}: <NumberBox type='text' value={item.quantityProductSold ?? 0} className='' />
                </div>
              </div>

              <div className='product-item-text mb-1'>
                <span className='text-primary fs-3 fw-bold'>
                  <NumberBox type='text' value={item.basePrice} className='' suffix='đ' />
                </span>
              </div>

              <div className='product-item-text text-muted fs-7 mb-1'>
                {intl.formatMessage({ id: 'SalesCommission' })}: <NumberBox type='text' value={item.commissionMoney} className='' suffix='đ' />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })
}
export default Product
