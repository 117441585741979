import React from 'react';
import { ToastContainer, toast as displayToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = () => {
  return <ToastContainer />
};

const showToast = (message, type, options) => {
  const defaultOptions = {
    position: 'top-right',
    autoClose: 3000,
    type: type
    // Other options...
  }
  options = options ? options : defaultOptions
  displayToast(message, options)
}

const TOAST_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success'
}

export { showToast, Toastify, TOAST_TYPES}