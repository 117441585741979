import { Category as CategoryAPI } from '../../../services'

const getCategories = async () => {
  return await CategoryAPI.getCategories()
}

const getById = async (payload) => {
  return await CategoryAPI.getById(payload)
}

const getAll = async (categoryId) => {
  return await CategoryAPI.getAll({ categoryId })
}

export { getCategories, getById, getAll }
