import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getDistrictsByProvinceId, getProvinces, getWardsByDistrictId } from '../../../redux/actions/gnr/provinceActions'
import { validationAllComponents } from '../../../utilities/shareFunction'
import Button from '../componentsShared/Button'
import { ControlType, FormControl } from '../componentsShared/FormControl'
import Modal from '../componentsShared/Modal'

const CreateAddressModal = ({ editAddress, closeModal, setAddress }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  const [provinceId, setProvinceId] = useState(editAddress?.provinceId ?? null)
  const [districtId, setDistrictId] = useState(editAddress?.districtId ?? null)
  const [wardId, setWardId] = useState(editAddress?.wardId ?? null)
  const [street, setStreet] = useState(editAddress?.street ?? null)

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvinces()
      if (result) {
        setProvinces(result)
      }
    }
    fetchProvinces()
  }, [])

  useEffect(() => {
    const fetchDistricts = async () => {
      const { result } = await getDistrictsByProvinceId({ provinceId, pageIndex: null, pageSize: null })
      if (result?.items) {
        setDistricts(result.items)
        if (!result.items.some((x) => x.value === districtId)) {
          componentRefs.current.wardId.clearValue()
          setWards([])
        }
      }
    }
    fetchDistricts()
  }, [provinceId])

  useEffect(() => {
    const fetchWards = async () => {
      const { result } = await getWardsByDistrictId({ districtId, pageIndex: null, pageSize: null })
      if (result?.items) {
        setWards(result.items)
        if (!result.items.some((x) => x.value === wardId)) {
          componentRefs.current.wardId.clearValue()
        }
      }
    }
    fetchWards()
  }, [districtId])

  const handleSave = async (e) => {
    e.preventDefault()
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const provinceName = _.find(provinces, { value: provinceId })?.name || ''
    const districtName = _.find(districts, { value: districtId })?.name || ''
    const wardName = _.find(wards, { value: wardId })?.name || ''

    const addressData = {
      provinceName,
      provinceId,
      districtName,
      districtId,
      wardName,
      wardId,
      street,
    }
    setAddress(addressData)
    closeModal(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: 'AddNewAddress' })} closeModal={closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_address_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_address_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_address_header'
          data-kt-scroll-wrappers='#kt_modal_add_address_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.provinceId = ref)}
                  tabIndex={3}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'Province' })}
                  options={provinces}
                  value={provinceId}
                  isRequired={true}
                  onChange={(e) => setProvinceId(e.value)}
                />
              </div>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.districtId = ref)}
                  tabIndex={4}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'District' })}
                  options={districts}
                  value={districtId}
                  isRequired={true}
                  onChange={(e) => setDistrictId(e.value)}
                />
              </div>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.wardId = ref)}
                  tabIndex={5}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'Ward' })}
                  options={wards}
                  value={wardId}
                  isRequired={true}
                  onChange={(e) => setWardId(e.value)}
                />
              </div>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.street = ref)}
                  tabIndex={6}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'HouseNumber' })}
                  value={street}
                  isRequired={true}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4} />
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={5} />
        </div>
      </form>
    </Modal>
  )
}

export default CreateAddressModal
