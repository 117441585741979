import { Auth } from '../../components/auth/Auth'
import { DEFAULT_VALUE } from '../../utilities/constant'
import { ADD_TO_CART, DECREASE_CART, REMOVE_ALL_CART, REMOVE_FROM_CART, INCREASE_CART } from '../actions/eco/shoppingCartActions'

const initialState = {
  shoppingCartDetails: [],
  count: 0,
  totalAmount: 0,
  userId: Auth.getUserId(),
}

const shoppingCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return addToCart(state, action.product)
    case INCREASE_CART:
      return increaseCart(state, action.cartDetailId)
    case DECREASE_CART:
      return decreaseCart(state, action.cartDetailId)
    case REMOVE_FROM_CART:
      return removeFromCart(state, action.cartDetailId)
    case REMOVE_ALL_CART:
      return reCalculateCart([])
    default:
      return Auth.getUserShoppingCart()
  }
}

const addToCart = (state, product) => {
  const cartDetails = state.shoppingCartDetails
  const existingItem = cartDetails.find((item) => item.id === product.id)
  let updatedCart
  if (existingItem) {
    updatedCart = cartDetails.map((item) => (item.id === product.id ? { ...item, quantity: item.quantity + product.quantity } : item))
  } else {
    updatedCart = [...cartDetails, { ...product, userId: Auth.getUserId() }]
  }
  return reCalculateCart(updatedCart)
}

const increaseCart = (state, cartDetailId) => {
  const cartDetails = state.shoppingCartDetails
  const existingItem = cartDetails.find((item) => item.id === cartDetailId)
  let updatedCart
  if (existingItem) {
    updatedCart = cartDetails.map((item) => (item.id === cartDetailId ? { ...item, quantity: item.quantity + 1 } : item))
  } else {
    updatedCart = cartDetails
  }
  return reCalculateCart(updatedCart)
}

const decreaseCart = (state, cartDetailId) => {
  const cartDetails = state.shoppingCartDetails
  const existingItem = cartDetails.find((item) => item.id === cartDetailId)
  let updatedCart
  if (existingItem) {
    updatedCart = cartDetails
      .map((item) => (item.id === cartDetailId ? { ...item, quantity: Math.max(item.quantity - 1, 0) } : item))
      .filter((item) => item.quantity > 0)
  } else {
    updatedCart = cartDetails
  }
  return reCalculateCart(updatedCart)
}

const removeFromCart = (state, cartDetailId) => {
  const updatedCart = state.shoppingCartDetails.filter((item) => item.id !== cartDetailId)
  return reCalculateCart(updatedCart)
}

const reCalculateCart = (cart) => {
  const totalAmount = cart.reduce((total, { unitPrice, quantity }) => total + unitPrice * quantity, 0)
  const count = cart.length
  const newCart = { shoppingCartDetails: cart, count, totalAmount, userId: Auth.getUserId() }
  Auth.saveUserShoppingCart(newCart)
  return newCart
}

export default shoppingCartReducer
