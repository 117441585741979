import React, { useState, useEffect, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import PageTitle from '../components/PageTitle'
import '../../../assets/sass/supplier.scss'
import { getAll } from '../../../redux/actions/eco/categoryActions'
import { searchSuppliers } from '../../../redux/actions/eco/supplierActions'
import CategoryFilter from '../components/CategoryFilter'
import RatingFilter from '../components/RatingFilter'
import NewsBanner from '../components/NewsBanner'
import Supplier from '../components/Supplier'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import PageNumber from '../components/PageNumber'
import { ControlType, FormControl } from '../componentsShared/FormControl'

const ListSupplier = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [timer, setTimer] = useState(null)

  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize] = useState(20)
  const [totalPages, setTotalPages] = useState(0)
  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  const [categoiesSearch, setCategoriesSearch] = useState([])
  const [ratingSearch, setRatingSearch] = useState(null)
  const [nameSearch, setNameSearch] = useState(null)

  useEffect(() => {
    fetchCategories()
    fetchsuppliers()
    clearTimeout(timer)
  }, [])

  useEffect(() => {
    fetchsuppliers()
  }, [categoiesSearch, ratingSearch])

  const fetchCategories = async () => {
    try {
      const { result } = await getAll()
      if (result) {
        setCategories(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchsuppliers = async (search) => {
    try {
      const { result } = await searchSuppliers({
        pageIndex: currentPage,
        pageSize: pageSize,
        search: search ?? null,
        categoryIds: categoiesSearch ?? null,
        rating: ratingSearch ?? null,
      })
      if (result) {
        setSuppliers(result.items)
        setTotalPages(result.totalPageCount)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeCategory = (checked, id) => {
    var currentCategories = [...categoiesSearch]

    if (checked) {
      currentCategories.push(id)
    } else {
      currentCategories = currentCategories.filter((x) => x !== id)
    }

    setCategoriesSearch(currentCategories)
  }

  const handleInputChange = (e) => {
    setNameSearch(e.target.value)
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      fetchsuppliers(e.target.value)
    }, 1000)

    setTimer(newTimer)
  }

  return (
    <div className='list-supplier pb-8'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Suppliers' })}</h1>
            <PageTitle />
          </div>
        </div>
      </div>

      <div className='d-flex pt-8'>
        <div className='category-filter-panel col-3 pe-8'>
          <div className='mb-8'>
            <CategoryFilter categories={categories} handleChangeCategory={handleChangeCategory} />
          </div>

          <div className='mb-8'>
            <RatingFilter rating={ratingSearch} onChange={setRatingSearch} />
          </div>

          <div className='mb-8'>
            <NewsBanner news={[]} />
          </div>
        </div>

        <div className='item-result col-9'>
          <div className='sort-bar mb-4'>
            <span>
              <FormattedMessage id='Search' />
            </span>
            <div className='sort-by-options'>
              <div className='ms-2 col-sm-10 col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.priceSort = ref)}
                  tabIndex={3}
                  controlType={ControlType.Input}
                  value={nameSearch}
                  onChange={handleInputChange}
                  isHideLabel={true}
                  label={intl.formatMessage({ id: 'SupplierName' })}
                />
              </div>
            </div>

            <div className='mini-page-controller'>
              <div className='state'>
                <span className='current'>{currentPage}</span>/<span className='total'>{totalPages}</span>
              </div>
              <a
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`prev-btn btn button-outline ms-5 ${currentPage > 1 && currentPage <= totalPages ? '' : 'disabled'}`}
              >
                <i className='fa fa-angle-left' aria-hidden='true'></i>
              </a>

              <a onClick={() => setCurrentPage(currentPage + 1)} className={`next-btn btn button-outline ${currentPage < totalPages ? '' : 'disabled'}`}>
                <i className='fa fa-angle-right' aria-hidden='true'></i>
              </a>
            </div>
          </div>

          <div className='row row-cols-2'>
            {suppliers?.map((item) => (
              <Supplier supplier={item} key={item?.id} />
            ))}
          </div>

          <div className='p-4'>
            <PageNumber currentPage={currentPage} totalPages={totalPages} className='pagination-lg' onPageChange={setCurrentPage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListSupplier
