import { useEffect, useState } from 'react'
import moment from 'moment'

const useCountdownTimer = (toDate) => {
  const calculateCountdown = () => {
    const duration = moment.duration(moment(toDate).diff(moment()))
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    return {
      days,
      hours,
      minutes,
      seconds,
    }
  }

  const [countdown, setCountdown] = useState(calculateCountdown())

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateCountdown())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return countdown
}

export default useCountdownTimer
