import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class SupplierAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.SUPPLIER)
    this.adapter = this.initAdapter(conf)
  }

  async getSupplierById(supplierId) {
    return await this.adapter.getAsync(`getById?id=${supplierId}`)
  }

  async getProductSupplierBySupplierId(supplierId) {
    return await this.adapter.getAsync(`getProductSupplierBySupplierId?supplierId=${supplierId}`)
  }

  async getSameProductsBySupplierId(supplierId) {
    return await this.adapter.getAsync(`getSameProductsBySupplierId?supplierId=${supplierId}`)
  }
  
  async getSuppliers(payload) {
    return await this.adapter.postAsync('getSuppliers', { payload })
  }

  async searchSuppliers(payload) {
    return await this.adapter.postAsync('searchSuppliers', { payload })
  }

}

export default SupplierAPI
