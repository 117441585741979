import React from 'react'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../helpers'
import '../../../assets/sass/productDetail.scss'
import ProductReview from './ProductReview'
import RatingStar from '../components/RatingStar'
import NumberBox from '../componentsShared/NumberBox'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import CKEditorDisplay from '../componentsShared/CKEditorDisplay'
import { useIntl } from 'react-intl'

const ProductSupplier = ({ supplier, product }) => {
  const intl = useIntl()
  return (
    <>
      <section className='card d-flex flex-row justify-content-center p-4 mt-4 border me-8'>
        <div className='me-4'>
          <Link to={`/supplier/${supplier?.id}`} className='rounded-pill border d-inline-block position-relative'>
            <img width='70' height='70' src={toAbsoluteUrl('/media/jpg/shop.jpg')} loading='lazy' className='object-fit-contain rounded-pill' alt='' />
          </Link>
        </div>
        <div className='me-4 border-end w-50'>
          <div className='fw-bold text-uppercase'>{supplier?.supplierName}</div>
          <div className='row align-items-center'>
            <RatingStar rating={supplier?.rating} />
            <div className='text-muted my-1'>
              <SVG src={toAbsoluteUrl('/media/svg/icon/location.svg')} />
              {supplier?.businessAddress}
            </div>
          </div>
        </div>
        <div className='col me-4 pt-6'>
          <div className='row'>
            <div className='col-6'>
              <span className='text-primary fw-bold'>
                <NumberBox value={supplier?.quantityProduct} className='' />
              </span>
              <span className='text-muted text-lowercase'> {intl.formatMessage({ id: 'Product' })}</span>
            </div>
            <div className='col-6'>
              <span className='text-primary fw-bold'>
                <NumberBox value={supplier?.quantityProductSold} className='' />
              </span>
              <span className='text-muted text-lowercase'> {intl.formatMessage({ id: 'Sold' })}</span>
            </div>

            <div className='col-6'>
              <span className='text-primary fw-bold'>
                <NumberBox value={supplier?.quantitySalesSeller} className='' />{' '}
              </span>
              <span className='text-muted text-lowercase'> {intl.formatMessage({ id: 'SellerSell' })}</span>
            </div>
            <div className='col-6'>
              <span className='text-primary fw-bold'>
                <NumberBox value={supplier?.quantitySellerFollowed} className='' />{' '}
              </span>
              <span className='text-muted text-lowercase'> {intl.formatMessage({ id: 'SellerFollow' })}</span>
            </div>
          </div>
        </div>
      </section>

      <section className='card p-4 mt-4 border me-8'>
        <div className='tab-style-1'>
          <ul className='nav nav-tabs text-uppercase'>
            <li className='nav-item'>
              <a className='nav-link active' id='Description-tab' data-bs-toggle='tab' href='#Description'>
                {intl.formatMessage({ id: 'ProductInformation' })}
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' id='Reviews-tab' data-bs-toggle='tab' href='#Reviews'>
                {intl.formatMessage({ id: 'RatingProduct' })} ({product?.quantityProductReviewed})
              </a>
            </li>
          </ul>

          <div className='tab-content mt-10'>
            <div className='tab-pane fade active show' id='Description'>
              <div className='d-flex'>
                <div className='col-4 px-4'>
                  <div className='bg-light p-4 rounded'>
                    <div className='fw-bold fw-bolder text-uppercase'>{intl.formatMessage({ id: 'Product.PriceInformation' })}</div>
                  </div>
                  <div className='mt-4'>
                    <div className='list-group list-group-flush'>
                      <div className='px-2 list-group-item'>
                        <div className='justify-content-between row'>
                          <div className='col'>
                            <p className='fw-bold'>
                              {intl.formatMessage({ id: 'Product.YourSellingPrice' })}
                              <i className='fa fa-info-circle ms-1 cursor-pointer' aria-hidden='true'></i>
                            </p>
                          </div>
                          <div className='fw-bold col-auto'>
                            <NumberBox value={product?.basePrice} className='' suffix={DEFAULT_VALUE.VND} />
                          </div>
                        </div>
                      </div>

                      {/* <div className='px-2 list-group-item'>
                        <div className='justify-content-between row'>
                          <div className='col'>
                            <p>
                              Giá bán thị trường
                              <i className='fa fa-info-circle ms-1 cursor-pointer' aria-hidden='true'></i>
                            </p>
                          </div>
                          <div className='fw-bold col-auto'>295.000đ</div>
                        </div>
                      </div>
                      <div className='px-2 list-group-item'>
                        <div className='justify-content-between row'>
                          <div className='col'>
                            <p>
                              Giá nhà cung cấp
                              <i className='fa fa-info-circle ms-1 cursor-pointer' aria-hidden='true'></i>
                            </p>
                          </div>
                          <div className='fw-bold col-auto'>249.865đ</div>
                        </div>
                      </div> */}
                      <div className='px-2 list-group-item'>
                        <div className='justify-content-between row'>
                          <div className='col'>
                            <p>
                              {intl.formatMessage({ id: 'Product.CommissionMoney' })}
                              <i className='fa fa-info-circle ms-1 cursor-pointer' aria-hidden='true'></i>
                            </p>
                          </div>
                          <div className='fw-bold col-auto text-primary'>
                            <NumberBox value={product?.commissionMoney} className='' suffix={DEFAULT_VALUE.VND} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-4 px-4 border-start'>
                  <div className='d-flex justify-content-between bg-light p-4 rounded'>
                    <div className='fw-bold fw-bolder text-uppercase'>{intl.formatMessage({ id: 'Product.Description' })}</div>
                    <div className='fw-bold text-primary cursor-pointer'>{intl.formatMessage({ id: 'Product.CopyDescription' })}</div>
                  </div>
                  <div className='mt-4'>
                    <CKEditorDisplay content={product?.description} />
                  </div>
                </div>

                <div className='col-4 px-4 border-start'>
                  <div className='d-flex justify-content-between bg-light p-4 rounded'>
                    <div className='fw-bold fw-bolder text-uppercase'>{intl.formatMessage({ id: 'Product.Instruction' })}</div>
                  </div>
                  <div className='mt-4'>
                    <CKEditorDisplay content={product?.usage} />
                  </div>
                </div>
              </div>
            </div>

            <div className='tab-pane fade' id='Reviews'>
              <ProductReview productId={product?.id} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductSupplier
