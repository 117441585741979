import { useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import SystemVoucherModal from './SystemVoucherModal'
import NumberBox from '../componentsShared/NumberBox'
import { DiscountTypeEnum, CouponTypeEnum } from '../../../utilities/enums'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../../utilities/constant'

const CheckoutCoupon = ({ coupons, voucherSelected, onChangeVoucher, couponType }) => {
  const intl = useIntl()
  const [isOpenSystemVoucher, setIsOpenSystemVoucher] = useState(false)
  const title =
    couponType === CouponTypeEnum.Ship ? 'Coupon.FreeShip' : couponType === CouponTypeEnum.Supplier ? 'Checkout.ShopVoucher' : 'Checkout.GreenVoucher'
  return (
    <div className='checkout-coupons'>
      <div className='checkout-coupon-wrap'>
        <div className='d-flex justify-content-between'>
          <div className='p-1'>
            <div className='checkout-voucher-icon'>
              <img src={toAbsoluteUrl('../media/svg/icon/coupon.svg')} alt='' className='me-2' />
              <span>{intl.formatMessage({ id: title })}</span>
            </div>
          </div>
          <div className='p-1'>
            {voucherSelected && (
              <span className='color-brand'>
                {voucherSelected.couponName + ' - '}
                &nbsp;(
                <NumberBox
                  value={voucherSelected.discountValue}
                  className=''
                  suffix={voucherSelected.discountType === DiscountTypeEnum.Percentage ? DEFAULT_VALUE.PERCENT : DEFAULT_VALUE.VND}
                />
                )
              </span>
            )}
          </div>
          <div className='p-1'>
            <button className='checkout-voucher-button-text' onClick={() => setIsOpenSystemVoucher(true)}>
              <span>{intl.formatMessage({ id: 'Checkout.ChooseVoucher' })}</span>
            </button>
          </div>
        </div>
      </div>

      {isOpenSystemVoucher && (
        <SystemVoucherModal
          closeModal={setIsOpenSystemVoucher}
          voucherSelected={voucherSelected}
          coupons={coupons}
          onChangeVoucher={onChangeVoucher}
          couponType={couponType}
        />
      )}
    </div>
  )
}

export default CheckoutCoupon
