import { DEFAULT_VALUE } from './constant'
import _ from 'lodash'

const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

const deepEqual = (valueA, valueB) => _.isEqual(_.isEmpty(valueA) ? null : valueA, _.isEmpty(valueB) ? null : valueB)

const isValidEmail = (email) => {
  const emailRegex = DEFAULT_VALUE.REGEX_EMAIL
  return emailRegex.test(email)
}

function formatString(template, ...args) {
  return template.replace(/{(\d+)}/g, (match, index) => {
    const argIndex = parseInt(index, 10)
    return args[argIndex] !== undefined ? args[argIndex] : match
  })
}

function formatDateTime(date) {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  if (isSameDay(date, today)) {
    return `Today at ${formatTime(date)}`
  } else if (isSameDay(date, yesterday)) {
    return `Yesterday at ${formatTime(date)}`
  } else {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${day}/${month}/${year} at ${formatTime(date)}`
  }
}

function formatTime(date) {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  return `${formattedHours}:${formattedMinutes} ${ampm}`
}

function isSameDay(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
}

const validationAllComponents = async (componentRefs) => {
  let isValid = true
  await _.map(componentRefs.current, async (ref) => {
    if (!(await ref.validateData())) {
      isValid = false
    }
  })
  return isValid
}

export const addDays = (date, days) => {
  var result = new Date()
  result.setDate(date.getDate() + days)
  return result
}

export { htmlDecode, deepEqual, isValidEmail, formatString, formatDateTime, validationAllComponents }
