export const CONFIG_TYPE = {
  USER: 'user',
  ACCOUNT: 'account',
  BANNER: 'banner',
  CATEGORY: 'category',
  FEATURED_PRODUCT: 'featuredProduct',
  DANHMUC: 'danhMuc',
  PROMOTION: 'promotion',
  SHOPPING_CART: 'shoppingCart',
  PRODUCT: 'product',
  SUPPLIER: 'supplier',
  PRODUCT_CATEGORY: 'productCategory',
  PROVINCE: 'province',
  SUB_CATEGORY: 'subCategory',
  COUPON: 'coupon',
  ORDER: 'order',
  AFFILIATE: 'affiliate',
  AFFILIATE_CUSTOMER_ADDRESS: 'affiliateCustomerAddress',
  AFFILIATE_CUSTOMER_RELATION: 'affiliateCustomerRelation',
  AFFILIATE_COMMISSION_MONEY: 'affiliateCommissionMoney'
}

export const PRAGMA = 'pragma'
export const REMOVE_PRAGMA = 'remove-pragma'
export const CACHE_CONTROL_FIELD = 'cache-control'
