import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import NumberBox from '../componentsShared/NumberBox'
import ShippingUnitModal from './ShippingUnitModal'
import { CouponTypeEnum, DiscountTypeEnum, ShippingMethodEnum, getEnums } from '../../../utilities/enums'
import { useIntl } from 'react-intl'
import { addDays } from '../../../utilities/shareFunction'
import CheckoutCoupon from './CheckoutCoupon'

const CheckoutBody = ({ productOrders, supplier, onChangeSupplier, coupons }) => {
  const intl = useIntl()
  const today = new Date()

  const [isOpenShippingUnit, setIsOpenShippingUnit] = useState(false)
  const [isOpenShopVoucher, setIsOpenShopVoucher] = useState(false)
  const [message, setMessage] = useState(null)
  const [voucherSelected, setVoucherSelected] = useState(null)
  const [discountTypes] = useState(() => getEnums(DiscountTypeEnum, intl))

  const shippingMethods = Object.keys(ShippingMethodEnum).map((key, index) => ({
    value: ShippingMethodEnum[key],
    name: intl.formatMessage({ id: `Enum.${key}` }),
    receivedDay: index + 4,
    expectedDeliveryDate: addDays(today, index + 4),
    shippingAmount: 8300 * (index + 4),
  }))
  const [shippingMethod, setShippingMethod] = useState(shippingMethods[0])

  useEffect(() => {
    if (shippingMethod) {
      supplier.shippingMethod = shippingMethod.value
      supplier.shippingAmount = shippingMethod.shippingAmount
      supplier.expectedDeliveryDate = shippingMethod.expectedDeliveryDate
    }
    if (message) {
      supplier.message = message
    }
    if (voucherSelected) {
      supplier.supplierCouponId = voucherSelected.id
      supplier.discountType = voucherSelected.discountType
      supplier.discountValue = voucherSelected.discountValue
      supplier.supplierCouponAmount =
        voucherSelected.discountType === DiscountTypeEnum.FixedPrice
          ? voucherSelected.discountValue
          : (supplier.totalAmount * voucherSelected.discountValue) / 100
    }
    onChangeSupplier(supplier)
  }, [shippingMethod, message, voucherSelected])

  return (
    <div className='checkout-body-box'>
      <div className='checkout-table-row'>
        {productOrders &&
          productOrders.map((d) => (
            <div className='checkout-product-wrap' key={d.id}>
              <div className='checkout-product-flex'>
                <div className='checkout-content-flexstart checkout-flex-4'>
                  <img
                    className='checkout-product-image'
                    alt='product'
                    src={DEFAULT_VALUE.BASE_URL + DEFAULT_VALUE.SLASH + d.productImage}
                    width={40}
                    height={40}
                  />
                  <span className='checkout-product-content'>
                    <span className='checkout-product-content-title'>{d.productName}</span>
                    <div className='checkout-product-content-return'>
                      <span className='checkout-product-content-boder checkout-color-success'>{intl.formatMessage({ id: 'Checkout.FreeExchange' })}</span>
                    </div>
                  </span>
                </div>
                <div className='checkout-content-flexstart checkout-flex-2' />
                <div className='checkout-content-flexstart'>
                  <NumberBox value={d.unitPrice} className='' suffix={DEFAULT_VALUE.VND} />
                </div>
                <div className='checkout-content-flexstart'>{d.quantity}</div>
                <div className='checkout-content-flexstart checkout-font-weight-500'>
                  <NumberBox value={d.unitPrice * d.quantity} className='' suffix={DEFAULT_VALUE.VND} />
                </div>
              </div>
            </div>
          ))}

          <CheckoutCoupon
            coupons={coupons.filter(
              (d) =>
                (d.minAmount === null || d.minAmount <= supplier.totalAmount) && d.couponType === CouponTypeEnum.Supplier && d.supplierId === supplier.supplierId
            )}
            voucherSelected={voucherSelected}
            onChangeVoucher={setVoucherSelected}
            couponType={CouponTypeEnum.Supplier}
          />
      </div>
      <div className='checkout-row-support-wrap'>
        <div className='checkout-row-support-flex'>
          <div className='checkout-flex-table checkout-flex-4'>
            <div className='checkout-support-note'>
              <span>{intl.formatMessage({ id: 'Checkout.MessageForShop' })}:</span>
              <div className='checkout-flex-1'>
                <div className='checkout-support-input-wrap'>
                  <div className='checkout-support-input-flex'>
                    <input
                      className='checkout-support-input'
                      type='text'
                      placeholder={`${intl.formatMessage({ id: 'Checkout.NoteToSeller' })}...`}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div />
                </div>
              </div>
            </div>
          </div>
          <div className='checkout-flex-table checkout-table-grid'>
            <div className='checkout-driver-unit'>{intl.formatMessage({ id: 'Checkout.ShippingUnit' })}:</div>
            {shippingMethod && (
              <React.Fragment key={shippingMethod.value}>
                <div className='checkout-driver-fast'>{shippingMethod.name}</div>
                <div className='checkout-grid-column' />
                <div className='checkout-date-receiver'>
                  {intl.formatMessage({ id: 'Checkout.ReceivedOnDate' })} {today.getUTCDate() + shippingMethod.receivedDay}{' '}
                  {intl.formatMessage({ id: 'Month' })} {today.getMonth() + 1} - {today.getUTCDate() + shippingMethod.receivedDay + 2}{' '}
                  {intl.formatMessage({ id: 'Month' })} {today.getMonth() + 1}
                </div>
              </React.Fragment>
            )}
            <button className='checkout-change-driver' onClick={() => setIsOpenShippingUnit(true)}>
              Thay đổi
            </button>
            <div className='checkout-total-amount-driver'>
              <span>
                <NumberBox value={shippingMethod.shippingAmount} className='' suffix={DEFAULT_VALUE.VND} />
              </span>
            </div>
            <div className='checkout-separation-grid' />
            <div className='checkout-checked-wrap'>
              <div className='checkout-checked-text checkout-color-inherit'>{intl.formatMessage({ id: 'Checkout.CoCheck' })}.</div>
              <img src={toAbsoluteUrl('../media/svg/icon/note.svg')} alt='' className='checkout-svg-icon icon-voucher-line me-2 cursor-pointer' />
            </div>
          </div>
        </div>
        <div className='checkout-row-support-flex' />
      </div>
      <div className='checkout-supplier-totalamount'>
        <div className='checkout-supplier-total-flex'>
          <h3 className='checkout-text-center checkout-text-gray'>
            <div>
              {intl.formatMessage({ id: 'Checkout.TotalAmountOf' })} ({productOrders.length} {intl.formatMessage({ id: 'Checkout.Product' })}
              ):
            </div>
          </h3>
          <div className='checkout-text-center checkout-text-flex-end checkout-font-bold checkout-total-success'>
            <NumberBox value={supplier.totalAmount} className='' suffix={DEFAULT_VALUE.VND} />
          </div>
        </div>
      </div>

      {isOpenShippingUnit && (
        <ShippingUnitModal
          closeModal={setIsOpenShippingUnit}
          shippingMethodSelected={shippingMethod}
          shippingMethods={shippingMethods}
          onChangeShippingMethod={setShippingMethod}
        />
      )}
    </div>
  )
}

export default CheckoutBody
