import { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { getCategories } from '../../../../redux/actions/eco/categoryActions'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { checkIsActive } from '../../../../helpers'

const Search = () => {
  const intl = useIntl()
  const { searchValue, categoryId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const searchCategoryRef = useRef(null)

  const [searchTerm, setSearchTerm] = useState('')
  const [categoryTerm, setCategoryTerm] = useState('0')
  const [categories, setCategories] = useState([])
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)

  useEffect(() => {
    setSearchTerm(searchValue ?? '')
    setCategoryTerm(categoryId ?? '0')
  }, [searchValue, categoryId])

  useEffect(() => {
    fetchCategories()
  }, [])

  const fetchCategories = async () => {
    try {
      const { result } = await getCategories()
      if (result) {
        setCategories(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchCategoryRef.current && !searchCategoryRef.current.contains(event.target) && isOpenDropdown) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpenDropdown])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      navigate(`/search/${categoryTerm}/${event.target.value}`)
    }
  }

  const changeCategory = (categoryTerm) => {
    setCategoryTerm(categoryTerm)

    if (checkIsActive(location.pathname, `/search`)) {
      navigate(`/search/${categoryTerm}/${searchTerm || ''}`)
    }
  }

  return (
    <div className='header-search'>
      <form>
        <div className='main-categories-wrap'>
          <div
            className='select-content d-flex justify-content-between align-items-center'
            onClick={() => setIsOpenDropdown(!isOpenDropdown)}
            ref={searchCategoryRef}
          >
            <span className='mx-3'>
              {categoryTerm !== '0' ? categories.find((x) => x.id === categoryTerm)?.categoryName : intl.formatMessage({ id: 'AllCategories' })}
            </span>
            <i className='fa fa-angle-down text-dark' aria-hidden='true'></i>
          </div>
          <div className={`categories-dropdown-wrap categories-dropdown-large ${isOpenDropdown ? 'open' : ''}`}>
            <ul>
              <li onClick={() => changeCategory('0')}>
                <a>
                  <FormattedMessage id='AllCategories' />
                </a>
              </li>
              {categories.map((category) => {
                return (
                  <li onClick={() => changeCategory(category.id)} key={`categories-search-${category.id}`}>
                    <a>
                      <img src={DEFAULT_VALUE.BASE_URL + category.logo} alt='' />
                      {category.categoryName}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className='border-start border-primary my-4'></div>
        <input
          type='text'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={intl.formatMessage({ id: 'SearchForItems' })}
          onKeyDown={handleKeyDown}
        />
      </form>
    </div>
  )
}

export { Search }
