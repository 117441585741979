import React from 'react'
import CustomeCheckbox from '../componentsShared/CustomeCheckbox'
import { FormattedMessage } from 'react-intl'

const CategoryFilter = ({ categoryId, categories, handleChangeCategory, handleChangeSubCategory }) => {
  return (
    <div className='primary-sidebar'>
      <div className='sidebar-widget'>
        <h5 className='section-title style-1 mb-4'>
          <FormattedMessage id='AccordingToCategory' />
        </h5>

        <div className='list-group my-4'>
          <div className='list-group-item border-0 p-0'>
            {(categoryId ?? '0') === '0'
              ? categories?.map((item) => {
                  return (
                    <CustomeCheckbox
                      key={item.id}
                      className='p-4 my-2 border rounded hover'
                      id={`sub-${item.id}`}
                      name='filter-subcategory'
                      text={item.categoryName}
                      value={false}
                      onChange={(e) => handleChangeCategory(e.target.checked, item.id)}
                    />
                  )
                })
              : categories[0]?.subCategories.map((item) => {
                  return (
                    <CustomeCheckbox
                      key={item.id}
                      className='p-4 my-2 border rounded hover'
                      id={`sub-${item.id}`}
                      name='filter-subcategory'
                      text={item.subCategoryName}
                      value={false}
                      onChange={(e) => handleChangeSubCategory(e.target.checked, item.id)}
                    />
                  )
                })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CategoryFilter
