import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class OrderAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.ORDER)
    this.adapter = this.initAdapter(conf)
  }

  async saveOrder(payload) {
    return await this.adapter.postAsync('saveOrder', { payload })
  }

  async getOrders(payload) {
    return await this.adapter.postAsync('getOrders', { payload })
  }

  async cancel(payload) {
    return await this.adapter.putAsync('cancel', { payload })
  }

  async getOrderRepurchaseById(orderId) {
    return await this.adapter.getAsync(`getOrderRepurchaseById?orderId=${orderId}`)
  }
}

export default OrderAPI
