import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { toAbsoluteUrl } from '../../../../helpers'
import { FormattedMessage } from 'react-intl'
import { logout } from '../../../../redux/actions/auth/authActions'
import { PURCHASE_TYPE, ROLE_NAME } from '../../../../utilities/constant'

const UserMenu = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const handleLogout = async () => {
    await dispatch(logout())
    if (_.isEmpty(user)) {
      document.location.reload()
    }
  }

  return (
    <div
      className={'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'}
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/no-avatar.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {user?.fullName}
              <span className='badge badge-light-primary fw-bold fs-8 px-2 py-1 ms-2'>
                {/* <FormattedMessage id={user?.roleName ?? ROLE_NAME.AFFILIATE} /> */}
                {user?.affiliateLevelCode}
              </span>
            </div>
            <div className='fw-semibold text-muted text-hover-primary fs-7'>{user?.email}</div>
          </div>
        </div>

        <div className='separator my-2'></div>
        <div className='menu-item px-5'>
          <Link to={'/pages/accountBalance'} className='menu-link px-5'>
            <FormattedMessage id='Enum.AccountBalance' />
          </Link>
          <Link to={'/purchase/' + PURCHASE_TYPE.All} className='menu-link px-5'>
            <FormattedMessage id='PurchaseOrder' />
          </Link>
          <Link to={'/pages/customer'} className='menu-link px-5'>
            <FormattedMessage id='Customer' />
          </Link>
          <Link to={'/pages/my-offer'} className='menu-link px-5'>
            <FormattedMessage id='MyOffer' />
          </Link>
          <Link to={'/pages/affiliate'} className='menu-link px-5'>
            <FormattedMessage id='IntroduceCollaborator' />
          </Link>
          <Link to={'/'} className='menu-link px-5'>
            <FormattedMessage id='SalesPostTemplate' />
          </Link>
        </div>

        <div className='separator my-2'></div>
        <div className='menu-item px-5'>
          <Link to={`/pages/affiliate/${user?.affiliateId}`} className='menu-link px-5'>
            <FormattedMessage id='User.UserProfile' />
          </Link>

          <a onClick={handleLogout} className='menu-link px-5'>
            <FormattedMessage id='Logout' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default UserMenu
