import React from 'react'
import RatingStar from './RatingStar'
import { FormattedMessage } from 'react-intl'

const RatingFilter = ({ rating, onChange }) => {
  const stars = [1, 2, 3, 4, 5]

  return (
    <div className='primary-sidebar'>
      <div className='sidebar-widget'>
        <h5 className='section-title style-1 mb-4'>
          <FormattedMessage id='Rating' />
        </h5>
        <div className='list-group my-4'>
          <div className='list-group-item border-0 p-0'>
            <div className='rating-filter'>
              {stars.reverse().map((index) => {
                return (
                  <div
                    key={`rating-${index}`}
                    onClick={() => onChange(index)}
                    className={`d-flex align-items-center cursor-pointer p-2 rounded hover gap-2 ${rating === index ? 'selected' : ''}`}
                  >
                    <RatingStar rating={index} />
                    {index !== 5 && (
                      <span className='hover-text'>
                        <FormattedMessage id='Up' />
                      </span>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default RatingFilter
